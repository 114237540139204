<template>
  <div class="">
    <div class="row no-gutter header-content">
      <div class="col-12 col-md-4 chart-title">
        Average Invoice Value
      </div>
      <div class="col-12 col-md-6 header-right d-flex">
        <div class="navbar-nav nav dropdown dropdown-options  show inline" style="margin-left: 99px;">
          <button type="button"
                  class="btn waves-effect waves-light ex-options-button"
                  id="dropdownMenuLink1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true">
            Export
            <span class="dropdown-arrow pull-right" style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                                    <i class="fa fa-chevron-down pull-right"></i>
                                </span>
          </button>
          <div class="dropdown-menu dropdown-menu-right animated fadeInUp"
               aria-labelledby="dropdownMenuLink">
            <a class="dropdown-item text-primary p-1 clickable" @click="exportReport('csv')">.CSV</a>
            <a class="dropdown-item text-primary p-1 clickable" @click="exportReport('xlsx')">.XLS</a>
          </div>
        </div>
        <div class="navbar-nav nav dropdown dropdown-options  show inline">
          <button type="button"
                  class="btn waves-effect waves-light ex-options-button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true">
            {{adjustedBy}}
            <span class="dropdown-arrow pull-right" style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                                    <i class="fa fa-chevron-down pull-right"></i>
                                </span>
          </button>
          <div class="dropdown-menu dropdown-menu-right animated fadeInUp"
               aria-labelledby="dropdownMenuLink">
            <a class="dropdown-item text-primary p-1 clickable" @click="adjustedBy = 'Week'">Week</a>
            <a class="dropdown-item text-primary p-1 clickable" @click="adjustedBy = 'Month'">Month</a>
            <a class="dropdown-item text-primary p-1 clickable" @click="adjustedBy = 'Quarter'">Quarter</a>
            <a class="dropdown-item text-primary p-1 clickable" @click="adjustedBy = 'Year'">Year</a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isEmptyReportsData" class="mt-2 text-right chart-interval-block">
      <div>
        {{getDate}}
      </div>
    </div>
    <div class="chart-wrapper col-12 col-md-8">
      <vue-apex-chart
          v-if="isHasData"
          width="1000"
          height="500"
          type="bar"
          :series="computedSeries"
          :options="computedChartOptions"
      ></vue-apex-chart>
      <b-table
        v-if="isHasData"
        responsive
        hover
        :items="chartDataForTable"
        :fields="fields"
        class="report-table"
      >
        <template v-slot:cell(average)="data">
          <div>
            <div v-if="data.item.average === 0">
              -
            </div>
            <div v-else>
              {{data.item.average | formatMoney}}
            </div>
          </div>
        </template>
      </b-table>
<!--      <p class="no-data-text" v-if="!isHasData">No data for graph</p>-->
    </div>
  </div>
</template>

<script>
  import {appConfig} from '../../../../config'
  import Multiselect from 'vue-multiselect';
  import _ from 'lodash';
  import {mapActions, mapGetters} from "vuex";
  import moment from "moment";
  import VueApexCharts from 'vue-apexcharts';
  import accounting from "accounting";

  export default {
    name: "averageValue",
    components: {
      Multiselect,
      "vue-apex-chart": VueApexCharts,
    },
    data() {
      return {
        months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        adjustedBy: 'Month',
        intervalUpdateReports: null,
      }
    },
    mounted() {
      NProgress.start();
      this.actionLoadReports()
      let self = this;
      this.intervalUpdateReports = setInterval(() => {
        if (!_.isEmpty(self.getterAIV) && !_.isEmpty(self.computedSeries) && !_.isEmpty(self.computedChartOptions) && !_.isEmpty(self.chartDataForTable)) {
          NProgress.done();
        }
      }, 1000);
    },
    beforeDestroy() {
      clearInterval(this.intervalUpdateReports);
    },
    methods: {
      ...mapActions({
        actionLoadReports: 'report/loadIPSAndIVSAndAIV',
      }),
      formatMoneyFunc(value) {
        return accounting.formatMoney(value)
      },
      exportReport(format) {
        let token = localStorage.getItem('token');
        let type = 'ps';
        let period = _.toLower(this.adjustedBy);
        let value = 'average';
        window.open(appConfig.baseAPIURL + `/fe/reports/download/invoices/${type}/${period}/${value}/${format}?at=${token}`);
        return;
      }
    },
    computed: {
      ...mapGetters({
        getterAIV: 'report/getAIV',
      }),
      computedSeries() {
        let data = [{
          data: [],
        }];
        if (!this.chartDataForTable) {
          return  data;
        }
        let reportData = this.adjustedBy === 'Month' || this.adjustedBy === 'Quarter' ? this.chartDataForTable.slice().reverse() : this.chartDataForTable.slice();
        _.forEach(reportData, (itm) => {
          data[0].data.push(itm.average)
        })

        if (this.adjustedBy === 'Month') {
          data[0].data = data[0].data.slice(6, 12)
        }

        return data;
      },
      currentMonth() {
        return new Date().getMonth();
      },
      computedChartOptions() {
        let self = this
        let options = {
          chart: {
            id: 'vuechart-example',
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          colors: ['#3366cc', '#dc3912', '#ff9900', '#109618', '#990099', '#0099c6', '#dd4477', '#66aa00', '#b82e2e', '#316395', '#994499', '#22aa99', '#aaaa11', '#6633cc', '#e67300', '#8b0707', '#651067', '#329262', '#5574a6', '#3b3eac', '#b77322', '#16d620', '#b91383', '#f4359e', '#9c5935', '#a9c413', '#2a778d', '#668d1c', '#bea413', '#0c5922', '#743411', '#f4359e', '#b91383', '#16d620', '#b77322', '#3b3eac', '#5574a6', '#329262', '#329262', '#651067', '#8b0707', '#e67300', '#6633cc', '#aaaa11', '#22aa99', '#994499', '#316395', '#b82e2e', '#66aa00', '#dd4477', '#0099c6', '#990099', '#109618', '#ff9900', '#dc3912', '#3366cc', '#ca24d6', '#378784', '#ab8965', '#141e7d', '#edc7c7', '#167d76', '#d5f74a', '#3022c9', '#705658'],
          xaxis: {
            categories: []
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return self.formatMoneyFunc(value)
              }
            },
          },
          legend: {
            showForNullSeries: false,
            position: 'top',
            height: 38,
          },
          tooltip: {
            marker: {show: true},
            enabled: true,
            y: {
              title: {
                formatter: () => {
                  return 'Average Invoice Value:'
                },
              },
            },
            style: {
              fontSize: '13px',
              fontFamily: 'Nunito Sans',
              fontWeight: 'bold',
            },
          },
          dataLabels: {
            enabled: false,
          },
        }
        let result = [];
        if (this.adjustedBy === 'Month') {
          let prevMonths = [];
          let self = this;
          _.forEach(this.months, function (item) {
            if (self.months.indexOf(item) > self.currentMonth) {
              prevMonths.push(item)
            }
          })

          let currentMonths = [];
          _.forEach(self.months, function (item) {
            let index = self.months.indexOf(item)
            if (index <= self.currentMonth) {
              currentMonths.push(item)
            }
          });

          _.forEach(prevMonths, function (item) {
            result.push(item)
          })
          _.forEach(currentMonths, function (item) {
            result.push(item)
          });
        } else if (this.adjustedBy === 'Week') {
          result = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
        } else if (this.adjustedBy === 'Quarter') {
          let q1 = 'January - March';
          let q2 = 'April - June';
          let q3 = 'July - September';
          let q4 = 'October - December';

          let i1 = [0, 1, 2];
          let i2 = [3, 4, 5];
          let i3 = [6, 7, 8];
          let i4 = [9, 10, 11];

          if (i1.includes(this.currentMonth)) {
            result = [
              q2,
              q3,
              q4,
              q1
            ]
          } else if (i2.includes(this.currentMonth)) {
            result = [
              q3,
              q4,
              q1,
              q2,
            ]
          } else if (i3.includes(this.currentMonth)) {
            result = [
              q4,
              q1,
              q2,
              q3,
            ]
          } else if (i4.includes(this.currentMonth)) {
            result = [
              q1,
              q2,
              q3,
              q4,
            ]
          }
        } else if (this.adjustedBy === 'Year') {
          for (let key in this.getterAIV.year) {
            result.push(key);
          }
        }

        options.xaxis.categories.splice(0, options.xaxis.categories.length);
        _.forEach(result, item => {
          options.xaxis.categories.push(item);
        })

        if (this.adjustedBy === 'Month') {
          options.xaxis.categories = options.xaxis.categories.slice(6, 12)
        }
        return options;
      },
      isHasData() {
        if (this.isEmptyReportsData) {
          return false;
        }
        if (this.adjustedBy === 'Week') {
          let isEmptyWeek = true;
          for (let day in this.getterAIV.week) {
            if (!_.isEmpty(this.getterAIV.week[day])) {
              isEmptyWeek = false;
              break
            }
          }
          return !this.isEmptyReportsData && !isEmptyWeek;
        } else if (this.adjustedBy === 'Year') {
          let isEmptyYear = true;
          for (let year in this.getterAIV.year) {
            if (!_.isEmpty(this.getterAIV.year[year])) {
              isEmptyYear = false;
              break
            }
          }
          return !this.isEmptyReportsData && !isEmptyYear;
        } else if (this.adjustedBy === 'Month') {
          let isEmptyMonth = true;
          for (let month in this.getterAIV.month) {
            if (!_.isEmpty(this.getterAIV.month[month])) {
              isEmptyMonth = false;
              break
            }
          }
          return !this.isEmptyReportsData && !isEmptyMonth;
        } else if (this.adjustedBy === 'Quarter') {
          let isEmptyQuarter = true;
          for (let quarter in this.getterAIV.quarter) {
            if (!_.isEmpty(this.getterAIV.quarter[quarter])) {
              isEmptyQuarter = false;
              break
            }
          }
          return !this.isEmptyReportsData && !isEmptyQuarter;
        }
      },
      getDate() {
        let lastIndex = this.computedChartOptions.xaxis.categories.length - 1;
        if (this.isEmptyReportsData) {
          return  '';
        }
        if (this.adjustedBy === "Week") {
          let monday = moment().startOf('isoweek').toDate();
          let sunday   = moment().endOf('isoweek').toDate();

          let mDay = String(monday.getDate()).padStart(2, '0');
          let mMonth = String(monday.getMonth() + 1).padStart(2, '0');
          let mYear = monday.getFullYear();

          let mResult = mDay + '/' + mMonth + '/' + mYear;

          let sDay = String(sunday.getDate()).padStart(2, '0');
          let sMonth = String(sunday.getMonth() + 1).padStart(2, '0');
          let sYear = sunday.getFullYear();

          let sResult = sDay + '/' + sMonth + '/' + sYear;

          return 'Mon' + ' ' + mResult + ' - ' + 'Sun ' + sResult;
        } else if (this.adjustedBy === 'Month') {
          let firstMonth = this.computedChartOptions.xaxis.categories[0];
          let lastMonth = this.computedChartOptions.xaxis.categories[lastIndex];
          let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

          if (lastMonth === 'December') {
            let y1 = new Date().getFullYear();

            return  firstMonth + ' ' + y1 + ' - ' + lastMonth + ' ' + y1;
          }

          if (months.indexOf(firstMonth) > months.indexOf(lastMonth)) {
            let y2 = new Date().getFullYear();
            let y1 = y2 - 1;

            return  firstMonth + ' ' + y1 + ' - ' + lastMonth + ' ' + y2;
          }
        } else if (this.adjustedBy === 'Quarter') {
          let firstQuarter = this.computedChartOptions.xaxis.categories[0];
          let lastQuarter = this.computedChartOptions.xaxis.categories[lastIndex];
          let quarters = ['January - March', 'April - June', 'July - September', 'October - December']

          if (lastQuarter === 'October - December') {
            let y1 = new Date().getFullYear();

            return  firstQuarter + ' ' + y1 + ' - ' + lastQuarter + ' ' + y1;
          }

          if (quarters.indexOf(firstQuarter) > quarters.indexOf(lastQuarter)) {
            let y2 = new Date().getFullYear();
            let y1 = y2 - 1;

            return  firstQuarter + ' ' + y1 + ' to ' + lastQuarter + ' ' + y2;
          }
        } else if (this.adjustedBy === 'Year') {
          let firstYear = this.computedChartOptions.xaxis.categories[0];
          let lastYear = this.computedChartOptions.xaxis.categories[lastIndex];

          return firstYear + ' - ' + lastYear;
        }
      },
      isEmptyReportsData() {
        return _.isEmpty(this.getterAIV);
      },
      fields() {
        let data = [];
        if (this.adjustedBy === 'Month') {
          data.push(
            {
              label: "Month",
              key: "month",
              sortable: true,
              tdClass: 'clickable',
            },
          )
        } else if (this.adjustedBy === 'Week') {
          data.push(
            {
              label: "Day",
              key: "day",
              sortable: true,
              tdClass: 'clickable',
            },
          )
        } else if (this.adjustedBy === 'Year') {
          data.push(
            {
              label: "Year",
              key: "year",
              sortable: true,
              tdClass: 'clickable',
            },
          )
        } else if (this.adjustedBy === 'Quarter') {
          data.push(
            {
              label: "Quarter",
              key: "quarter",
              sortable: true,
              tdClass: 'clickable',
            },
          )
        }
        data.push(
          {
            label: 'Average Invoice Value',
            key: 'average',
            sortable: true,
            tdClass: 'clickable',
          }
        )
        return data
      },
      chartDataForTable () {
        if (this.isEmptyReportsData) {
          return null;
        }
        if (this.adjustedBy === 'Month') {
          let d = new Date();
          let currentMonth = d.getMonth();
          let months = [
            {
              month: 'January',
              average: this.getterAIV.month.January.average
            },
            {
              month: 'February',
              average: this.getterAIV.month.February.average
            },
            {
              month: 'March',
              average: this.getterAIV.month.March.average
            },
            {
              month: 'April',
              average: this.getterAIV.month.April.average
            },
            {
              month: 'May',
              average: this.getterAIV.month.May.average
            },
            {
              month: 'June',
              average: this.getterAIV.month.June.average
            },
            {
              month: 'July',
              average: this.getterAIV.month.July.average
            },
            {
              month: 'August',
              average: this.getterAIV.month.August.average
            },
            {
              month: 'September',
              average: this.getterAIV.month.September.average
            },
            {
              month: 'October',
              average: this.getterAIV.month.October.average
            },
            {
              month: 'November',
              average: this.getterAIV.month.November.average
            },
            {
              month: 'December',
              average: this.getterAIV.month.December.average
            },
          ];
          let prevMonths = months.slice(currentMonth + 1);
          let currentMonths = months.slice(0, currentMonth + 1);
          return _.concat(currentMonths.reverse(), prevMonths.reverse())
        } else if (this.adjustedBy === 'Week') {
          return [
            {
              day: 'Monday',
              average: this.getterAIV.week.Monday.average,
            },
            {
              day: 'Tuesday',
              average: this.getterAIV.week.Tuesday.average,
            },
            {
              day: 'Wednesday',
              average: this.getterAIV.week.Wednesday.average,
            },
            {
              day: 'Thursday',
              average: this.getterAIV.week.Thursday.average,
            },
            {
              day: 'Friday',
              average: this.getterAIV.week.Friday.average,
            },
            {
              day: 'Saturday',
              average: this.getterAIV.week.Saturday.average,
            },
            {
              day: 'Sunday',
              average: this.getterAIV.week.Sunday.average,
            },
          ]
        } else if (this.adjustedBy === 'Quarter') {
          let q1 = [0, 1, 2];
          let q2 = [3, 4, 5];
          let q3 = [6, 7, 8];
          let q4 = [9, 10, 11];

          let d = new Date();
          let currentMonth = d.getMonth();

          let result = [];

          if (q1.includes(currentMonth)) {
            result = [
              {quarter: 'January - March', average: this.getterAIV.quarter['January - March'].average},
              {quarter: 'October - December', average: this.getterAIV.quarter['October - December'].average},
              {quarter: 'July - September', average: this.getterAIV.quarter['July - September'].average},
              {quarter: 'April - June', average: this.getterAIV.quarter['April - June'].average},
            ]
          } else if (q2.includes(currentMonth)) {
            result = [
              {quarter: 'April - June', average: this.getterAIV.quarter['April - June'].average},
              {quarter: 'January - March', average: this.getterAIV.quarter['January - March'].average},
              {quarter: 'October - December', average: this.getterAIV.quarter['October - December'].average},
              {quarter: 'July - September', average: this.getterAIV.quarter['July - September'].average},
            ]
          } else if (q3.includes(currentMonth)) {
            result = [
              {quarter: 'July - September', average: this.getterAIV.quarter['July - September'].average},
              {quarter: 'April - June', average: this.getterAIV.quarter['April - June'].average},
              {quarter: 'January - March', average: this.getterAIV.quarter['January - March'].average},
              {quarter: 'October - December', average: this.getterAIV.quarter['October - December'].average},
            ]
          } else if (q4.includes(currentMonth)) {
            result = [
              {quarter: 'October - December', average: this.getterAIV.quarter['October - December'].average},
              {quarter: 'July - September', average: this.getterAIV.quarter['July - September'].average},
              {quarter: 'April - June', average: this.getterAIV.quarter['April - June'].average},
              {quarter: 'January - March', average: this.getterAIV.quarter['January - March'].average},
            ]
          }

          return result
        } else if (this.adjustedBy === 'Year') {
          let data = []
          for (let year in this.getterAIV.year) {
            data.push(
              {
                year: year,
                average: this.getterAIV.year[year].average,
              })
          }
          return data
        }
      },
    },
  }
</script>

<style scoped>

</style>

