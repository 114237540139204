<template>
  <div id="invoices"
       class="tab-pane tab-pane--invoices col-lg-12 col-md-12 active"
       role="tabpanel">

    <div class="row no-gutter">
      <div class="col-sm-6">
        <div class="card">
          <div class="card-header bg-navyblue">
            <strong>Invoice Standard</strong>
          </div>
          <div class="card-block bg-white">
            <div class="form-group row no-gutter">
              <label for="invoice_prefix" class="col-sm-4 col-form-label">Invoice Prefix</label>
              <div class="col-sm-8">
                <input v-model="value.invoices.invoice_prefix"
                       type="text"
                       class="form-control"
                       :readonly="!isStatusActive"
                       id="invoice_prefix"
                       placeholder="Invoice Prefix">
              </div>
            </div>
            <div class="form-group row no-gutter">
              <label for="invoice_number" class="col-sm-4 col-form-label">Invoice Number Start</label>
              <div class="col-sm-8">
                <input v-model="value.invoices.invoice_number"
                       type="text"
                       :readonly="!isStatusActive"
                       class="form-control" id="invoice_number"
                       placeholder="Invoice Number Start">
              </div>
            </div>
            <div class="form-group row no-gutter">
              <label for="invoice_number_length" class="col-sm-4 col-form-label">Invoice Number
                Length</label>
              <div class="col-sm-8">
                <multiselect id="invoice_number_length"
                             v-model="value.invoices.invoice_number_length"
                             :options="['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']"
                             :showLabels="false"
                             :disabled="!isStatusActive"
                             style="opacity: 1"
                             :option-height="29"
                             :close-on-select="true"
                >
                </multiselect>
              </div>
            </div>
            <div class="form-group row no-gutter">
              <label class="col-sm-4 col-form-label">Due Date - (Default)</label>
              <div class="col-sm-8">
                <multiselect
                    v-model="value.invoices.invoiceDueDate"
                    :options="invoiceDueDates.map(date => date.key)"
                    :custom-label="opt => invoiceDueDates.find(x => x.key == opt).name"
                    :showLabels="false"
                    :close-on-select="true"
                    :option-height="29"
                    :max-height="203"
                    style="opacity: 1"
                    placeholder="Default Due Date"
                    :disabled="!!value.invoices.isXeroInvoiceDueDate || !isStatusActive"
                >
                </multiselect>
              </div>
            </div>
            <div class="form-group row no-gutter">
              <label for="invoice_payment_advice" class="col-sm-4 col-form-label">Terms &amp; Payment
                Advice</label>
              <div class="col-sm-8 invoices-editor">
                <vue-editor v-model="value.invoices.ii.advice"
                            :editorToolbar="customToolbar"></vue-editor>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 pl-1">
        <div class="auto-save">
          <div class="card-header bg-navyblue">
            <strong>Auto Save</strong>
          </div>
          <div class="">
            <label for="auto-save-button" class="col-sm-4 col-form-label"> Auto-Save</label>
            <toggle-button
                v-model="value.invoices.auto_save"
                id="auto-save-button"
                class="mb-0 rs-toggle__button"
                color="#5E79FF"
                :disabled="!isStatusActive"
                :sync="true"
                :width="40"
                :labels="false"/>
            <span class="form-text text-active d-inline-block col-form-label mt-0 ml-0-25">
                                                          <template v-if="value.invoices.auto_save">Active</template>
                                                          <template v-else>Not Active</template>
                                                      </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import NumberFormatter from '../utility/number-formatter'
import { VueEditor } from 'vue2-editor'
import {mapGetters} from "vuex";
export default {
  name: "TheInvoices",
  props: {
    value: {}
  },
  components: {
    Multiselect,
    NumberFormatter,
    VueEditor,
  },
  data() {
    return {
      customToolbar: [
        ['bold', 'italic', 'underline'],
      ],
      invoiceDueDates: [
        { key: 1, name: 'Today' },
        { key: 2, name: 'In 7 Days' },
        { key: 3, name: 'In 14 Days' },
        { key: 4, name: 'In 30 days' },
        { key: 5, name: '1st day of Next Month' },
        { key: 6, name: 'Last day of Next month' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      isStatusActive: 'isStatusActive',
    })
  }
}
</script>

<style scoped>

</style>
