<template>
    <div class="">
        <div class="row no-gutter header-content">
            <div class="col-12 col-md-4 chart-title">
                Estimates Value Summary
            </div>
            <div class="col-12 col-md-6 header-right d-flex">
                <div class="navbar-nav nav dropdown dropdown-options  show inline" style="margin-left: 99px;">
                    <button type="button"
                            class="btn waves-effect waves-light ex-options-button"
                            id="dropdownMenuLink1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true">
                        Export
                        <span class="dropdown-arrow pull-right" style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                                    <i class="fa fa-chevron-down pull-right"></i>
                                </span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right animated fadeInUp"
                         aria-labelledby="dropdownMenuLink">
                        <a class="dropdown-item text-primary p-1 clickable" @click="exportReport('csv')">.CSV</a>
                        <a class="dropdown-item text-primary p-1 clickable" @click="exportReport('xlsx')">.XLS</a>
                    </div>
                </div>
                <div class="navbar-nav nav dropdown dropdown-options  show inline">
                    <button type="button"
                            class="btn waves-effect waves-light ex-options-button"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true">
                        {{adjustedBy}}
                        <span class="dropdown-arrow pull-right" style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                                    <i class="fa fa-chevron-down pull-right"></i>
                                </span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right animated fadeInUp"
                         aria-labelledby="dropdownMenuLink">
                        <a class="dropdown-item text-primary p-1 clickable" @click="adjustedBy = 'Week';    selectedColumn = '';">Week</a>
                        <a class="dropdown-item text-primary p-1 clickable" @click="adjustedBy = 'Month';   selectedColumn = '';">Month</a>
                        <a class="dropdown-item text-primary p-1 clickable" @click="adjustedBy = 'Quarter'; selectedColumn = '';">Quarter</a>
                        <a class="dropdown-item text-primary p-1 clickable" @click="adjustedBy = 'Year';    selectedColumn = '';">Year</a>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!isEmptyReportsData" class="mt-2 text-right chart-interval-block">
            <div>
                {{getDate}}
            </div>
        </div>
        <div class="chart-wrapper col-12 col-md-8">
            <vue-apex-chart
                v-if="isHasData"
                @click="onClick"
                width="1000"
                height="500"
                type="bar"
                :series="computedSeries"
                :options="computedChartOptions"
            ></vue-apex-chart>
            <b-table
                    v-if="isSelectedColumn"
                    responsive
                    hover
                    :items="estimateDetailData"
                    :fields="fieldsForEstimateDetail"
                    class="report-table"
            >
                <template v-slot:cell(total)="data">
                    {{data.item.total|formatMoney}}
                </template>
                <template v-slot:cell(gst)="data">
                    {{data.item.gst|formatMoney}}
                </template>
                <template v-slot:cell(subtotal)="data">
                    {{data.item.subtotal|formatMoney}}
                </template>
            </b-table>
            <b-table
                    v-if="!isSelectedColumn && isHasData"
                    responsive
                    hover
                    :items="chartDataForTable"
                    :fields="fields"
                    class="report-table"
            >
                <template v-slot:cell(summary)="data">
                    <div v-if="data.item.summary === 0">
                        -
                    </div>
                    <div v-else>
                        {{data.item.summary | formatMoney}}
                    </div>
                </template>
            </b-table>
<!--            <p class="no-data-text" v-if="!isHasData">No data for graph</p>-->
        </div>
    </div>
</template>

<script>
    import {appConfig} from '../../../../config';
    import Multiselect from 'vue-multiselect';
    import _ from 'lodash';
    import {mapActions, mapGetters} from "vuex";
    import moment from "moment";
    import Axios from "axios";
    import VueApexCharts from 'vue-apexcharts';
    import accounting from "accounting";

    export default {
        name: "valueSummary",
        data() {
            return {
                months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                fieldsForEstimateDetail: [
                    {label: "Estimate number", key: "estimateNumber", sortable: true, tdClass: '',},
                    {label: "Estimate Creation Date", key: "estimateCreationDate", sortable: true, tdClass: '',},
                    {label: "Rego", key: "rego", sortable: true, tdClass: '',},
                    {label: "Vehicle Owner", key: "vehicleOwner", sortable: true, tdClass: '',},
                    {label: "Subtotal", key: "subtotal", sortable: true, tdClass: '',},
                    {label: "GST", key: "gst", sortable: true, tdClass: '',},
                    {label: "Grandtotal", key: "total", sortable: true, tdClass: '',},
                ],
                estimateDetailData: [],
                adjustedBy: 'Month',
                intervalUpdateReports: null,
                selectedColumn: '',
                chartEvents: {
                    'click': (event) => {
                        console.log('event', event);
                        if (!event.targetID.includes('bar#')) {
                            return;
                        }
                        let index = event.targetID.split('#');
                        index = index[index.length - 1];
                        if (this.selectedColumn != this.chartData[Number(index) + 1][0]) {
                            this.selectedColumn = this.chartData[Number(index) + 1][0];
                            this.clickOnChart();
                        } else {
                            this.selectedColumn = '';
                        }
                    }
                }
            };
        },
        computed: {
            ...mapGetters({
                getterEVS: 'report/getEVS',
            }),
            computedSeries() {
              let data = [{
                data: [],
              }];
              if (!this.chartDataForTable) {
                return  data;
              }
              let reportData = this.adjustedBy === 'Month' || this.adjustedBy === 'Quarter' ? this.chartDataForTable.slice().reverse() : this.chartDataForTable.slice();
              _.forEach(reportData, (itm) => {
                data[0].data.push(itm.summary)
              })

              if (this.adjustedBy === 'Month') {
                data[0].data = data[0].data.slice(6, 12)
              }

              return data;
            },
            currentMonth() {
              return new Date().getMonth();
            },
            computedChartOptions() {
              let self = this
              let options = {
                chart: {
                  id: 'vuechart-example',
                  stacked: true,
                  toolbar: {
                    show: false,
                  },
                },
                colors: ['#3366cc', '#dc3912', '#ff9900', '#109618', '#990099', '#0099c6', '#dd4477', '#66aa00', '#b82e2e', '#316395', '#994499', '#22aa99', '#aaaa11', '#6633cc', '#e67300', '#8b0707', '#651067', '#329262', '#5574a6', '#3b3eac', '#b77322', '#16d620', '#b91383', '#f4359e', '#9c5935', '#a9c413', '#2a778d', '#668d1c', '#bea413', '#0c5922', '#743411', '#f4359e', '#b91383', '#16d620', '#b77322', '#3b3eac', '#5574a6', '#329262', '#329262', '#651067', '#8b0707', '#e67300', '#6633cc', '#aaaa11', '#22aa99', '#994499', '#316395', '#b82e2e', '#66aa00', '#dd4477', '#0099c6', '#990099', '#109618', '#ff9900', '#dc3912', '#3366cc', '#ca24d6', '#378784', '#ab8965', '#141e7d', '#edc7c7', '#167d76', '#d5f74a', '#3022c9', '#705658'],
                xaxis: {
                  categories: []
                },
                yaxis: {
                  labels: {
                    formatter: function (value) {
                      return self.formatMoneyFunc(value)
                    }
                  },
                },
                legend: {
                  showForNullSeries: false,
                  position: 'top',
                  height: 38,
                },
                tooltip: {
                  marker: {show: true},
                  enabled: true,
                  y: {
                    title: {
                      formatter: () => {
                        return 'Estimate Totals: '
                      },
                    },
                  },
                  style: {
                    fontSize: '13px',
                    fontFamily: 'Nunito Sans',
                    fontWeight: 'bold',
                  },
                },
                dataLabels: {
                  enabled: false,
                },
              }
              let result = [];
              if (this.adjustedBy === 'Month') {
                let prevMonths = [];
                let self = this;
                _.forEach(this.months, function (item) {
                  if (self.months.indexOf(item) > self.currentMonth) {
                    prevMonths.push(item)
                  }
                })

                let currentMonths = [];
                _.forEach(self.months, function (item) {
                  let index = self.months.indexOf(item)
                  if (index <= self.currentMonth) {
                    currentMonths.push(item)
                  }
                });

                _.forEach(prevMonths, function (item) {
                  result.push(item)
                })
                _.forEach(currentMonths, function (item) {
                  result.push(item)
                });
              } else if (this.adjustedBy === 'Week') {
                result = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
              } else if (this.adjustedBy === 'Quarter') {
                let q1 = 'January - March';
                let q2 = 'April - June';
                let q3 = 'July - September';
                let q4 = 'October - December';

                let i1 = [0, 1, 2];
                let i2 = [3, 4, 5];
                let i3 = [6, 7, 8];
                let i4 = [9, 10, 11];

                if (i1.includes(this.currentMonth)) {
                  result = [
                    q2,
                    q3,
                    q4,
                    q1
                  ]
                } else if (i2.includes(this.currentMonth)) {
                  result = [
                    q3,
                    q4,
                    q1,
                    q2,
                  ]
                } else if (i3.includes(this.currentMonth)) {
                  result = [
                    q4,
                    q1,
                    q2,
                    q3,
                  ]
                } else if (i4.includes(this.currentMonth)) {
                  result = [
                    q1,
                    q2,
                    q3,
                    q4,
                  ]
                }
              } else if (this.adjustedBy === 'Year') {
                for (let key in this.getterEVS.year) {
                  result.push(key);
                }
              }

              options.xaxis.categories.splice(0, options.xaxis.categories.length);
              _.forEach(result, item => {
                options.xaxis.categories.push(item);
              })

              if (this.adjustedBy === 'Month') {
                options.xaxis.categories = options.xaxis.categories.slice(6, 12)
              }

              return options;
            },
            isSelectedColumn() {
                return Boolean(this.selectedColumn != '');
            },
            isHasData() {
                if (this.isEmptyReportsData) {
                    return false;
                }
                if (this.adjustedBy === 'Week') {
                    let isEmptyWeek = true;
                    for (let day in this.getterEVS.week) {
                        if (!_.isEmpty(this.getterEVS.week[day])) {
                            isEmptyWeek = false;
                            break;
                        }
                    }
                    return !this.isEmptyReportsData && !isEmptyWeek;
                } else if (this.adjustedBy === 'Year') {
                    let isEmptyYear = true;
                    for (let year in this.getterEVS.year) {
                        if (!_.isEmpty(this.getterEVS.year[year])) {
                            isEmptyYear = false;
                            break;
                        }
                    }
                    return !this.isEmptyReportsData && !isEmptyYear;
                } else if (this.adjustedBy === 'Month') {
                    let isEmptyMonth = true;
                    for (let month in this.getterEVS.month) {
                        if (!_.isEmpty(this.getterEVS.month[month])) {
                            isEmptyMonth = false;
                            break;
                        }
                    }
                    return !this.isEmptyReportsData && !isEmptyMonth;
                } else if (this.adjustedBy === 'Quarter') {
                    let isEmptyQuarter = true;
                    for (let quarter in this.getterEVS.quarter) {
                        if (!_.isEmpty(this.getterEVS.quarter[quarter])) {
                            isEmptyQuarter = false;
                            break;
                        }
                    }
                    return !this.isEmptyReportsData && !isEmptyQuarter;
                }
            },
            getDate() {
                let lastIndex = this.computedChartOptions.xaxis.categories.length - 1;
                if (this.isEmptyReportsData) {
                    return '';
                }
                if (this.adjustedBy === "Week") {
                    let monday = moment().startOf('isoweek').toDate();
                    let sunday = moment().endOf('isoweek').toDate();

                    let mDay = String(monday.getDate()).padStart(2, '0');
                    let mMonth = String(monday.getMonth() + 1).padStart(2, '0');
                    let mYear = monday.getFullYear();

                    let mResult = mDay + '/' + mMonth + '/' + mYear;

                    let sDay = String(sunday.getDate()).padStart(2, '0');
                    let sMonth = String(sunday.getMonth() + 1).padStart(2, '0');
                    let sYear = sunday.getFullYear();

                    let sResult = sDay + '/' + sMonth + '/' + sYear;

                    return 'Mon' + ' ' + mResult + ' - ' + 'Sun ' + sResult;
                } else if (this.adjustedBy === 'Month') {
                    let firstMonth = this.computedChartOptions.xaxis.categories[0];
                    let lastMonth = this.computedChartOptions.xaxis.categories[lastIndex];
                    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

                    if (lastMonth === 'December') {
                        let y1 = new Date().getFullYear();

                        return firstMonth + ' ' + y1 + ' - ' + lastMonth + ' ' + y1;
                    }

                    if (months.indexOf(firstMonth) > months.indexOf(lastMonth)) {
                        let y2 = new Date().getFullYear();
                        let y1 = y2 - 1;

                        return firstMonth + ' ' + y1 + ' - ' + lastMonth + ' ' + y2;
                    }
                } else if (this.adjustedBy === 'Quarter') {
                    let firstQuarter = this.computedChartOptions.xaxis.categories[0];
                    let lastQuarter = this.computedChartOptions.xaxis.categories[lastIndex];
                    let quarters = ['January - March', 'April - June', 'July - September', 'October - December'];

                    if (lastQuarter === 'October - December') {
                        let y1 = new Date().getFullYear();

                        return firstQuarter + ' ' + y1 + ' - ' + lastQuarter + ' ' + y1;
                    }

                    if (quarters.indexOf(firstQuarter) > quarters.indexOf(lastQuarter)) {
                        let y2 = new Date().getFullYear();
                        let y1 = y2 - 1;

                        return firstQuarter + ' ' + y1 + ' to ' + lastQuarter + ' ' + y2;
                    }
                } else if (this.adjustedBy === 'Year') {
                    let firstYear = this.computedChartOptions.xaxis.categories[0];
                    let lastYear = this.computedChartOptions.xaxis.categories[lastIndex];

                    return firstYear + ' - ' + lastYear;
                }
            },
            isEmptyReportsData() {
                return _.isEmpty(this.getterEVS);
            },
            fields() {
                let data = [];
                if (this.adjustedBy === 'Month') {
                    data.push(
                        {
                            label: "Month",
                            key: "month",
                            sortable: true,
                            tdClass: 'clickable',
                        },
                    );
                } else if (this.adjustedBy === 'Week') {
                    data.push(
                        {
                            label: "Day",
                            key: "day",
                            sortable: true,
                            tdClass: 'clickable',
                        },
                    );
                } else if (this.adjustedBy === 'Year') {
                    data.push(
                        {
                            label: "Year",
                            key: "year",
                            sortable: true,
                            tdClass: 'clickable',
                        },
                    );
                } else if (this.adjustedBy === 'Quarter') {
                    data.push(
                        {
                            label: "Quarter",
                            key: "quarter",
                            sortable: true,
                            tdClass: 'clickable',
                        },
                    );
                }
                data.push(
                    {
                        label: 'Estimate Totals',
                        key: 'summary',
                        sortable: true,
                        tdClass: 'clickable',
                    }
                );
                return data;
            },
            chartDataForTable() {
                if (this.isEmptyReportsData) {
                    return null;
                }
                if (this.adjustedBy === 'Month') {
                    let d = new Date();
                    let currentMonth = d.getMonth();
                    let months = [
                        {
                            month: 'January',
                            summary: this.getterEVS.month.January.summary
                        },
                        {
                            month: 'February',
                            summary: this.getterEVS.month.February.summary
                        },
                        {
                            month: 'March',
                            summary: this.getterEVS.month.March.summary
                        },
                        {
                            month: 'April',
                            summary: this.getterEVS.month.April.summary
                        },
                        {
                            month: 'May',
                            summary: this.getterEVS.month.May.summary
                        },
                        {
                            month: 'June',
                            summary: this.getterEVS.month.June.summary
                        },
                        {
                            month: 'July',
                            summary: this.getterEVS.month.July.summary
                        },
                        {
                            month: 'August',
                            summary: this.getterEVS.month.August.summary
                        },
                        {
                            month: 'September',
                            summary: this.getterEVS.month.September.summary
                        },
                        {
                            month: 'October',
                            summary: this.getterEVS.month.October.summary
                        },
                        {
                            month: 'November',
                            summary: this.getterEVS.month.November.summary
                        },
                        {
                            month: 'December',
                            summary: this.getterEVS.month.December.summary
                        },
                    ];
                    let prevMonths = months.slice(currentMonth + 1);
                    let currentMonths = months.slice(0, currentMonth + 1);
                    return _.concat(currentMonths.reverse(), prevMonths.reverse());
                } else if (this.adjustedBy === 'Week') {
                    return [
                        {
                            day: 'Monday',
                            summary: this.getterEVS.week.Monday.summary,
                        },
                        {
                            day: 'Tuesday',
                            summary: this.getterEVS.week.Tuesday.summary,
                        },
                        {
                            day: 'Wednesday',
                            summary: this.getterEVS.week.Wednesday.summary,
                        },
                        {
                            day: 'Thursday',
                            summary: this.getterEVS.week.Thursday.summary,
                        },
                        {
                            day: 'Friday',
                            summary: this.getterEVS.week.Friday.summary,
                        },
                        {
                            day: 'Saturday',
                            summary: this.getterEVS.week.Saturday.summary,
                        },
                        {
                            day: 'Sunday',
                            summary: this.getterEVS.week.Sunday.summary,
                        },
                    ];
                } else if (this.adjustedBy === 'Quarter') {
                    let q1 = [0, 1, 2];
                    let q2 = [3, 4, 5];
                    let q3 = [6, 7, 8];
                    let q4 = [9, 10, 11];

                    let d = new Date();
                    let currentMonth = d.getMonth();

                    let result = [];

                    if (q1.includes(currentMonth)) {
                        result = [
                            {quarter: 'January - March', summary: this.getterEVS.quarter['January - March'].summary},
                            {quarter: 'October - December', summary: this.getterEVS.quarter['October - December'].summary},
                            {quarter: 'July - September', summary: this.getterEVS.quarter['July - September'].summary},
                            {quarter: 'April - June', summary: this.getterEVS.quarter['April - June'].summary},
                        ];
                    } else if (q2.includes(currentMonth)) {
                        result = [
                            {quarter: 'April - June', summary: this.getterEVS.quarter['April - June'].summary},
                            {quarter: 'January - March', summary: this.getterEVS.quarter['January - March'].summary},
                            {quarter: 'October - December', summary: this.getterEVS.quarter['October - December'].summary},
                            {quarter: 'July - September', summary: this.getterEVS.quarter['July - September'].summary},
                        ];
                    } else if (q3.includes(currentMonth)) {
                        result = [
                            {quarter: 'July - September', summary: this.getterEVS.quarter['July - September'].summary},
                            {quarter: 'April - June', summary: this.getterEVS.quarter['April - June'].summary},
                            {quarter: 'January - March', summary: this.getterEVS.quarter['January - March'].summary},
                            {quarter: 'October - December', summary: this.getterEVS.quarter['October - December'].summary},
                        ];
                    } else if (q4.includes(currentMonth)) {
                        result = [
                            {quarter: 'October - December', summary: this.getterEVS.quarter['October - December'].summary},
                            {quarter: 'July - September', summary: this.getterEVS.quarter['July - September'].summary},
                            {quarter: 'April - June', summary: this.getterEVS.quarter['April - June'].summary},
                            {quarter: 'January - March', summary: this.getterEVS.quarter['January - March'].summary},
                        ];
                    }

                    return result;
                } else if (this.adjustedBy === 'Year') {
                    let data = [];
                    for (let year in this.getterEVS.year) {
                        data.push(
                            {
                                year: year,
                                summary: this.getterEVS.year[year].summary,
                            });
                    }
                    return data;
                }

            },
        },
        methods: {
            ...mapActions({
                actionLoadReports: 'report/loadEPSAndAEVAndEVS',
            }),
          formatMoneyFunc(value) {
            return accounting.formatMoney(value)
          },
            onClick(event, context, config){
              if (this.selectedColumn === this.computedChartOptions.xaxis.categories[config.dataPointIndex] || config.dataPointIndex === -1) {
                this.selectedColumn = '';
                return;
              }
              this.selectedColumn = this.computedChartOptions.xaxis.categories[config.dataPointIndex];
              if (this.adjustedBy == 'week') {
                return;
              }
              NProgress.start();
              Axios.get('/fe/reports/estimate/details/' + _.toLower(this.adjustedBy) + '/' + _.toLower(this.selectedColumn.replace(/\s+/g, '')))
                  .then(response => {
                    this.estimateDetailData = response.data.data;
                  })
                  .catch(error => {
                    console.log(error);

                  }).finally(() => {
                NProgress.done();
              });
            },
            exportReport(format) {
                let token = localStorage.getItem('token');
                if (this.isSelectedColumn) {
                    let url = appConfig.baseAPIURL;
                    url += '/fe/reports/download/estimates/details/';
                    url += _.toLower(this.adjustedBy);
                    url += '/';
                    url += _.toLower(this.selectedColumn.replace(/\\s+/g, ''));
                    url += '/';
                    url += format;
                    url += '?at=' + token;
                    window.open(url);
                } else {
                    let type = 'ps';
                    let period = _.toLower(this.adjustedBy);
                    let value = 'summary';
                    window.open(appConfig.baseAPIURL + `/fe/reports/download/estimates/${type}/${period}/${value}/${format}?at=${token}`);
                }
                return;
            }
        },
        mounted() {
            NProgress.start();
            this.actionLoadReports();
            let self = this;
            this.intervalUpdateReports = setInterval(() => {
                if (!_.isEmpty(self.getterEVS) && !_.isEmpty(self.computedSeries) && !_.isEmpty(self.computedChartOptions) && !_.isEmpty(self.chartDataForTable)) {
                    NProgress.done();
                }
            }, 1000);
        },
        beforeDestroy() {
            clearInterval(this.intervalUpdateReports);
        },
        components: {
            Multiselect,
            "vue-apex-chart": VueApexCharts,
        },
    };
</script>

<style scoped>
</style>
