<template>
  <div class="web-video-containter">
    <audio id="cameraAudio" src="../../../assets/audio/cameraShutter.mp3"></audio>
    <div class="close-web-camera" @click="close">
      <span v-if="savedImgCount !== unsavedImgCount && isClickedClose"><vue-simple-spinner style="position: relative; top: 8px" :size="23"></vue-simple-spinner></span>
      <i v-else class='bx bx-x'></i>
    </div>
    <div class="switch-camera">
      <div class="switch-camera-wrapper">
        <i class="bx bx-camera" @click="switchCamera"></i>
        <div class="switch-number"> {{(currentCameraMode === 'user') ? 2 : 1}}</div>
      </div>
    </div>
    <template>
      <video autoplay muted playsinline id="preview" :class="{'user-mode': currentCameraMode !== 'environment'}"></video>
    </template>

    <div class="actions">
      <div class="" style="flex: 1; text-align: center">
        <button type="button" id="take-photo" :class="{'red-take-photo': isRecording}" class="btn-shot" @click="onTakePhotoClick" tabindex="1"></button>
      </div>
      <div style="position: relative; flex-shrink: 0">
        <div @click="isShowImageSections = !isShowImageSections" class="image-section" style="">{{ imageSection ? recoverToUpperCase(imageSection) : 'Image Section' }}</div>
        <div v-if="isShowImageSections" class="image-section-dropdown">
          <div class="image-section-dropdown-itm" @click="imageSection = 'Uncategorised'; isShowImageSections = false">Uncategorised</div>
          <div class="image-section-dropdown-itm" @click="imageSection = 'Existing_Damage'; isShowImageSections = false">Existing Damage</div>
          <div class="image-section-dropdown-itm" @click="imageSection = 'Accident_Damage'; isShowImageSections = false">Accident Damage</div>
          <div class="image-section-dropdown-itm" @click="imageSection = 'Supplementary_Damage'; isShowImageSections = false">Supplementary Damage</div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Axios from "axios";
import {mapGetters} from "vuex";
import _ from "lodash";
import SiteHeader from "@/components/common/site-header.vue";
import Spinner from "vue-simple-spinner";

export default {
  name: "webCamera",
  components: {
    SiteHeader,
    'vue-simple-spinner': Spinner,
  },
  props: {
    estimateId: {
      type: [String, Number],
      default() {
        return null
      }
    },
    cardId: {
      type: [String, Number],
      default() {
        return null
      }
    },
    imageIds: {
      type: Array,
      default() {
        return []
      }
    },
    isEstimate: {
      type: Boolean,
      default: false,
    },
    isNewCard: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isRecording: false,
      activeVideoStream: null,
      savedImgCount: 0,
      unsavedImgCount: 0,
      photos: [],
      currentCameraMode: 'environment',
      isShowImageSections: false,
      isClickedClose: false,
      imageSection: null,
    }
  },
  mounted() {
    this.startVideo()
    this.$fullscreen.toggle(document.body, {
      wrap: false,
    });
  },
  computed: {
    ...mapGetters({
      getterAllEstimates: 'estimate/getAllEstimates',
      userInfo: 'userInfo'
    }),
  },
  methods: {
    close() {
      if (this.savedImgCount == this.unsavedImgCount) {
        this.$fullscreen.toggle(document.body, {
          wrap: false,
          callback: this.emitClose()
        });
      } else {
        let interval = setInterval(() => {
          this.isClickedClose = true
          if (this.savedImgCount == this.unsavedImgCount) {
            clearInterval(interval)
            this.$fullscreen.toggle(document.body, {
              wrap: false,
              callback: this.emitClose()
            });
            this.isClickedClose = false
          }
        }, 10)
      }
    },
    emitClose() {
      if (this.activeVideoStream) {
        this.activeVideoStream.getTracks().forEach(track => track.stop());
      }
      this.$emit('close')
    },
    recoverToUpperCase: function (item) {
      return item.replace(/_/g, ' ').split(' ').map(_ => _.replace(_.charAt(0), _.charAt(0))).join(' ')
    },
    onClickDone(id) {
      let data = {}
      let uid = Date.now().toString() + Math.random().toString(36).substr(2, 4)
      data[uid] = {}
      data[uid].image_ids = [id]
      data[uid].imageCategories = [{
        file_id: id,
        category: this.imageSection ? this.imageSection : 'Uncategorised'
      }]
      data[uid].imagesSort =[{
        id: id,
        index: 0,
      }]
      _.forEach(this.imageIds, (id, index) => {
        data[uid].imagesSort.push({
          id: id,
          index: index + 1
        })
      })
      Axios({
        method: 'post',
        responseType: 'json',
        headers: { 'Autosave': true },
        url: this.isEstimate ? `/fe/estimate/${this.estimateId}` : `/fe/card/${this.cardId}`,
        validateStatus: function (status) {
          return status < 500
        },
        data: data
      })
          .then((r) => {
            if (r.status === 200) {
              this.savedImgCount++
            }
          })
      .finally(() => {
        NProgress.done()
      })

    },
    switchCamera() {
      this.updateActiveCameraSelection()
    },
    async updateActiveCameraSelection() {
      try {
        this.currentCameraMode = (this.currentCameraMode === 'user') ? 'environment' : 'user';
        this.startVideo()
      } catch (error) {
        this.saveError(error)
      }
    },
    saveError(e) {
      Axios.post('/fe/logs/front', {error: String(e)}).then(r => {
      })
    },
    createThumbnail(video, scaleFactor) {
      if (scaleFactor == null) {
        scaleFactor = 1;
      }
      let w = video.videoWidth * scaleFactor;
      let h = video.videoHeight * scaleFactor;
      let canvas = document.createElement("canvas");
      canvas.style.margin = "5px";
      canvas.width = w;
      canvas.height = h;

      let ctx = canvas.getContext("2d");

      if (this.currentCameraMode !== 'environment') {
        ctx.translate(w, 0);
        ctx.scale(-1, 1);
      }
      ctx.drawImage(video, 0, 0, w, h);
      return canvas;
    },
    async onTakePhotoClick(e) {

      try {
        this.unsavedImgCount++
        e.preventDefault();
        e.stopPropagation();

        this.isRecording = true

        if (window?.navigator?.vibrate) {
          window.navigator.vibrate([200])
        }

        let audio = document.getElementById('cameraAudio');
        audio.play().catch(e => {
          console.log('audio.catch:', e)
        })

        let video = document.getElementById("preview");
        let canvas = this.createThumbnail(video, 1);

        await canvas.toBlob((blob) => {
          setTimeout(() => {
            this.isRecording = false;
            this.savePhoto(blob);
          }, 100)
        });
      } catch (error) {
        this.unsavedImgCount = this.savedImgCount
        this.saveError(error)
      }
    },
    resizeFile(file, width = undefined, height = undefined, quality = 0.7) {
      return new Promise((resolve) => {
        let image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = function() {
          let imageWidth = image.width;
          let imageHeight = image.height;
          let canvas = document.createElement('canvas');

          if (width && height) {
            canvas.width = width;
            canvas.height = height;
          } else if (width) {
            canvas.width = width;
            canvas.height = Math.floor(imageHeight * width / imageWidth)
          } else if (height) {
            canvas.width = Math.floor(imageWidth * height / imageHeight);
            canvas.height = height;
          } else {
            canvas.width = imageWidth;
            canvas.height = imageHeight
          }

          var ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

          let dataUrl = canvas.toDataURL("image/jpeg", quality);
          resolve(dataUrl);
        }
      }).then(dataUrl => {
        return dataUrl;
      })
    },
    savePhoto(imageBlob) {
      if (this.isNewCard) {
        let category = this.imageSection ? _.cloneDeep(this.imageSection) : 'Uncategorised'
        this.$emit('addBlob', {blob: imageBlob, category: category})
        this.savedImgCount++
        return
      }
      this.resizeFile(imageBlob).then(dataUrl => {

        Axios.post('/fe/card/upload-files', {file: dataUrl}).then(r => {
          if (r?.data?._status) {
            this.onClickDone(r.data.file_id)
          }
        })

      })
    },
    async startVideo() {
      let width = 1280;
      let height = 720;
      if (this.userInfo.resolution) {
        let r = this.userInfo.resolution;
        if (r == 'sd') {
          width = 720;
          height = 480;
        } else if (r == 'hd') {
          width = 1280;
          height = 720;
        } else if (r == 'full') {
          width = 1920;
          height = 1080;
        } else if (r == '2k') {
          width = 2048;
          height = 1080;
        } else if (r == '4k') {
          width = 3480;
          height = 2160;
        }
      }
      if (this.activeVideoStream) {
        this.activeVideoStream.getTracks().forEach(track => track.stop());
      }
      try {
        this.activeVideoStream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: this.currentCameraMode ,
            width: { ideal: width },
            height: { ideal: height }
          }
        });
        document.getElementById("preview").srcObject = this.activeVideoStream;
      } catch (error) {
        this.saveError(error)
      }
    },
  }
}
</script>

<style scoped>
.btn-shot{
  margin-left: 95px;
}
.close-web-camera{
  position: absolute;
  font-size: 34px;
  left: 30px;
  top: 15px;
  text-align: center;
  padding-top: 5px;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-color: #fdfdfd;
  z-index: 9999;
}
.image-section-dropdown {
  position: absolute;
  top: -208px;
  left: -2.5px;
  border-radius: 10px;
}
.image-section-dropdown-itm {
  background-color: white;
  color: black;
  font-weight: bold;
  padding: 9px;
  cursor: pointer;
}
.image-section-dropdown-itm:first-child {
  border-radius: 3px 3px 0 0;
}
.image-section-dropdown-itm:last-child {
  border-radius: 0 0 3px 3px;
}
.user-mode {
  transform: scaleX(-1)
}
.switch-camera {
  position: absolute;
  z-index: 9999;
  cursor: pointer;
  right: 30px;
  transform: translateY(15px);
}

.switch-camera-wrapper {
  background-color: white;
  border-radius: 100px;
  width: 50px;
  height: 50px;
}

.switch-camera .bx-camera {
  color: black;
  font-size: 26px;
  transform: translate(12px, 13px);

}

.switch-camera .switch-number {
  color: black;
  width: 10px;
  transform: translate(34px, -28px);
  font-weight: bold;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.image-section {
  background-color: white;
  color: black;
  border-radius: 3px;
  border: 1px solid #989898;
  font-weight: bold;
  padding: 9px;
  cursor: pointer;
}

.web-video-containter {
  min-height: 100vh;
  height: auto;
  z-index: 100001;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #1C1F3A;
}
@media (orientation: landscape) {
  .web-video-containter {
    width: 100vw;
    height: 100%;
    overflow: hidden;
    min-height: auto;
  }
  .actions{
    flex-direction: column;
    right: 0 !important;
    left: auto !important;
    height: 50vh !important;
    width: 100px;
    padding-top: 0px !important;
  }
  .actions .image-section{
    font-size: 10px;
    width: 85px;
    position: relative;
    top: -5px;
  }
  .switch-number{
    transform: translate(24px, -25px) !important;
    font-size: 12px;
  }
  .actions .waves-light{
    position: relative;
    top: -20px;
  }
  .btn-shot{
    margin-left: 0;
    position: relative;
    top: -22px;
    margin-top: 0px;
  }
  .bx-camera{
    font-size: 21px !important;
    transform: translate(10px, 12px) !important;
  }
  .switch-camera-wrapper{
    width: 40px;
    height: 40px;
  }
  .image-section-dropdown {
    position: absolute;
    top: -105px;
    left: -164px;
    border-radius: 10px;
  }
}
#preview {
  flex: 1;
  width: 100%;
  /*height: 100%;*/
  /*max-height: 100vh;*/
  object-fit: cover;
  height: 100vh;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 10vh;
}

#take-photo {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: grey;
  border: 3px solid white;
}

.red-take-photo {
  background-color: red !important;
  width: 60px !important;
  height: 60px !important;
}

</style>
