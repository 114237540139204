<template>
  <div class="" style="margin-bottom: 100px">
    <div style="margin: 8px 0; display: flex">
      <div class="subtitle" style="margin-right: 15px">Non-Attendance Call Back Attempts</div>
      <button class="update-btn btn btn-secondary" v-if="showEditMenu == false" @click="editBookingAttempts" style="margin-left: 8px">
        Edit Count
      </button>
      <button class="update-btn btn btn-secondary" v-if="showEditMenu == true" @click="save" style="margin-left: 8px">
        Save
      </button>
      <button class="update-btn btn btn-secondary" v-if="showEditMenu == true" @click="bookingAttempts.push(null)" style="margin-left: 8px">
        Create Tracker
      </button>
      <button class="update-btn btn btn-secondary" v-if="showEditMenu == true" @click="cancelBookingAttempts" style="margin-left: 15px">
        Cancel
      </button>

      <multiselect
          class="multiselect-follow-up-booking-snapshot"
          v-model="followUpAction"
          :options="['Default', 'Rebooked', 'Cancelled', 'Calling']"
          :max-height="203"
          placeholder="Follow Up Action"
          :multiple="true"
          style="min-width: 150px; margin-left: 15px; width: auto"
          :showLabels="false"
          :close-on-select="true"
      >
      </multiselect>
    </div>
    <div class="cards-all-snapshot-main-total d-flex">
      <div v-if="!showEditMenu" class="cards-all-snapshot-main-total_item" v-for="(itm, ind) in bookingAttempts" :key="ind">
        <div class="cards-all-snapshot-main-total_item-border">
          <div class="cards-all-snapshot-main-total_item-sum" @click="goToCards(itm)" style="cursor: pointer">
            {{getCountByStatusId(itm)}}
          </div>
          <div class="cards-all-snapshot-main-total_item-name">
            {{itm}}
          </div>
        </div>
      </div>
      <div v-if="showEditMenu == true" class="cards-all-snapshot-main-total_item"  v-for="(itm, ind) in bookingAttempts" :key="ind">

        <div class="cards-all-snapshot-main-total_item-border" style="padding: 8px 12px">
          <div class="cards-all-snapshot-main-total_item-sum cards-all-snapshot-main-total_item-sum-bold"  style="float: right;">

            <div class=""  @focusout="closePopupMenu" tabindex="0">
              <i class='bx bx-dots-vertical-rounded' style="color: grey"  @click="showPopupMenu(itm + ind)" :id="itm + ind"></i>
              <ul v-if="showMenuItem == itm + ind"
                  class="card-menu-popup"
                  style="position: absolute; transform: translate(12px, -8px);"
                  :class="{'card-menu-popup-open-left': isRevertMenuCheck(itm + ind)}">
                <li class="parent" v-if="!itm && computedAttempts.length">
                  <div class="title">
                    <i v-if="isRevertMenuCheck(itm + ind)"
                       class="bx bx-chevron-left"></i>
                    Add Contact
                    <i v-if="!isRevertMenuCheck(itm + ind)"
                       class="bx bx-chevron-right"></i>
                  </div>
                  <div class="sub-menu">
                    <ul class="sub-menu-inner">
                      <li v-for="(att, index) in computedAttempts" :key="index" @click="addBookingAttempts(att, ind)">{{ att }}</li>
                    </ul>
                  </div>
                </li>
                <li  class="parent" :class="{'reverse-label': isRevertMenuCheck(itm + ind)}" @click="removeTrackers(itm, ind)">
                  <div class="title"
                  >
                    Remove Contact
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div style="font-weight: 900; font-size: 15px; text-align: center; margin-top: 20%;">
            {{itm}}
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import Axios from "axios";
import _ from "lodash";
import Multiselect from 'vue-multiselect';

export default {
  name: "callBackAttempts",
  data: function () {
    return {
      bookingAttempts: [],
      addAttempts: [],
      bookingAttemptsBeforeEdit: [],
      removeBookingAttempts: [],
      followUpAction: ['Default'],
      showMenuItem: null,
      showEditMenu: false,
    }
  },
  components: {
    Multiselect
  },
  mounted() {
    let v = _.cloneDeep(this.vendorInfo)
    this.bookingAttempts = v.bookingAttempts ? v.bookingAttempts : []
  },
  watch: {
    vendorInfo: {
      handler(v) {
        this.bookingAttempts = v.bookingAttempts ? v.bookingAttempts : []
      },
      deep: true
    }
  },
  methods: {
    toTimestamp(date) {
      if (typeof(date) !== 'string') {
        return date
      }
      // "2022-07-25 09:00"
      if (date.includes(':')) {
        let [date1, date2] = date.split(' ');
        let [year, month, day] = date1.split('-');
        let [hours, minutes] = date2.split(':');

        let datum = new Date(year + '-' + month + '-' + day + 'T' + hours + ':' + minutes + ':00');
        return datum.getTime();

      } else {
        let [year, month, day] = date.split('-');
        let datum = new Date(year + '-' + month + '-' + day + 'T00:00:00');
        return datum.getTime();
      }
    },

    save() {
      this.showEditMenu = false

      let addBooking = _.filter(this.addAttempts, (c) => {
        return !_.includes(this.bookingAttemptsBeforeEdit, (c))
      })

      NProgress.start();
      this.bookingAttempts = _.filter(this.bookingAttempts, (id) =>  {return !!(id)})
      Axios.post('/fe/booking-trackers', {addBookingAttempt: _.filter(addBooking, (id) =>  {return _.isString(id)}), removeBookingAttempt: _.filter(this.removeBookingAttempts, (id) =>  {return _.isString(id)})}
      ).then(res => {
        if (res.data._status) {
          this.isEdit = false
          this.$store.dispatch('loadVendorInfo');
          this.addAttempts = [];
          this.removeBookingAttempts = []
        }
      }).catch(e => {
        console.log(e);
      })
          .finally(()=>{
            NProgress.done();
          })
    },
    removeTrackers(id, index) {
      this.closePopupMenu()
      if (_.includes(this.addAttempts, id)) {
        if (id == null) {
          let nInd = _.find(this.addAttempts, (i) => {return i == null})
          if (nInd !== -1) {
            this.addAttempts.splice(nInd, 1)
          }
        } else {
          this.addAttempts = _.filter(this.addAttempts, (rId) => {
            return rId !== id
          })
        }
      }
      if (_.includes(this.bookingAttempts, id)) {
        if (id == null) {
          this.bookingAttempts.splice(index, 1)
        } else {
          this.bookingAttempts = _.filter(this.bookingAttempts, (rId) => {
            return rId !== id
          })
        }
      }
      this.removeBookingAttempts.push(id)
    },
    editBookingAttempts() {
      this.showEditMenu = true
      this.bookingAttemptsBeforeEdit = _.cloneDeep(this.bookingAttempts)
    },
    addBookingAttempts(id, index) {
      this.closePopupMenu();
      if (_.includes(this.removeBookingAttempts, id)) {
        this.removeBookingAttempts = _.filter(this.removeBookingAttempts, (rId) => {
          return rId !== id
        })
      }
      this.addAttempts.push(id)
      if (index == -1) {
        this.bookingAttempts.push(id)
      } else {
        this.bookingAttempts.splice(index, 1, id)
      }
    },
    showPopupMenu(id) {
      this.showMenuItem = id
    },
    closePopupMenu() {
      this.showMenuItem = null
    },
    cancelBookingAttempts() {
      this.showEditMenu = false
      this.addAttempts = []
      this.removeBookingAttempts = []
      this.bookingAttempts = this.bookingAttemptsBeforeEdit
    },
    isRevertMenuCheck(id) {
      let el = document.getElementById(id)?.getBoundingClientRect();
      if (!el || (window.innerWidth - el.right) <= 330) {
        return true
      }
      return false
    },

    getCountByStatusId(v) {
      return _.filter(this.computedEvents, (e) => {
        return e.attempt == v
      })?.length
    },
    goToCards(v) {
      this.$storage.set("views.cards.defaultView", "table-view");
      let trackerFilter = v
      this.$store.commit('setCardFilter', trackerFilter);
      this.$router.push({ name: 'Cards', params: {filters: this.getCardsByStatusId(v), trackerFilter: trackerFilter} })
    },
    getCardsByStatusId(v) {
      let events = _.filter(this.computedEvents, (e) => {
        return e.attempt == v
      })
      return _.map(events, (e) => {
        return e?.card?.id
      })
    },

  },
  computed: {
    ...mapGetters({
      vendorInfo: 'vendorInfo',
      getBookingItems: 'board/getBookingItems',
      filter: 'board/getBookingFilter',
      attempts: 'board/getAttempts',
    }),

    computedEvents() {
      let followUpArray = _.map(this.followUpAction, (v) =>{
        return _.toLower(v)
      })
      let events = _.filter(this.getBookingItems, (e) => {
        return e.type == 'on-site' && e.attempt && _.includes(followUpArray, e.followUpAction)
      })
      return _.map(events, (e) => {
        e.start = this.toTimestamp(e.dateStart + ' ' + e.timeStart);
        e.end = this.toTimestamp(e.dateEnd + ' ' + e.timeEnd);
        return e
      });
    },

    computedAttempts() {
      return _.filter(this.attempts, (r) => {
        return !_.includes(this.bookingAttempts, r)
      })
    }
  },
};
</script>

<style>
.V3 .multiselect-follow-up-booking-snapshot .multiselect__input {
  min-width: auto !important;
}
</style>
<style scoped>
.subtitle{
  font-weight: 700;
  font-size: 16px;
  padding-top: 6px;
}

.card-menu-popup-open-left {
  transform: translate(-121px, -8px) !important;
}
.card-menu-popup-open-left .sub-menu {
  left: -143px !important;
}

.reverse-label {
  display: flex;
  justify-content: end;
}

.cards-all-snapshot-main-total_item{
  text-align: center;
  flex: 20%;
  padding: 5px;
  max-width: 20%;
}
.cards-all-snapshot-main-total_item-border{
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  padding: 20px 20px;
  height: 100%;
  min-height: 135px;
}
.cards-all-snapshot-main-total_item:first-child{
  margin-left: 0;
}
.cards-all-snapshot-main-total_item:last-child{
  margin-right: 0;
}
.cards-all-snapshot-main-total_item-sum{
  font-weight: 700;
  font-size: 23px;
}
.cards-all-snapshot-main-total_item-name{
  margin-top: 10px;
  font-weight: 700;
  font-size: 15px;
}
.cards-all-snapshot-main-total_item-sum-bold{
  font-size: 25px;
  font-weight: 900;
}



@media screen and (max-width: 1100px) {

  .cards-all-snapshot-main-total_item{
    flex: 33.333%;
    max-width: 30%;
  }
}

</style>
