<template>
    <div class="col-lg new-card-box" :class="{'new-card-box-mobile': isMobile}">
        <div class="row">
            <div class="col-lg company-logo" style="background-color: rgba(27, 30, 56, 0.12)">
<!--                <img class="img-fluid" :src="profile_bg_image_style" width="374" height="111"/>-->
            </div>
        </div>
        <div class="row">
            <div class="col-lg text-center">
                <div class="user-prof">
                    <a href="#" data-toggle="dropdown" aria-expanded="false" class="d-flex items-center justify-content-center user-menu-cursor">
                        <span class="avatar">
                            <img class="" :src="profile_picture_url" @error="onImageError" class="hidden-xs-up hidden-sm-down">
                            <avatar
                                :username="`${userInfo.first_name} ${userInfo.last_name}`"
                                :src="!isImageError ? profile_picture_url : ''"
                                :size="70"
                                rounded
                                :customStyle="avatarStyle"
                            />
                        </span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-center fadeInUp fade-in-animated user-menu-hidden">
                      <span style="cursor: pointer;" class="dropdown-item" @click="$router.push({name: 'settings', params: {customTab: 'my-account', customSubTab: '',}}).catch(()=>{})">
                        <i class="ti-user mr-0-5"></i> My Account
                      </span>
                      <span v-if="isShopManagerUser" @click="$router.push({name: 'business', params: {customTab: 'business-profile', customSubTab: 'details'}}).catch(()=>{})" style="cursor: pointer;" class="dropdown-item">
                        <i class="ti-briefcase mr-0-5"></i>Business
                      </span>

                        <div class="dropdown-divider"></div>
                        <a href="#"  class="dropdown-item" @click="logout"><i class="ti-power-off mr-0-5"></i> Sign out</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="row card-body no-gutter">
            <div class="col-lg text-center user-name">
                {{ userInfo.first_name }} {{ userInfo.last_name }}
            </div>
            <div class="col-lg text-center company-name">
                {{ userInfo.business_name }}
            </div>
          <div v-if="(isShopManagerUser || isProductionManagerUser || isEstimatorUser || isPDRTechnicianUser || isCustomerUser) && isStatusActive" class="col-lg text-center">
            <router-link to="/cards/new" class="btn btn-primary add-new-card">
              <i class="ti-plus"></i>
              New Card
            </router-link>
          </div>
          <div v-if="!isMobile && (isShopManagerUser || isProductionManagerUser || isEstimatorUser || isPDRTechnicianUser || isCustomerUser)" :class="{'mt-50': !isStatusActive}"  class="info-box-row" >
            <div @click="openCardsByNewEmails" class="info-box text-center clickable">
                    <span class="info-txt">
                        {{countNewEmails}} <br/>
                      New Emails
                    </span>
            </div>
            <div @click="openCardsByNewTasks" class="info-box text-center clickable">
                    <span class="info-txt">
                        {{countNewTasks}} <br/>
                        New Tasks
                    </span>
            </div>
          </div>
          <div class="tabs-nav-right-search tabs-nav-right-search-font-weight" v-if="isMobile" style="min-width: 290px !important; margin: 0 auto;">
            <search-filter
                type="estimates"
                style="padding-left: 0px !important;"
                class="search-filter"
                v-model="searchFilter"
                :limitLength="50"
                :filterValue="searchFilter"
                @selectAdditional="redirectToCardEdit"
                @onEnter="onEnter"
                :placeholder="'Search'"
                :additionalMenu="additionalMenu"
                :debug="false"
                height="41px"
                aria-expanded="false"
                data-toggle="dropdown"></search-filter>
          </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import { vAvatar as Avatar} from '@amaury-tobias/v-avatar'
    import {isMobile} from "../../deviceDetect/index";
    import SearchFilter from "@/components/search-filter/search-filter-2.vue";
    import _ from "lodash";


    export default {
        name: "dashboard-new-card",
        components: {
          SearchFilter,
            Avatar,
        },
        data() {
            return {
                isImageError: false,
                searchFilter: '',
              isMobile
            }
        },
        methods: {
          redirectToCardEdit(itm) {
            this.$router.push('/cards/view/' + itm.value)
          },
          onEnter() {
            if (!_.isEmpty(this.additionalMenu) && !_.isEmpty(this.searchFilter)) {
              if (this.additionalMenu.length == 1) {
                this.redirectToCardEdit(this.additionalMenu[0])
              } else {
                this.$store.commit('setCardFilter', this.searchFilter);
                this.$router.push('/cards')
              }
            }
          },
            openCardsByNewEmails() {
                console.log('new emails ');
                this.$router.push({name: 'Cards', params: {filter: '#NewEmails', items: _.uniq(_.map(this.getEmails, (t) => {
                  return t.cardId
                }))}});
            },
            openCardsByNewTasks() {
                console.log('new tasks ');
                this.$router.push({name: 'Cards', params: {filter: '#NewTasks', items: _.uniq(_.map(this.cardsOfNewTasks, (c) => {
                  return c.card_id
                }))}});
            },
            onImageError() {
                this.isImageError = true
            },
            removeInStorageSavedTabs() {
              if (!_.isEmpty(this.$route.params.estimate_id)) {
                this.$storage.remove("views.estimateEdit." + this.$route.params.estimate_id + ".defaultView");
              } else if (!_.isEmpty(this.$route.params.card_id)) {
                this.$storage.remove("views.cardsEdit." + this.$route.params.card_id + ".defaultView");
              } else if (!_.isEmpty(this.$route.params.invoice_id)) {
                if (this.$route.path.includes('/excess')) {
                  this.$storage.remove("views.invoiceExcess." + this.$route.params.invoice_id + ".defaultView");
                } else {
                  this.$storage.remove("views.invoiceView." + this.$route.params.invoice_id + ".defaultView");
                }
              }
            },
            logout: function () {
                this.$store.dispatch('logout')
                .then(() => {
                    this.removeInStorageSavedTabs();
                    document.body.style.zoom = null;
                    this.$router.push('/')
                })
            },
        },
        computed: {
            ...mapGetters({
                isShopManagerUser: 'isShopManagerUser',
                isProductionManagerUser: 'isProductionManagerUser',
                isEstimatorUser: 'isEstimatorUser',
                isPDRTechnicianUser: 'isPDRTechnicianUser',
                isCustomerUser: 'isCustomerUser',
                countNewEmails: 'dashboard/countNewEmails',
                getEmails: 'dashboard/getEmails',
                countNewTasks: 'card/countNewTasks',
              cardsOfNewTasks: 'card/getCardsOfNewTasks',
                isStatusActive: 'isStatusActive',
              cardsAll: 'card/getAllCards',
            }),
          additionalMenu() {
            if (_.isEmpty(this.searchFilter)) {
              return []
            }
            let data = _.filter(this.cardsAll, (r) => {
              return _.startsWith(_.trim(_.toLower(r.card_number)), _.trim(_.toLower(this.searchFilter))) ||
                  _.startsWith(_.trim(_.toLower(r.rego_number)), _.trim(_.toLower(this.searchFilter))) ||
                  _.includes(_.trim(_.toLower(r.customer_name)), _.trim(_.toLower(this.searchFilter)))
            })
            let result = [];
            _.forEach(data, (itm) => {
              let label = itm.card_number
              if (itm.rego_number) {
                label +=  ' - '
              }

              if (itm.rego_number) {
                label += itm.rego_number + ' '
              }
              result.push({
                value: itm.card_id,
                label: label,
                estimateNumber: itm.card_number,
                regoNumber: itm.rego_number,
                customerName: itm.customer_name,
                insurerName: itm.insurer_name,
                make: itm.make,
                model: itm.model,
              })
            })
            result = _.orderBy(result, ['label'], ['asc']).slice(0, 5)
            return result
          },
            userInfo: function () {
                return this.$store.state.userInfo;
            },
            vendorInfo: function () {
                return this.$store.state.vendorInfo;
            },
            dasboardSummary: function () {
                return this.$store.state.dashboardSummary;
            },
            profile_picture_url: function () {
                return this.$store.getters.avatar;
                // return 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSd_fGmme8LQlwlE_m7Zxs9YQLJWzRrMGcHqQ&usqp=CAU';
            },
            profile_bg_image_style: function () {
                // if (this.vendorInfo.logo_picture != '') {
                //     return '/img/brands/' + this.vendorInfo.logo_picture;
                // } else {
                //     return '';
                // }
                return 'https://www.lifewire.com/thmb/kc1wqukCIVUR1AHVSdG5EYSA1D8=/768x0/filters:no_upscale():max_bytes(150000):strip_icc()/city_AlexanderSpatari_Getty-5a13972b47c26600378f0216.jpg'
            },
            avatarStyle() {
                return {
                    'font-family': `'Nunito Sans', sans-serif`,
                }
            }
        },
        mounted: function () {
            this.$store.dispatch('loadDashboardSummary');
        },
    };
</script>


<style scoped>
  .tabs-nav-right-search >>> .custom-dropdown{
    width: calc(100% + 60px);
  }
  .tabs-nav-right-search .search-filter >>> .form-control:focus{
    outline: 0 !important;
  }
  .tabs-nav-right-search .search-filter >>> .search-filter__box{
    border: 3px solid #5e77fd;
    border-radius: 7px;
  }
  .tabs-nav-right-search .search-filter >>> .search-filter__input{
    padding-top: 4px;
  }
  .tabs-nav-right-search .search-filter >>> .search-filter__input::placeholder{
    font-weight: 700;
    font-size: 16px;
    color: #5e78fc;
    text-align: center;
    padding-right: 27px;
  }
  .tabs-nav-right-search .search-filter >>> .custom-dropdown{
    top: 37px;
    padding-top: 2px;
  }
  .search-filter >>> .search-filter__box__focused{
    border: 3px solid rgba(94, 121, 255, 0.5) !important;
    box-shadow: 0 0 0 0.2rem rgb(94 121 255 / 25%) !important;
  }
  .tabs-nav-right-search .search-filter >>> .search-filter__input{
    font-size: 13px !important;
    font-weight: 800;
    color: black;
  }
  .tabs-nav-right-search >>> .custom-dropdown{
    width: calc(100% + 60px);
  }
  .tabs-nav-right-search .search-filter >>> .form-control:focus{
    outline: 0 !important;
  }
  .tabs-nav-right-search .search-filter >>> .search-filter__box{
    border: 3px solid #5e77fd;
    border-radius: 7px;
  }
  .tabs-nav-right-search .search-filter >>> .search-filter__input{
    padding-top: 4px;
  }
  .tabs-nav-right-search .search-filter >>> .search-filter__input::placeholder{
    font-weight: 700;
    font-size: 16px;
    color: #5e78fc;
    text-align: center;
    padding-right: 27px;
  }
  .mt-50 {
    margin-top: 50px;
  }
  .new-card-box {
    width: 374px;
    min-height: 350px;
    border-radius: 3px;
    background-color: white;
    padding: 0px;
  }
  .new-card-box-mobile{
    width: 100%;
  }

  .company-logo {
    height: 111px;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    padding: 0;
  }

  .company-logo img {
      object-fit: cover;
  }

  .user-prof {
    background-color: #818A91;
    width: 70px;
    height: 70px;
    border-radius: 35px;
    margin-top: -35px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    overflow: hidden;
  }

  .user-prof img {
      object-fit: cover;
  }

  .card-body {
      padding: 15px;
  }

  .user-name {
    font-family: "Nunito Sans";
    font-weight: 800;
    line-height: 18px;
    vertical-align: baseline;
    margin-bottom: 14px;
  }

  .company-name {
    font-family: "Nunito Sans";
    font-weight: 600;
    line-height: 18px;
    vertical-align: baseline;
    margin-bottom: 15px;
  }

  .add-new-card {
    padding: 10px;
    min-width: 134px;
    border-radius: 5px;
    background-color: #5E79FF;
    color: white;
    text-align: center;
    font-family: "Nunito Sans";
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 30px;
  }

  .add-new-card .ti-plus {
      margin-right: 5px;
      font-size: 16px;
      font-weight: 600;
      position: relative;
      top: 2px;
  }

  .info-box-row {
    background-color: white;
    display: flex;
    flex-flow: row unwrap;
    justify-content: center;
  }

  .info-box {
    box-sizing: border-box;
    height: 61px;
    border: 1px solid #5E79FF;
    border-radius: 3px;
    width: 100%;
    position: relative;
    padding-top: 10.5px;
    padding-bottom: 10.5px;
  }

  .info-box:first-child {
      margin-right: 14px;
  }

  .info-txt {
    font-family: "Nunito Sans";
    font-weight: 800;
    line-height: 18px;
    color: #1C1F39;
  }

  @media screen and (max-width: 373px) {
    .new-card-box {
        width: 100%;
    }
    .info-box:first-child {
        margin-bottom: 15px;
    }
  }

    .dropdown-menu-center {
        left: 120px;
    }

    @media screen and (min-width: 768px) {
        .user-menu-hidden {
            display: none !important;
        }
        .user-menu-cursor {
            cursor: default;
        }
    }
</style>
