<template>
  <div style="padding-left: 15px" id="estimates"
       class="tab-pane tab-pane--estimates col-12 active"
       role="tabpanel">

    <div class="row no-gutter">
      <div class="col-sm-6">
        <div class="card">
          <div class="card-header bg-navyblue">
            <strong>Estimate - Standard</strong>
          </div>
          <div class="card-block bg-white">
            <div class="form-group row no-gutter">
              <label for="estimate_prefix" class="col-sm-4 col-form-label">Estimate Prefix</label>
              <div class="col-sm-8">
                <input v-model="value.estimates.estimate_prefix"
                       :readonly="!isStatusActive"
                       type="text"
                       class="form-control" id="estimate_prefix"
                       placeholder="Estimate Prefix">
              </div>
            </div>
            <div class="form-group row no-gutter">
              <label for="estimate_terms" class="col-sm-4 col-form-label">Terms Estimate</label>
              <div class="col-sm-8">
                                                                  <textarea class="terms-editor"
                                                                            v-model="value.estimates.estimate_terms"
                                                                            cols="30" rows="10">
                                                                  </textarea>
              </div>
            </div>

            <div class="form-group row no-gutter">
              <label class="col-sm-4 col-form-label">Hide Series name <br/> from Estimate</label>
              <div class="col-sm-8">
                <label class="form-check-inline checkbox" style="padding-top: 8px;">
                  <input :disabled="!isStatusActive" class="form-check-input"
                         name="hide-series-checkbox" v-model="value.estimates.isHideSeries"
                         type="checkbox">
                  <span class="icon"><i class='bx bx-check'></i></span><span class="text"></span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header bg-navyblue">
            <strong>Auto Save</strong>
          </div>
          <div class="card-block bg-white">
            <label class="col-sm-4 col-form-label" style="padding-left: 0px;"> Auto-Save</label>
            <toggle-button
                v-model="computedSettingsEstimatesAutoSave"
                class="mb-0 rs-toggle__button"
                color="#5E79FF"
                :width="40"
                :disabled="!isStatusActive"
                :sync="true"
                :labels="false"/>
            <span class="form-text text-active d-inline-block col-form-label mt-0 ml-0-25">
                                                            <template
                                                                v-if="computedSettingsEstimatesAutoSave">Active</template>
                                                            <template v-else>Not Active</template>
                                                        </span>
          </div>
        </div>
      </div>

      <div class="col-sm-6 pl-1">

        <!-- Custom Rates -->
        <div class="card">
          <div class="card-header bg-navyblue">
            <strong>Rates</strong>
          </div>
          <div class="card-block bg-white">
            <div class="form-group row no-gutter">
              <label class="col-sm-4 col-form-label">Estimate Methodology</label>
              <div class="col-sm-8">
                <input readonly value="Custom" class="form-control">
              </div>
            </div>
            <div class="form-group row no-gutter">
              <label class="col-sm-4 col-form-label">RR</label>
              <div class="col-sm-8">
                <number-formatter ref="rr"
                                  v-model="value.rates.custom.default_rates_rr"
                                  type="text"
                                  class="form-control"
                                  :isDisabled="!isStatusActive"
                                  placeholder="RR Amount"
                                  format="$0,0.00"
                ></number-formatter>
              </div>
            </div>
            <div class="form-group row no-gutter">
              <label class="col-sm-4 col-form-label">Repair</label>
              <div class="col-sm-8">
                <number-formatter ref="repair" type="text"
                                  v-model="value.rates.custom.default_rates_repair"
                                  class="form-control" id="repair"
                                  placeholder="Repair"
                                  :isDisabled="!isStatusActive"
                                  format="$0,0.00"
                ></number-formatter>
              </div>
            </div>
            <div class="form-group row no-gutter">
              <label class="col-sm-4 col-form-label">Paint</label>
              <div class="col-sm-8">
                <number-formatter ref="paint" type="text"
                                  v-model="value.rates.custom.default_rates_paint"
                                  class="form-control" id="paint"
                                  placeholder="Paint"
                                  :isDisabled="!isStatusActive"
                                  format="$0,0.00"
                ></number-formatter>
              </div>
            </div>
            <div class="form-group row no-gutter">
              <label class="col-sm-4 col-form-label">Paint Material</label>
              <div class="col-sm-8">
                <multiselect id="custom_default_rates_paint_material"
                             v-model="computedEstimatesRatesCustomPaintMaterial"
                             :options="[{value: 'S1', label: 'S1'}, {value:'S2', label: 'S2'}, {value:'M1', label: 'M1'}, {value:'M2', label: 'M2'}, {value:'M3', label: 'M3'}, { value: 'M3PLUS', label: 'M3+'}]"
                             label="label"
                             track-by="value"
                             :showLabels="false"
                             :disabled="!isStatusActive"
                             style="opacity: 1"
                             :option-height="31.5"
                             :close-on-select="true"
                >
                </multiselect>
              </div>
            </div>
            <div class="form-group row no-gutter">
              <label class="col-sm-4 col-form-label">Mechanical</label>
              <div class="col-sm-8">
                <number-formatter type="text"
                                  class="form-control" id="mechanical"
                                  placeholder="Mechanical"
                                  :isDisabled="!isStatusActive"
                                  format="$0,0.00"
                                  v-model="value.rates.custom.default_rates_mechanical"
                ></number-formatter>
              </div>
            </div>
            <div class="row no-gutter mt-2">
              <div class="form-check">
                <label class="form-check-inline checkbox w-100">
                  <input class="form-check-input"
                         :checked="isDefaultMethodologyCustom"
                         :disabled="isDefaultMethodologyCustom || !isStatusActive"
                         type="checkbox"
                         name="DefaultMethodologyCustom"
                         value="true"
                         @change="setDefaultMethodology('custom')">
                  <span class="icon"><i class='bx bx-check'></i></span>
                  <span class="text">Make this my default for new Estimate</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <!-- NTAR Rates -->
        <div class="card">
          <div class="card-header bg-navyblue">
            <strong>Rates</strong>
          </div>
          <div class="card-block bg-white">
            <div class="form-group row no-gutter">
              <label class="col-sm-4 col-form-label">Estimate Methodology</label>
              <div class="col-sm-8">
                <input readonly value="NTAR" class="form-control" :readonly="!isStatusActive">
              </div>
            </div>
            <div class="form-group row no-gutter">
              <label class="col-sm-4 col-form-label">RR</label>
              <div class="col-sm-8">
                <number-formatter ref="rr"
                                  v-model="value.rates.ntar.default_rates_rr"
                                  type="text"
                                  class="form-control"
                                  :isDisabled="!isStatusActive"
                                  placeholder="RR Amount"
                                  format="$0,0.00"
                ></number-formatter>
              </div>
            </div>
            <div class="form-group row no-gutter">
              <label class="col-sm-4 col-form-label">Repair</label>
              <div class="col-sm-8">
                <number-formatter ref="repair" type="text"
                                  v-model="value.rates.ntar.default_rates_repair"
                                  class="form-control" id="repair"
                                  placeholder="Repair"
                                  :isDisabled="!isStatusActive"
                                  format="$0,0.00"
                ></number-formatter>
              </div>
            </div>
            <div class="form-group row no-gutter">
              <label class="col-sm-4 col-form-label">Paint</label>
              <div class="col-sm-8">
                <number-formatter ref="paint" type="text"
                                  v-model="value.rates.ntar.default_rates_paint"
                                  class="form-control" id="paint"
                                  placeholder="Paint"
                                  :isDisabled="!isStatusActive"
                                  format="$0,0.00"
                ></number-formatter>
              </div>
            </div>
            <div class="form-group row no-gutter">
              <label class="col-sm-4 col-form-label">Paint Material</label>
              <div class="col-sm-8">
                <multiselect id="ntar_default_rates_paint_material"
                             v-model="computedEstimatesRatesNTARPaintMaterial"
                             :options="[{value: 'S1', label: 'S1'}, {value:'S2', label: 'S2'}, {value:'M1', label: 'M1'}, {value:'M2', label: 'M2'}, {value:'M3', label: 'M3'}, { value: 'M3PLUS', label: 'M3+'}]"
                             label="label"
                             track-by="value"
                             :showLabels="false"
                             :option-height="31.5"
                             :disabled="!isStatusActive"
                             style="opacity: 1"
                             :close-on-select="true">
                </multiselect>
              </div>
            </div>
            <div class="form-group row no-gutter">
              <label class="col-sm-4 col-form-label">Mechanical</label>
              <div class="col-sm-8">
                <number-formatter type="text"
                                  class="form-control" id="mechanical"
                                  placeholder="Mechanical"
                                  :isDisabled="!isStatusActive"
                                  format="$0,0.00"
                                  v-model="value.rates.ntar.default_rates_mechanical"
                ></number-formatter>
              </div>
            </div>
            <div class="row no-gutter mt-2">
              <div class="form-check">
                <label class="form-check-inline checkbox w-100">
                  <input class="form-check-input"
                         :checked="isDefaultMethodologyNTAR"
                         :disabled="isDefaultMethodologyNTAR || !isStatusActive"
                         type="checkbox"
                         name="DefaultMethodologyNTAR"
                         value="true"
                         @change="setDefaultMethodology('ntar')">
                  <span class="icon"><i class='bx bx-check'></i></span>
                  <span class="text">Make this my default for new Estimate</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <!-- LTAR Rates -->
        <div class="card">
          <div class="card-header bg-navyblue">
            <strong>Rates</strong>
          </div>
          <div class="card-block bg-white">
            <div class="form-group row no-gutter">
              <label class="col-sm-4 col-form-label">Estimate Methodology</label>
              <div class="col-sm-8">
                <input readonly value="LTAR" class="form-control">
              </div>
            </div>
            <div class="form-group row no-gutter">
              <label class="col-sm-4 col-form-label">RR</label>
              <div class="col-sm-8">
                <number-formatter ref="rr"
                                  :isDisabled="!isStatusActive"
                                  v-model="value.rates.ltar.default_rates_rr"
                                  type="text"
                                  class="form-control"
                                  placeholder="RR Amount"
                                  format="$0,0.00"
                ></number-formatter>
              </div>
            </div>
            <div class="form-group row no-gutter">
              <label class="col-sm-4 col-form-label">Repair</label>
              <div class="col-sm-8">
                <number-formatter ref="repair" type="text"
                                  :isDisabled="!isStatusActive"
                                  v-model="value.rates.ltar.default_rates_repair"
                                  class="form-control" id="repair"
                                  placeholder="Repair"
                                  format="$0,0.00"
                ></number-formatter>
              </div>
            </div>
            <div class="form-group row no-gutter">
              <label class="col-sm-4 col-form-label">Paint</label>
              <div class="col-sm-8">
                <number-formatter ref="paint" type="text"
                                  :isDisabled="!isStatusActive"
                                  v-model="value.rates.ltar.default_rates_paint"
                                  class="form-control" placeholder="Paint"
                                  format="$0,0.00"
                ></number-formatter>
              </div>
            </div>
            <div class="form-group row no-gutter">
              <label class="col-sm-4 col-form-label">Paint Material</label>
              <div class="col-sm-8">
                <multiselect id="ltar_default_rates_paint_material"
                             ref="RatesPaintMaterial"
                             :disabled="!isStatusActive"
                             style="opacity: 1"
                             v-model="computedEstimatesRatesLTARPaintMaterial"
                             :options="[{value: 'S1', label: 'S1'}, {value:'S2', label: 'S2'}, {value:'M1', label: 'M1'}, {value:'M2', label: 'M2'}, {value:'M3', label: 'M3'}, { value: 'M3PLUS', label: 'M3+'}]"
                             label="label"
                             track-by="value"
                             :showLabels="false"
                             :option-height="31.5"
                             :close-on-select="true">
                </multiselect>
              </div>
            </div>
            <div class="form-group row no-gutter">
              <label class="col-sm-4 col-form-label">Mechanical</label>
              <div class="col-sm-8">
                <number-formatter type="text"
                                  class="form-control" id="mechanical"
                                  placeholder="Mechanical"
                                  :isDisabled="!isStatusActive"
                                  format="$0,0.00"
                                  v-model="value.rates.ltar.default_rates_mechanical"
                ></number-formatter>
              </div>
            </div>
            <div class="row no-gutter mt-2">
              <div class="form-check">
                <label class="form-check-inline checkbox w-100">
                  <input class="form-check-input"
                         :checked="isDefaultMethodologyLTAR"
                         :disabled="isDefaultMethodologyLTAR || !isStatusActive"
                         type="checkbox"
                         name="DefaultMethodologyLTAR"
                         value="true"
                         @change="setDefaultMethodology('ltar')">
                  <span class="icon"><i class='bx bx-check'></i></span>
                  <span class="text">Make this my default for new Estimate</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <!-- RS Rates -->
        <div v-if="isRsEnabled" class="card">
          <div class="card-header bg-navyblue">
            <strong>Rates</strong>
          </div>
          <div class="card-block bg-white">
            <div class="form-group row no-gutter">
              <label class="col-sm-4 col-form-label">Estimate Methodology</label>
              <div class="col-sm-8">
                <input readonly value="RS" class="form-control">
              </div>
            </div>
            <div class="form-group row no-gutter">
              <label class="col-sm-4 col-form-label">RR</label>
              <div class="col-sm-8">
                <number-formatter ref="rr"
                                  v-model="value.rates.rs.default_rates_rr"
                                  type="text"
                                  :isDisabled="!isStatusActive"
                                  class="form-control"
                                  placeholder="RR Amount"
                                  format="$0,0.00"
                ></number-formatter>
              </div>
            </div>
            <div class="form-group row no-gutter">
              <label class="col-sm-4 col-form-label">Repair</label>
              <div class="col-sm-8">
                <number-formatter ref="repair" type="text"
                                  v-model="value.rates.rs.default_rates_repair"
                                  class="form-control"
                                  placeholder="Repair"
                                  :isDisabled="!isStatusActive"
                                  format="$0,0.00"
                ></number-formatter>
              </div>
            </div>
            <div class="form-group row no-gutter">
              <label class="col-sm-4 col-form-label">Paint</label>
              <div class="col-sm-8">
                <number-formatter ref="paint" type="text"
                                  v-model="value.rates.rs.default_rates_paint"
                                  class="form-control" placeholder="Paint"
                                  :isDisabled="!isStatusActive"
                                  format="$0,0.00"
                ></number-formatter>
              </div>
            </div>
            <div class="form-group row no-gutter">
              <label class="col-sm-4 col-form-label">Paint Material</label>
              <div class="col-sm-8">
                <multiselect
                    ref="RatesPaintMaterial"
                    v-model="computedEstimatesRatesRSPaintMaterial"
                    :options="[{value: 'S1', label: 'S1'}, {value:'S2', label: 'S2'}, {value:'M1', label: 'M1'}, {value:'M2', label: 'M2'}, {value:'M3', label: 'M3'}, { value: 'M3PLUS', label: 'M3+'}]"
                    label="label"
                    track-by="value"
                    :showLabels="false"
                    :option-height="31.5"
                    :disabled="!isStatusActive"
                    style="opacity: 1"
                    :close-on-select="true">
                </multiselect>
              </div>
            </div>
            <div class="form-group row no-gutter">
              <label class="col-sm-4 col-form-label">Mechanical</label>
              <div class="col-sm-8">
                <number-formatter type="text"
                                  class="form-control" id="mechanical"
                                  placeholder="Mechanical"
                                  :isDisabled="!isStatusActive"
                                  format="$0,0.00"
                                  v-model="value.rates.rs.default_rates_mechanical"
                ></number-formatter>
              </div>
            </div>
            <div class="row no-gutter mt-2">
              <div class="form-check">
                <label class="form-check-inline checkbox w-100">
                  <input class="form-check-input"
                         :checked="isDefaultMethodologyRS"
                         :disabled="isDefaultMethodologyRS || !isStatusActive"
                         type="checkbox"
                         name="DefaultMethodologyRS"
                         value="true"
                         @change="setDefaultMethodology('rs')">
                  <span class="icon"><i class='bx bx-check'></i></span>
                  <span class="text">Make this my default for new Estimate</span>
                </label>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import NumberFormatter from '../utility/number-formatter'
import {mapGetters} from "vuex";
import _ from "lodash";
export default {
  name: "TheEstimates",
  props: {
    value: {}
  },
  components: {
    Multiselect,
    NumberFormatter,
  },
  computed: {
    ...mapGetters({
      isStatusActive: 'isStatusActive',
      isRsEnabled: 'isRsEnabled',
    }),
    isDefaultMethodologyRS: function () {
      return Boolean(this.isRsEnabled && this.value.rates.default == 'rs')
    },
    isDefaultMethodologyCustom: function () {
      if (this.value.rates.default == 'custom') {
        return true
      }
      return false
    },
    isDefaultMethodologyNTAR: function () {
      if (this.value.rates.default == 'ntar') {
        return true
      }
      return false
    },
    isDefaultMethodologyLTAR: function () {
      if (this.value.rates.default == 'ltar') {
        return true
      }
      return false
    },
    computedEstimatesRatesRSPaintMaterial: {
      get () {
        let vm = this
        try {
          let item = _.find([{ value: 'S1', label: 'S1' }, { value: 'S2', label: 'S2' }, {
            value: 'M1',
            label: 'M1'
          }, { value: 'M2', label: 'M2' }, { value: 'M3', label: 'M3' }, {
            value: 'M3PLUS',
            label: 'M3+'
          }], function (itm) {
            return itm.value === vm.value.rates.rs.default_rates_paint_material
          })
          return item
        } catch (e) {
          return {}
        }
      },
      set (value) {
        this.value.rates.rs.default_rates_paint_material = value.label
        //console.log('1', this.value.rates.ltar.default_rates_paint_material);
      },
    },
    computedSettingsEstimatesAutoSave: {
      get () {
        // console.log('autoSave====',JSON.stringify(this.value.estimates.autoSave));
        if (this.value.estimates.autoSave) {
          return true
        }
        if (this.value.estimates.autoSave === false) {
          return false
        }
        return true
      },
      set (val) {
        //console.log('autoSave',JSON.stringify(val));
        this.value.estimates.autoSave = val
      }
    },
    computedEstimatesRatesCustomPaintMaterial: {
      get () {
        let vm = this
        try {
          let item = _.find([{ value: 'S1', label: 'S1' }, { value: 'S2', label: 'S2' }, {
            value: 'M1',
            label: 'M1'
          }, { value: 'M2', label: 'M2' }, { value: 'M3', label: 'M3' }, {
            value: 'M3PLUS',
            label: 'M3+'
          }], function (itm) {
            return itm.value === vm.value.rates.custom.default_rates_paint_material
          })
          return item
        } catch (e) {
          return {}
        }
      },
      set (value) {
        this.value.rates.custom.default_rates_paint_material = value.label
        //console.log('1', this.value.rates.custom.default_rates_paint_material);
      },
    },
    computedEstimatesRatesNTARPaintMaterial: {
      get () {
        let vm = this
        try {
          let item = _.find([{ value: 'S1', label: 'S1' }, { value: 'S2', label: 'S2' }, {
            value: 'M1',
            label: 'M1'
          }, { value: 'M2', label: 'M2' }, { value: 'M3', label: 'M3' }, {
            value: 'M3PLUS',
            label: 'M3+'
          }], function (itm) {
            return itm.value === vm.value.rates.ntar.default_rates_paint_material
          })
          return item
        } catch (e) {
          return {}
        }
      },
      set (value) {
        this.value.rates.ntar.default_rates_paint_material = value.label
        //console.log('1', this.value.rates.ntar.default_rates_paint_material);
      },
    },
    computedEstimatesRatesLTARPaintMaterial: {
      get () {
        let vm = this
        try {
          let item = _.find([{ value: 'S1', label: 'S1' }, { value: 'S2', label: 'S2' }, {
            value: 'M1',
            label: 'M1'
          }, { value: 'M2', label: 'M2' }, { value: 'M3', label: 'M3' }, {
            value: 'M3PLUS',
            label: 'M3+'
          }], function (itm) {
            return itm.value === vm.value.rates.ltar.default_rates_paint_material
          })
          return item
        } catch (e) {
          return {}
        }
      },
      set (value) {
        this.value.rates.ltar.default_rates_paint_material = value.label
        //console.log('1', this.value.rates.ltar.default_rates_paint_material);
      },
    },
  },
  methods: {
    setDefaultMethodology: function (t) {
      this.value.rates.default = t
    },
  }
}
</script>

<style scoped>

</style>
