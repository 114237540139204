<script>
    import Axios from 'axios';
    import {mapGetters} from "vuex";
    import {isMobile, isMobileOnly} from '../../deviceDetect/index';

    export default {
        name: 'site-sidebar',
        data: function () {
            return {
                isMobile,
                settings: {
                    partssearch: {
                        api_key: '',
                        api_secret: ''
                    }
                },
                partssearch_enabled: false,
            };
        },
        methods: {
          isMobileOnly() {
            return isMobileOnly
          },
            logout: function () {
                this.$store.dispatch('logout')
                    .then(() => {
                        this.removeInStorageSavedTabs();
                        document.body.style.zoom = null;
                        this.$router.push('/');
                    });
            },
            goToReports() {
              this.$store.commit('report/setIsShowReportList', true);
              this.$store.commit('report/setSelectedOption', '');
            },
            removeInStorageSavedTabs() {
              if (!_.isEmpty(this.$route.params.estimate_id)) {
                this.$storage.remove("views.estimateEdit." + this.$route.params.estimate_id + ".defaultView");
              } else if (!_.isEmpty(this.$route.params.card_id)) {
                  this.$storage.remove("views.cardsEdit." + this.$route.params.card_id + ".defaultView");
              } else if (!_.isEmpty(this.$route.params.invoice_id)) {
                if (this.$route.path.includes('/excess')) {
                  this.$storage.remove("views.invoiceExcess." + this.$route.params.invoice_id + ".defaultView");
                } else {
                  this.$storage.remove("views.invoiceView." + this.$route.params.invoice_id + ".defaultView");
                }
              }
            },
            clickOverlay: function () {
                console.log('overlay');
                let body = document.querySelector('body');
                let first = document.querySelector('.site-header .sidebar-toggle-first');
                first.classList.remove('active');
                body.classList.remove('site-sidebar-opened');
            },
            updateSettings: function () {
                NProgress.start();
                Axios.post('/fe/user/config', this.settings)
                    .then(response => {
                        NProgress.done();
                        toastr.success(response.data.msg);
                    })
                    .catch(error => {
                        NProgress.done();
                        toastr.error(error);
                    });
            },
            checkPSApi: function () {
                this.partssearch_enabled = !this.partssearch_enabled;
                if (!this.partssearch_enabled) {
                    this.settings.partssearch.api_key = '';
                    this.settings.partssearch.api_secret = '';
                }
            }

        },
        computed: {
            ...mapGetters({
                isTechnicalUser: 'isTechnicalUser',
                isCustomerUser: 'isCustomerUser',
                isEstimatorUser: 'isEstimatorUser',
                isPDRTechnicianUser: 'isPDRTechnicianUser',
                isShopManagerUser: 'isShopManagerUser',
                isProductionManagerUser: 'isProductionManagerUser',
                isDisplayBoardUser: 'isDisplayBoardUser',
                menu: 'getMenu',
              vendorInfo: 'vendorInfo',
            }),
            isMenuLoaded() {
              return !_.isEmpty(this.menu);
            },
            path: function () {
                return this.$route.path;
            },
            isMenu: function () {
                return this.menu;
            },
            isBoards() {
                return this.$route.path.includes('/boards');
            }
        },
        watch: {
            path: function () {
            }
        },
        mounted: function () {
        }
    };
</script>

<template>
    <div >
        <!-- Sidebar -->
        <div class="site-overlay" @click="clickOverlay"></div>
        <div class="site-sidebar"
             :class="{ 'pt-1-25': isBoards,
              'sidebar-mobile': isMobile
              }"
             @click="clickOverlay">
<!--            <div class="main-logo">-->
<!--              <router-link to="/login" class="logo-link"></router-link>-->
<!--            </div>-->
            <div v-if="!isBoards" style="">
                <img src="../../assets/logos/brandslogo.png" class="navbar-brand-logo-svg"/>
            </div>
            <div class="x-custom-scroll custom-scroll-dark">
                <ul v-if="isMenuLoaded" class="sidebar-menu">
                    <li class="menu-title" v-if="menu.general.default">General</li>
                    <li :class="{'active': path == '/'}" v-if="menu.general.default && menu.general.workcenter && !isDisplayBoardUser">
                        <router-link to="/" class="waves-effect  waves-light">
                            <!--<a href="#/" class="waves-effect  waves-light">-->
                            <!-- <span class="tag tag-purple">22</span> -->
                            <span class="s-icon"><i class='bx bx-grid-alt'></i></span>
                            <span class="s-text">Workcenter</span>
                            <!--</a>-->
                        </router-link>
                    </li>
                    <li :class="{'active': path == '/cards'}" v-if="menu.general.default && menu.general.cards">
                        <router-link to="/cards" class="waves-effect  waves-light">
                            <span class="s-icon"><i class='bx bx-card'></i></span>
                            <span class="s-text">Cards</span>
                        </router-link>
                    </li>

                    <li :class="{'active': path == '/estimates'}" v-if="menu.general.default && menu.general.estimates && !isDisplayBoardUser">
                        <router-link to="/estimates" class="waves-effect  waves-light">
                            <span class="s-icon"><i class='bx bx-file'></i></span>
                            <span class="s-text">Estimates</span>
                        </router-link>
                    </li>
<!--                    <li :class="{'active': path == '/estimates-test-1'}" v-if="menu.general.default && menu.general.estimates && !isDisplayBoardUser">-->
<!--                      <router-link to="/estimates-test-1" class="waves-effect  waves-light">-->
<!--                        <span class="s-icon"><i class='bx bx-file'></i></span>-->
<!--                        <span class="s-text">Estimates Test 1</span>-->
<!--                      </router-link>-->
<!--                    </li>-->
<!--                    <li :class="{'active': path == '/estimates-test-2'}" v-if="menu.general.default && menu.general.estimates && !isDisplayBoardUser">-->
<!--                      <router-link to="/estimates-test-2" class="waves-effect  waves-light">-->
<!--                        <span class="s-icon"><i class='bx bx-file'></i></span>-->
<!--                        <span class="s-text">Estimates Test 2</span>-->
<!--                      </router-link>-->
<!--                    </li>-->
<!--                    <li :class="{'active': path == '/estimates-test-3'}" v-if="menu.general.default && menu.general.estimates && !isDisplayBoardUser">-->
<!--                      <router-link to="/estimates-test-3" class="waves-effect  waves-light">-->
<!--                        <span class="s-icon"><i class='bx bx-file'></i></span>-->
<!--                        <span class="s-text">Estimates Test 3</span>-->
<!--                      </router-link>-->
<!--                    </li>-->
                    <li :class="{'active': path == '/invoices'}" v-if="menu.general.default && menu.general.invoices && !isDisplayBoardUser">
                        <router-link to="/invoices" class="waves-effect  waves-light">
                            <span class="s-icon"><i class='bx bx-receipt'></i></span>
                            <span class="s-text">Invoices</span>
                        </router-link>
                    </li>
                    <li v-if="isShopManagerUser" :class="{'active': path == '/reports'}" @click="goToReports">
                      <router-link to="/reports" class="waves-effect  waves-light">
                        <span class="s-icon"><i class='bx bx-bar-chart-square'></i></span>
                        <span class="s-text">Reports</span>
                      </router-link>
                    </li>
                    <li v-if="!isMobileOnly() && (isShopManagerUser || isProductionManagerUser || isCustomerUser) && vendorInfo &&  vendorInfo.features && vendorInfo.features.features && vendorInfo.features.features.bookingsBoard" :class="{'active': path == '/boards/planner'}">
                      <router-link to="/boards/planner" class="waves-effect waves-light">
                        <span class="s-icon"><i class='bx bx-calendar'></i></span>
                        <span class="s-text">Bookings Board</span>
                      </router-link>
                    </li>
                    <li v-if="!isMobile && (isShopManagerUser || isProductionManagerUser || isDisplayBoardUser) && vendorInfo &&  vendorInfo.features && vendorInfo.features.features && vendorInfo.features.features.vehicleInBoard" :class="{'active': path == '/boards/vehicle-in'}">
                        <router-link to="/boards/vehicle-in" class="waves-effect waves-light">
                            <span class="s-icon"><i class='bx bx-chalkboard'></i></span>
                            <span class="s-text">Vehicle In Board</span>
                        </router-link>
                    </li>
                    <li v-if="!isMobile && (isShopManagerUser || isProductionManagerUser || isDisplayBoardUser) && vendorInfo &&  vendorInfo.features && vendorInfo.features.features && vendorInfo.features.features.departureBoard" :class="{'active': path == '/boards/departure'}">
                        <router-link to="/boards/departure" class="waves-effect waves-light">
                            <span class="s-icon"><i class='bx bx-chalkboard'></i></span>
                            <span class="s-text">Departure Board</span>
                        </router-link>
                    </li>
                    <!--                    <li :class="{'active': path == '/parts'}" v-if="menu.general.default && menu.general.parts">-->
                    <!--                        <router-link to="/parts" class="waves-effect  waves-light">-->
                    <!--                            <span class="s-icon"><i class='bx bx-layer'></i></span>-->
                    <!--                            <span class="s-text">Parts</span>-->
                    <!--                        </router-link>-->
                    <!--                    </li>-->

                    <li class="menu-title" v-if="menu.maintain.default">Maintain</li>
                    <li :class="{'active': path == '/estimates-template'}" v-if="menu.maintain.default && menu.maintain.estimateTemplates && !isDisplayBoardUser">
                        <router-link to="/estimates-template" class="waves-effect  waves-light">
                            <span class="s-icon"><i class='bx bx-windows'></i></span>
                            <span class="s-text">Estimate Template</span>
                        </router-link>
                    </li>
                    <li v-if="menu.maintain.default && menu.maintain.suppliers && !isDisplayBoardUser" :class="{'active': path == '/suppliers'}">
                        <router-link to="/suppliers" class="waves-effect  waves-light">
                            <span class="s-icon"><i class="bx bx-user-pin" aria-hidden="true"></i></span>
                            <span class="s-text">Suppliers</span>
                        </router-link>
                    </li>
                    <li :class="{'active': path == '/customers'}" v-if="menu.maintain.default && menu.maintain.customers && !isDisplayBoardUser">
                        <router-link to="/customers" class="waves-effect  waves-light">
                            <span class="s-icon"><i class='bx bx-user'></i></span>
                            <span class="s-text">Customers</span>
                        </router-link>
                    </li>
                    <li :class="{'active': path == '/insurers'}" v-if="menu.maintain.default && menu.maintain.insurers && !isDisplayBoardUser">
                        <router-link to="/insurers" class="waves-effect  waves-light">
                            <span class="s-icon"><i class='bx bx-check-shield'></i></span>
                            <span class="s-text">Insurers</span>
                        </router-link>
                    </li>
                    <li :class="{'active': path == '/assessors'}" v-if="menu.maintain.default && menu.maintain.assessors && !isDisplayBoardUser">
                        <router-link to="/assessors" class="waves-effect  waves-light">
                            <span class="s-icon"><i class="bx bx-user-check" aria-hidden="true"></i></span>
                            <span class="s-text">Assessors</span>
                        </router-link>
                    </li>
                    <li :class="{'active': path == '/settings'}" v-if="menu.maintain.default && menu.maintain.settings && isShopManagerUser && !isDisplayBoardUser">
                      <router-link to="/settings" class="waves-effect  waves-light">
                        <span class="s-icon"><i class='bx bx-slider-alt'></i></span>
                        <span class="s-text">Advanced Settings</span>
                      </router-link>
                    </li>
                    <li>
                        <a @click="logout" href="#" class="waves-effect  waves-light">
                            <span class="s-icon"><i class="ti-power-off"></i></span>
                            <span class="s-text">Sign out</span>
                        </a>
                    </li>
                    <!--                    <li class="menu-title" v-if="menu.partsSearch.default">PartsSearch</li>-->
                    <!--                    <li :class="{'active': path == '/estimate-info'}" v-if="menu.partsSearch.default && menu.partsSearch.estimateInfo">-->
                    <!--                        <router-link to="/estimate-info" class="waves-effect  waves-light">-->
                    <!--                            <span class="s-icon"><i class='bx bx-chat'></i></span>-->
                    <!--                            <span class="s-text">Estimate Information</span>-->
                    <!--                        </router-link>-->
                    <!--                    </li>-->
                    <!--                    <li :class="{'active': path == '/preferred-suppliers'}" v-if="menu.partsSearch.default && menu.partsSearch.preferredSuppliers">-->
                    <!--                        <router-link to="/preferred-suppliers" class="waves-effect  waves-light">-->
                    <!--                            <span class="s-icon"><i class='bx bx-check-double'></i></span>-->
                    <!--                            <span class="s-text">Preferred Suppliers</span>-->
                    <!--                            &lt;!&ndash;</a>&ndash;&gt;-->
                    <!--                        </router-link>-->
                    <!--                    </li>-->

                </ul>
            </div>
            <div class="navbar-logo-vertical" v-if="isBoards">
                <img src="../../assets/logos/brandslogo.png"/>
            </div>
        </div>

    </div>
</template>

<style scoped>
    .skin-3 .site-sidebar {
        /*        background-color: #142742;*/
        background: linear-gradient(180deg, #1C1F3A 0%, #161931 53.69%, #282C48 100%);
    }

    .skin-3 .site-sidebar .sidebar-menu .menu-title {
        color: #FFFFFF;
        opacity: 0.25;
        margin-bottom: 0px;
        padding-bottom: 2px;
    }

    .skin-3 .site-sidebar .sidebar-menu li > a {
        color: #FFFFFF;
        margin-left: 15px;
        margin-right: 15px;
        font-size: 14px;
    }

    .skin-3 .site-sidebar .sidebar-menu > li.active > a, .skin-3.compact-sidebar .site-sidebar .sidebar-menu > li:hover {
        /*background-color: #BBC2CE;*/
        background: linear-gradient(135deg, #7267E7 0%, #6259EF 49.12%, #4E45EE 100%);
        margin-left: 15px;
        margin-right: 15px;
        border-radius: 5px;
        color: #FFFFFF;
        margin-top: 1px;
        height: 35px;
    }

    .skin-3 .site-sidebar .sidebar-menu > li:hover > a {
        color: #FFFFFF;
    }

    .skin-3 .site-sidebar .sidebar-menu > li > a .s-icon i {
        color: #FFFFFF;
        font-size: 21px;
    }

    /* Version3 */
    .V3 .site-sidebar {
        /*        background-color: #142742;*/
        margin-top: 0px;
        background: linear-gradient(180deg, #1C1F3A 0%, #161931 53.69%, #282C48 100%);
        margin-top: 0px;
    }

    .V3 .site-sidebar .sidebar-menu .menu-title {
        color: #FFFFFF;
        opacity: 0.25;
    }

    .V3 .site-sidebar .sidebar-menu li > a {
        color: #FFFFFF;
        margin-left: 15px;
        margin-right: 15px;
        font-size: 14px;
    }

    .V3 .site-sidebar .sidebar-menu > li.active > a {
        /*background-color: #BBC2CE;*/
        background: linear-gradient(135deg, #7267E7 0%, #6259EF 49.12%, #4E45EE 100%);
        margin-left: 15px;
        margin-right: 15px;
        border-radius: 5px;
        color: #FFFFFF;
        marging-top: 1px;
    }

    .V3 .site-sidebar .sidebar-menu > li:hover > a {
        color: #FFFFFF;
    }

    .V3 .site-sidebar .sidebar-menu > li > a .s-icon i {
        color: #FFFFFF;
        font-size: 21px;
    }
</style>

<style>
    /* Version3 sidebar */
    .V3 .site-sidebar {
        position: absolute;
        z-index: 100000;
        top: 0;
        bottom: 0;
        left: 0;
        /* margin-top: 60px;*/
        width: 220px;
        /*background-color: #142742;*/
        background: linear-gradient(180deg, #1C1F3A 0%, #161931 53.69%, #282C48 100%);
    }
    .V3 .site-sidebar.sidebar-mobile {
      width: 220px !important;
    }

    .V3 .site-sidebar.sidebar-mobile {
      left: -220px;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
    }
    .V3 .site-sidebar-opened .site-sidebar.sidebar-mobile {
      left: 0;
    }

    .V3 .site-sidebar .custom-scroll {
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .V3 .site-sidebar ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .V3 .site-sidebar .sidebar-menu .menu-title {
        margin-top: 21px;
        margin-bottom: 15px;
        padding: 0.5rem 1.25rem;
        font-size: 13px;
        font-weight: 800;
        letter-spacing: 0.93px;
        line-height: 18px;
        color: rgba(255, 255, 255, 0.25);
        text-transform: uppercase;
    }

    .V3 .site-sidebar .sidebar-menu li {
        position: relative;
        height: 36px;
        margin-bottom: 0px;
    }

    .V3 .site-sidebar .sidebar-menu li > a {
        display: block;
        position: relative;
        padding: 7px 1.25rem;
        color: rgba(255, 255, 255, 0.7);
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    .V3 .site-sidebar .sidebar-menu li > a:hover,
    .site-sidebar .sidebar-menu li.active > a {
        color: #FFFFFF;
    }

    .V3 .site-sidebar .sidebar-menu > li.active > a {
        background-color: rgba(255, 255, 255, 0.1);
    }

    .V3 .site-sidebar.sidebar-mobile .sidebar-menu > li.active > a:after {
      display: none;
    }

    .V3 .site-sidebar .sidebar-menu > li > a .s-icon {
        float: left;
        margin-right: 1.125rem;
    }

    .V3 .site-sidebar .sidebar-menu > li > a .s-icon i {
        position: relative;
        /*font-size: 1.15rem;*/
        color: #FFFFFF;
    }

    .V3 .site-sidebar .sidebar-menu > li > a .s-text {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 0px;
    }

    .V3 .site-sidebar .sidebar-menu > li.with-sub > a .s-caret {
        float: right;
        margin-left: 1rem;
        font-size: 14px;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    .V3 .site-sidebar .sidebar-menu > li.with-sub.active > a .s-caret {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    .V3 .site-sidebar .sidebar-menu > li > a .tag {
        float: right;
        margin-top: 2px;
        border-radius: 1rem;
    }

    .V3 .site-sidebar .sidebar-menu ul {
        display: none;
    }

    .V3 .site-sidebar .sidebar-menu ul ul {
        margin-left: 1rem;
        display: none;
    }

    .V3 .site-sidebar .sidebar-menu ul li > a {
        padding: 0.5rem 1rem 0.5rem 3.5rem;
    }

    /* Layout settings */
    .V3 .fixed-sidebar .site-sidebar {
        position: fixed;
    }
</style>

<style>
    /*Vesrion3 compact sidebar */

        .V3 .V3.compact-sidebar .site-sidebar:not(.sidebar-mobile) .sidebar-menu li {
            position: relative;
            height: 36px;
            margin-bottom: 0px;
        }

        .V3 .V3.compact-sidebar .site-sidebar:not(.sidebar-mobile) {
            width: 70px !important;
        }

        .V3 .V3.compact-sidebar .site-sidebar:not(.sidebar-mobile) .custom-scroll,
        .V3 .V3.compact-sidebar .site-sidebar:not(.sidebar-mobile) .jspContainer {
            overflow: visible !important;
        }

        .V3 .V3.compact-sidebar .jspVerticalBar {
            width: 0 !important;
        }

        .V3 .V3.compact-sidebar .site-sidebar:not(.sidebar-mobile) .sidebar-menu .menu-title {
            display: none;
        }

        .V3 .V3.compact-sidebar .site-sidebar:not(.sidebar-mobile) .sidebar-menu li.compact-hide {
            display: none;
        }

        .V3 .V3.compact-sidebar .site-sidebar:not(.sidebar-mobile) .sidebar-menu > li:hover {
            margin-left: 15px;
            margin-top: 1px;
            margin-right: 15px;
            border-radius: 5px;
            color: #FFFFFF;
            width: 290px;
            background: none;
        }

        .V3 .V3.compact-sidebar .site-sidebar:not(.sidebar-mobile) .sidebar-menu li.active > a {
            background: linear-gradient(135deg, #7267E7 0%, #6259EF 49.12%, #4E45EE 100%);
            padding-left: 10px;
            margin: 0px;
            margin-left: 15px;
        }

        .V3 .V3.compact-sidebar .site-sidebar:not(.sidebar-mobile) .sidebar-menu li.active:hover > a {
            margin: 0px;
            margin-left: 0px;
            border-radius: 5px;
        }

        .V3 .V3.compact-sidebar .site-sidebar:not(.sidebar-mobile) .sidebar-menu > li.active > a:after {
            display: none;
        }

        .V3 .V3.compact-sidebar .site-sidebar:not(.sidebar-mobile) .sidebar-menu > li > a {
            margin: 0px;
            margin-left: 15px;
            border-radius: 5px 0px 0px 5px;
            padding: 7px 16px 7px 10px;
        }

        .V3 .V3.compact-sidebar .site-sidebar:not(.sidebar-mobile) .sidebar-menu li:hover > a {
            background: linear-gradient(135deg, #7267E7 0%, #6259EF 49.12%, #4E45EE 100%);
            margin: 0px;
            margin-left: 0px;
        }

        .V3 .V3.compact-sidebar .site-sidebar:not(.sidebar-mobile) .sidebar-menu > li > a .s-icon {
            margin-right: 15px;
        }

        .V3 .V3.compact-sidebar .site-sidebar:not(.sidebar-mobile) .sidebar-menu > li > a .s-icon i {
            top: 0;
            font-size: 1.5rem;
        }

        .V3 .V3.compact-sidebar .site-sidebar:not(.sidebar-mobile) .sidebar-menu > li > a > .tag {
            position: absolute;
            z-index: 10;
            top: 5px;
            left: 35px;
        }

        .V3 .V3.compact-sidebar .site-sidebar:not(.sidebar-mobile) .sidebar-menu > li > a > .s-text,
        .V3 .V3.compact-sidebar .site-sidebar:not(.sidebar-mobile) .sidebar-menu > li > a > .s-caret {
            display: none;
        }

        .V3 .V3.compact-sidebar .site-sidebar:not(.sidebar-mobile) .sidebar-menu > li li > a > .s-caret {
            color: #999999;
        }

        .V3 .V3.compact-sidebar .site-sidebar:not(.sidebar-mobile) .sidebar-menu li > ul {
            position: absolute;
            top: 100%;
            left: 70px;
            width: 220px;
            background: #FFFFFF;
            border-left: none;
        }

        .V3 .V3.compact-sidebar .site-sidebar:not(.sidebar-mobile) .sidebar-menu li:hover > a > .s-text {
            display: block;
        }

        .V3 .V3.compact-sidebar .site-sidebar:not(.sidebar-mobile) .sidebar-menu li:hover > ul {
            display: block !important;
        }

        .V3 .V3.compact-sidebar .site-sidebar:not(.sidebar-mobile) .sidebar-menu ul li > a {
            padding: 0.5rem 1rem;
            color: #777777;
        }

        .V3 .V3.compact-sidebar .site-sidebar:not(.sidebar-mobile) .sidebar-menu ul li > a:hover,
        .V3 .V3.compact-sidebar .site-sidebar:not(.sidebar-mobile) .sidebar-menu ul li.active > a {
            color: #333333;
        }

        .V3 .V3.compact-sidebar .site-sidebar:not(.sidebar-mobile) .sidebar-menu ul li.active > a {
            font-weight: bold;
        }
</style>

<style>
    /* LOGOS */
    /* For 1x devices */
    .V3 .navbar-brand-logo-svg {
        display: block;
        width: 157px;
        height: 33px;
        margin-left: 15px;
        margin-top: 12px;
    }

    .V3 .navbar-logo-vertical {
        transform: rotate(-90deg);
        position: relative;
        top: 120px;
    }

    .V3 .navbar-logo-vertical img {
        width: 150px;
    }

    .V3 .compact-sidebar .navbar-brand-logo-svg {
        width: 50px;
        height: auto;
    }

    /* For 2x devices */
    @media only screen and ( -webkit-min-device-pixel-ratio: 2 ), only screen and ( -webkit-min-device-pixel-ratio: 1.5 ), only screen and ( -moz-min-device-pixel-ratio: 2), only screen and ( -o-min-device-pixel-ratio: 3/2), only screen and ( -o-min-device-pixel-ratio: 2/1), only screen and ( min--moz-device-pixel-ratio: 1.5), only screen and ( min-device-pixel-ratio: 1.5), only screen and ( min-device-pixel-ratio: 2) {
        .V3 .navbar-brand-logo-svg {
        }
    }

    /* For 3x devices */
    @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 480dpi) {
        .V3 .navbar-brand-logo-svg {
        }
    }
</style>
