<template>
  <div>
    <canvas ref="canvas" :width="canvasWidth2" :height="canvasHeight2"></canvas>
  </div>
</template>

<script>
import { Canvas, PencilBrush, IText, Rect, Ellipse, Line, Polygon, Path, Group } from 'fabric';
import * as fabric from 'fabric'

export default {
  props: {
    imageUrl: {
      type: String,
      required: true
    },
    canvasWidth: {
      type: [String,Number],
      required: true
    },
    canvasHeight: {
      type: [String,Number],
      required: true
    },
  },
  mounted() {
    this.initCanvas();
  },
  created() {
    this.canvasWidth2 = this.canvasWidth
    this.canvasHeight2 = this.canvasHeight
  },
  data() {
    return {
      canvas: null,
      cropeGroup: null,
      isDown: false,
      image: null,
      canvasWidth2: null,
      canvasHeight2: null,
      currentMode: null,
      params: null,
      activeObject: null,
      isCroppedImage: false,
      origX: 0,
      origY: 0,

      line: null,
      arrowHead1: null,
      history: [],
      historySize: [],
      historyIndex: -1,
      isArrowMoved: false,
    }
  },
  methods: {
    saveState() {
      if (this.historyIndex < this.history.length - 1) {
        this.history.splice(this.historyIndex + 1);
        this.historySize.splice(this.historyIndex + 1);
      }
      this.history.push(this.canvas.toJSON());
      this.historySize.push({
        width: this.canvas.width,
        height: this.canvas.height,
        angle: this.image.angle,
      })
      this.historyIndex++;
    },
    saveImage() {
      this.cancelCropping();
      return this.canvas.toDataURL('image/jpeg', 1);
    },
    cancelCropping() {
      if (this.currentMode == 'crop' && this.cropeGroup) {
        let objects = this.canvas.getObjects();
        let cropObjInd = objects.indexOf(this.cropeGroup)
        if (cropObjInd !== -1) {
          this.canvas.remove(objects[cropObjInd])
          this.canvas.renderAll();
          this.$emit('cancelCrope')
        }
      }
    },
    loadState(index) {
      if (index >= 0 && index < this.history.length) {
        this.canvas.loadFromJSON(this.history[index], () => {
          this.$nextTick(() => {
            setTimeout(() => {

              if (this.canvas.width !== this.historySize[index].width) {
                this.canvas.setDimensions({
                  width: this.historySize[index].width,
                  height: this.historySize[index].height,
                })
                this.canvas.renderAll();
                this.image.scaleToHeight(this.historySize[index].height);
                this.image.scaleToWidth(this.historySize[index].width);
                this.canvas.renderAll()
              }

              if (this.image.angle !== this.historySize[index].angle) {
                this.image.rotate(this.historySize[index].angle);
              }

              this.canvas.getObjects().forEach((obj) => {
                if (obj.type == 'image') {
                  obj.selectable = false;
                  obj.hasControls = false;
                }
                if (obj.type == 'group') {
                  this.canvas.remove(obj)
                  this.canvas.renderAll();
                  this.$emit('cancelCrope')
                }
              })
              this.canvas.renderAll()
            }, 0)
          })
        });
        this.historyIndex = index;
      }
    },
    toDataUrl(url, callback) {
      let xhr = new XMLHttpRequest();
      xhr.onload = function () {
        let reader = new FileReader();
        reader.onloadend = () => {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    },
    set(type, params) {
      this.cancelCropping();
      this.currentMode = type
      this.canvas.isDrawingMode = false;
      this.params = params
      switch (type) {
        case "rotateRight":
          this.rotateRight();
          break;
        case "rotateLeft":
          this.rotateLeft();
          break;
        case "freeDrawing":
          this.freeDrawing(params);
          break;
        case "crop":
          this.addCropRect()
          this.saveState()
          break;
      }
    },
    undo() {
      this.loadState(this.historyIndex - 1);
    },
    redo() {
      this.loadState(this.historyIndex + 1);
    },
    applyCropping () {
      if (!this.cropeGroup) {
        return
      }

      let actualSize = _.cloneDeep(this.cropeGroup).getBoundingRect()

      let objects = this.canvas.getObjects();
      let cropObjInd = objects.indexOf(this.cropeGroup)
      if (cropObjInd !== -1) {
        this.canvas.remove(objects[cropObjInd])
        this.canvas.renderAll();
      }

      let croppedUrl = this.canvas.toDataURL({
        left: actualSize.left,
        top: actualSize.top,
        width: actualSize.width,
        height: actualSize.height,
      });

      this.canvas.setDimensions({ width: actualSize.width, height: actualSize.height });
      this.isCroppedImage = true
      let img = new Image();
      this.toDataUrl(croppedUrl, (dataUri) => {
        img.src = dataUri;
        let inst = this;
        img.onload = function () {
          let image = new fabric.Image(img);
          image.selectable = false;
          inst.canvas.add(image);
          inst.image = image
          inst.canvas.renderAll();
          inst.saveState()
        }

      });
    },
    addCropRect() {
      const rect = new Rect({
        left: this.canvas.width / 2 - 150,
        top: this.canvas.height / 2 - 150,
        width: 302,
        height: 302,
        fill: 'transparent',
        stroke: 'black',
        strokeWidth: 2,
      });

      let squares = [];

      let squareSize = 100;
      for (let i = 0; i < 3; i++) {
        for (let j = 0; j < 3; j++) {
          const square = new Rect({
            left: (i * squareSize) + rect.left,
            top: (j * squareSize) + rect.top,
            width: squareSize,
            height: squareSize,
            fill: 'transparent',
            stroke: 'black',
            strokeWidth: 2,
            selectable: false,
          });
          squares.push(square);
        }
      }

      let group = new Group([rect, ...squares], {
        left: rect.left,
        top: rect.top,
        hasControls: true,
        lockScalingFlip: true,
        cornerColor: 'black',
        cornerStrokeColor: 'black',
        cornerStyle: 'circle',
        transparentCorners: false,
      });

      group.clipPath = new Path(`M ${rect.left} ${rect.top}
          L ${rect.left + rect.width} ${rect.top}
          L ${rect.left + rect.width} ${rect.top + rect.height}
          L ${rect.left} ${rect.top + rect.height}
          Z`, {
        fill: 'transparent',
        selectable: false,
      });

      this.canvas.add(group);
      this.canvas.setActiveObject(group);
      this.cropeGroup = group
      this.canvas.renderAll();
    },
    addLine(event) {
      this.canvas.selection = false;
      let pointer = this.canvas.getPointer(event.e);
      this.line = new Line([pointer.x, pointer.y, pointer.x, pointer.y], {
        fill: this.params.stroke,
        stroke: this.params.stroke,
        strokeWidth: 7,
        hasBorders: false,
        hasControls: false,
        selectable: false,
        hoverCursor: 'crosshair',
        moveCursor :'crosshair'
      })

      this.arrowHead1 = new Polygon([
        {x: 0, y: 0},
        {x: -20, y: -10},
        {x: -20, y: 10}
      ], {
        stroke: this.params.stroke,
        strokeWidth: 3,
        fill: this.params.stroke,
        hasBorders: false,
        hasControls: false,
        selectable: false,
        top: pointer.y,
        left: pointer.x,
        hoverCursor: 'crosshair',
        moveCursor :'crosshair',
        originY: 'center',
        originX: 'center'
      })
      this.canvas.add(this.line, this.arrowHead1)
      this.canvas.renderAll()
    },
    addCircle(event) {
      // if (this.canvas.getActiveObject() || this.activeObject) {
      //   return
      // }
      let pointer = this.canvas.getPointer(event.e);
      let circle = new Ellipse({
        left: this.origX,
        top: this.origY,
        rx: 0,
        ry: 0,
        transparentCorners: false,
        hasBorders: false,
        hasControls: false,
        selectable: false,
        fill: 'transparent',
        stroke: this.params.stroke,
        strokeWidth: 7,
        radius: 20,
        hoverCursor: 'crosshair',
        moveCursor :'crosshair'
      });
      this.canvas.add(circle)
      this.canvas.setActiveObject(circle);
      this.saveState()
      // circle.on({
      //   'moving': this.saveState,
      //   'modified': this.saveState
      // });
    },
    addRect(event) {
      // if (this.canvas.getActiveObject() || this.activeObject) {
      //   return
      // }

      let pointer = this.canvas.getPointer(event.e);
      let rect = new Rect({
        left: pointer.x,
        top: pointer.y,
        originX: 'left',
        originY: 'top',
        transparentCorners: false,
        stroke: this.params.stroke,
        hasBorders: false,
        hasControls: false,
        selectable: false,
        width: null,
        height: null,
        fill:  'transparent',
        strokeWidth: 7,
        hoverCursor: 'crosshair',
        moveCursor :'crosshair'

      });
      this.canvas.add(rect);
      this.canvas.setActiveObject(rect);
      this.saveState()
      // rect.on({
      //   'moving': this.saveState,
      //   'modified': this.saveState
      // });
    },
    addText(event) {
      if (this.canvas.getActiveObject() && !this.canvas.getActiveObject().text) {
        this.canvas.getActiveObject().selectable = false;
        this.canvas.getActiveObject().evented = false;
      }
      if (!((!this.canvas.getActiveObject() && !this.activeObject) || (this.canvas.getActiveObject() && !this.canvas.getActiveObject().text))) {
        return
      }
      this.canvas.selection = true
      let pointer = this.canvas.getPointer(event.e)
      const text = new IText('Add text', {
        ...this.params,
        left: pointer.x,
        top: pointer.y,
      });
      this.canvas.add(text);
      text.set({
        hasBorders: false,
        hasControls: true,
        selectable: true
      })
      // this.canvas.centerObject(text);
      this.canvas.setActiveObject(text);
      text.enterEditing();
      text.selectAll()
      this.saveState()
      // text.on({
      //   'moving': this.saveState,
      //   'modified': this.saveState
      // });
    },
    freeDrawing(params) {
      this.canvas.isDrawingMode = true;
      this.canvas.freeDrawingBrush = new PencilBrush(this.canvas)
      this.canvas.freeDrawingBrush.color = params.stroke;
      this.canvas.freeDrawingBrush.width = 7;
    },
    initCanvas() {
      this.canvas = new Canvas(this.$refs.canvas, {defaultCursor: "crosshair" });
      let img = new Image();
      this.toDataUrl(this.imageUrl, (dataUri) => {
        img.src = dataUri;
        let inst = this;
        img.onload = function () {
          let image = new fabric.Image(img);
          image.scaleToWidth(inst.canvasWidth2);
          image.scaleToHeight(inst.canvasHeight2);
          image.selectable = false;
          image.hasControls = false;
          image.hoverCursor = 'crosshair'
          image.moveCursor = 'crosshair'
          inst.canvas.add(image);
          inst.image = image
          inst.canvas.renderAll();

          inst.saveState()
        }

      });

      this.canvas.on('mouse:up', (e) => {
        this.isDown = false
        if (this.canvas.isDrawingMode) {
          this.saveState()
        }
        if ((this.currentMode == 'arrow' || this.currentMode == 'circle' || this.currentMode == 'rect') && this.isArrowMoved) {
          this.isArrowMoved = false
          this.saveState()
        }
      })
      this.canvas.on('mouse:down', (e) => {
        this.isDown = true
        let pointer = this.canvas.getPointer(e.e);
        this.origX = pointer.x;
        this.origY = pointer.y;

        if (this.currentMode == 'text') {
          this.addText(e)
        } else if (this.currentMode == 'circle') {
          this.addCircle(e)
        } else if (this.currentMode == 'rect') {
          this.addRect(e)
        } else if (this.currentMode == 'arrow') {
          this.addLine(e)
        }
      })
      let self = this;
      this.canvas.on("mouse:move", function (o) {
        if (self.currentMode == 'text') {
          self.activeObject = self.canvas.getActiveObject();
        } else if (self.currentMode == 'circle' || self.currentMode == 'rect') {
          self.canvas.selection = false;
          let pointer = self.canvas.getPointer(o.e);


          const width = pointer.x - self.origX;
          const height = pointer.y - self.origY;



          self.activeObject = self.canvas.getActiveObject();
          if (!self.activeObject || !self.isDown) {
            return
          }
          self.isArrowMoved = true
          self.activeObject.noScaleCache = false;
          self.activeObject.strokeUniform = true;
          self.activeObject.lockMovementX = false;
          self.activeObject.lockMovementY = false;
          self.activeObject.lockUniScaling = false;
          if (self.currentMode == "rect") {
            self.activeObject.set({
              width: Math.abs(width),
              height: Math.abs(height)
            });

            self.activeObject.set({
              left: width < 0 ? pointer.x : self.origX,
              top: height < 0 ? pointer.y : self.origY
            });
          }
          if (self.currentMode == "circle") {
            self.activeObject.set({
              rx: Math.abs(width / 2),
              ry: Math.abs(height / 2)
            });

            self.activeObject.set({
              left: width < 0 ? pointer.x : self.origX,
              top: height < 0 ? pointer.y : self.origY
            });
          }
          self.activeObject.setCoords();
          self.canvas.renderAll();
        } else if (self.currentMode == 'arrow') {
          if (self.isDown) {
            self.isArrowMoved = true
            let pointer = self.canvas.getPointer(o.e);
            self.line.set({
              x2: pointer.x,
              y2: pointer.y
            })

            self.arrowHead1.set({
              left: pointer.x,
              top: pointer.y
            })

            let x1 = self.line.x1;
            let y1 = self.line.y1;
            let x2 = pointer.x;
            let y2 = pointer.y;

            let verticalHeight = Math.abs(y2 - y1)
            let horizontalWidth = Math.abs(x2-x1);
            let tanRatio = verticalHeight / horizontalWidth;
            let basicAngle = Math.atan(tanRatio)*180 / Math.PI;

            if (x2 > x1) {
              if (y2 < y1) {
                self.arrowHead1.set({
                  angle: -basicAngle
                })
              } else if ( y2 === y1) {
                self.arrowHead1.set({
                  angle: 0
                })
              } else if (y2 > y1) {
                self.arrowHead1.set({
                  angle: basicAngle
                })
              }
            } else if (x2 < x1) {
              if (y2 > y1) {
                self.arrowHead1.set({
                  angle: 180 - basicAngle
                })
              } else if (y2 == y1) {
                self.arrowHead1.set({
                  angle: 180
                })
              } else if (y2 < y1) {
                self.arrowHead1.set({
                  angle: 180 + basicAngle
                })
              }
            }

            self.line.setCoords()
            self.arrowHead1.setCoords()
            self.canvas.renderAll()
          }
        }
      });
    },
    clear() {
      // const currentImage = this.image;
      // this.canvas.clear();
      // if (currentImage) {
      //   this.canvas.add(currentImage);
      // }
      // this.canvas.renderAll();
      this.history.splice(1, this.history.length + 1)
      this.historySize.splice(1, this.historySize.length + 1)
      this.historyIndex = 0
      this.loadState(0)
    },
    rotate(t) {
      let count = t == 'right' ? 90 : 270
      let angle = (this.image.angle + count) % 360;
      if (angle == 90 || angle == 270) {
        this.canvasWidth2 = this.canvasHeight
        this.canvasHeight2 = this.canvasWidth
      } else {
        this.canvasWidth2 = this.canvasWidth
        this.canvasHeight2 = this.canvasHeight
      }

      this.canvas.setDimensions({ width: this.canvasWidth2, height: this.canvasHeight2 });
      this.canvas.renderAll();
      this.image.scaleToHeight(this.canvasHeight2);
      this.image.scaleToWidth(this.canvasWidth2);

      this.$nextTick(() => {
        if (this.image) {
          this.image.rotate(this.image.angle + count);

          const scaleX = this.canvasWidth / this.image.width;
          const scaleY = this.canvasHeight / this.image.height;

          let left = 0;
          let top = 0;

          if (angle == 90) {
            left = this.canvasWidth2
          } else if (angle == 180) {
            left = this.canvasWidth
            top = this.canvasHeight
          } else if (angle == 270) {
            left = 0
            top = this.canvasHeight2
          }

          this.image.set({
            left: left,
            top: top,
            originX: 'left',
            originY: 'top',
            scaleX: scaleX,
            scaleY: scaleY,
          })

          let degrees = t == 'right' ? 90 : -90
          this.canvas.getObjects().forEach((obj) => {
            if (obj !== this.image) {
              obj.set('angle', obj.get('angle') + degrees);
              if (degrees === 90) {
                if (angle == 90 || angle == 270) {
                  obj.set({
                    left: this.canvasWidth2 - obj.top,
                    top: obj.left,
                  });
                } else {
                  obj.set({
                    top:  obj.left,
                    left: this.canvasWidth - obj.top,
                  });
                }
              } else if (degrees === -90) {
                if (angle == 90 || angle == 270) {
                  obj.set({
                    top:  this.canvasWidth - obj.left,
                    left: obj.top,
                  });
                } else {
                  obj.set({
                    top:  this.canvasHeight - obj.left,
                    left: obj.top,
                  });
                }
              }
              obj.setCoords();
            }
          });

        }
        this.canvas.renderAll();
        this.saveState()
      })
    },
    rotateLeft() {
      this.rotate('left')
    },
    rotateRight() {
      this.rotate('right')
    }
  },
  beforeDestroy() {
    if (this.canvas) {
      this.canvas.dispose();
    }
  }
};
</script>
