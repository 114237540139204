import {Actions} from './actions';
import {Mutations} from './mutations';
import {Getters} from './getters';

const state = {
  itemsIn: [],
  itemsDeparture: [],
  timestamp: 0,
  zoomScaleIn: 1.4,
  zoomScaleDeparture: 1.4,
  bookingItems: [],
  production: {
    "definitions": {
      "categories": [],
      "numberOfDays": {
        "weld": null,
        "mechanical": null,
        "hail": null,
        "electrical": null,
        "ppf": null,
        "accessories": null
      }
    },
    "days": [
      false,
      true,
      true,
      true,
      true,
      true,
      false,
    ],
    "targets": {
      "job": null,
      "labour": null
    }
  },
  attempts: [],
  reasons: [],
  isLoadedPrev: false,
  bookingFilter: 'Week',
};

export default {
  namespaced: true,
  mutations: Mutations,
  actions: Actions,
  state,
  getters: Getters,
};
