<template>
  <div class="">
    <div class="row no-gutter header-content">
      <div class="col-12 col-md-4 chart-title">
        Average Estimates Value
      </div>
      <div class="col-12 col-md-6 header-right d-flex">
        <div class="navbar-nav nav dropdown dropdown-options  show inline" style="margin-left: 99px;">
          <button type="button"
                  class="btn waves-effect waves-light ex-options-button"
                  id="dropdownMenuLink1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true">
            Export
            <span class="dropdown-arrow pull-right" style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                                    <i class="fa fa-chevron-down pull-right"></i>
                                </span>
          </button>
          <div class="dropdown-menu dropdown-menu-right animated fadeInUp"
               aria-labelledby="dropdownMenuLink">
            <a class="dropdown-item text-primary p-1 clickable" @click="exportReport('csv')">.CSV</a>
            <a class="dropdown-item text-primary p-1 clickable" @click="exportReport('xlsx')">.XLS</a>
          </div>
        </div>
        <div class="navbar-nav nav dropdown dropdown-options  show inline">
          <button type="button"
                  class="btn waves-effect waves-light ex-options-button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true">
            {{adjustedBy}}
            <span class="dropdown-arrow pull-right" style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                                    <i class="fa fa-chevron-down pull-right"></i>
                                </span>
          </button>
          <div class="dropdown-menu dropdown-menu-right animated fadeInUp"
               aria-labelledby="dropdownMenuLink">
            <a class="dropdown-item text-primary p-1 clickable" @click="adjustedBy = 'Week'">Week</a>
            <a class="dropdown-item text-primary p-1 clickable" @click="adjustedBy = 'Month'">Month</a>
            <a class="dropdown-item text-primary p-1 clickable" @click="adjustedBy = 'Quarter'">Quarter</a>
            <a class="dropdown-item text-primary p-1 clickable" @click="adjustedBy = 'Year'">Year</a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isEmptyReportsData" class="mt-2 text-right chart-interval-block">
      <div>
        {{getDate}}
      </div>
    </div>
    <div class="chart-wrapper col-12 col-md-8">
      <vue-apex-chart
          v-if="isHasData"
          width="1000"
          height="500"
          type="bar"
          :series="computedSeries"
          :options="computedChartOptions"
      ></vue-apex-chart>

      <div v-if="isHasData">
        <div class="navbar-nav nav dropdown dropdown-options  show inline" style="right: -827px; margin-bottom: 5px;">
          <button type="button"
                  class="btn waves-effect waves-light ex-options-button"
                  v-on:click="showFilters = !showFilters">
            Filters
            <span class="dropdown-arrow pull-right" style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                    <i v-if="!showFilters" class="fa fa-chevron-down pull-right"></i>
                    <i v-if="showFilters" class="fa fa-chevron-up pull-right"></i>
                  </span>
          </button>

        </div>
        <div v-if="showFilters" class="active-filters-control">

          <div class="filters-checkboxes">
            <span class="filters-checkboxes-title">Display By Insurer</span>
            <label class="form-check-inline checkbox filters-checkbox" style="margin-right: 35px">
              <input v-model="isDisplayByInsurer" class="form-check-input" type="checkbox" name="ins">
              <span class="icon"><i class='bx bx-check'></i></span><span  class="text">Insurer</span>
            </label>
            <label class="form-check-inline checkbox filters-checkbox">
              <input v-model="isDisplayByNonInsurance" class="form-check-input" type="checkbox" name="nis">
              <span class="icon"><i class='bx bx-check'></i></span><span class="text" style="width: 122px">Non-Insurance</span>
            </label>
          </div>

          <div class="filters-checkboxes">
            <span class="filters-checkboxes-title">Display Difference</span>
            <label class="form-check-inline radio filters-checkbox">
              <input class="form-check-input" type="radio"
                     v-model="isDisplayDifference"
                     :value="true">
              <span class="icon"><i class='bx bx-check'></i></span><span class="text">Yes</span>
            </label>
            <label class="form-check-inline radio filters-checkbox">
              <input class="form-check-input" type="radio"
                     v-model="isDisplayDifference"
                     :value="false">
              <span class="icon"><i class='bx bx-check'></i></span><span class="text">No</span>
            </label>
          </div>

        </div>
      </div>

      <b-table
        v-if="isHasData"
        responsive
        hover
        :items="chartDataForTable"
        :fields="fields"
        class="report-table"
      >
        <template v-slot:cell(average)="data">
          <div v-if="data.item.average === 0">
            -
          </div>
          <div v-else>
            {{data.item.average | formatMoney}}
          </div>
        </template>
        <template v-slot:cell(insuranceAverage)="data">
          <div v-if="data.item.insuranceAverage === 0">
            -
          </div>
          <div v-else>
            {{data.item.insuranceAverage | formatMoney}}
          </div>
        </template>
        <template v-slot:cell(nonInsuranceAverage)="data">
          <div v-if="data.item.nonInsuranceAverage === 0">
            -
          </div>
          <div v-else>
            {{data.item.nonInsuranceAverage | formatMoney}}
          </div>
        </template>
      </b-table>
<!--      <p class="no-data-text" v-if="!isHasData">No data for graph</p>-->
    </div>
  </div>
</template>

<script>
  import {appConfig} from '../../../../config'
  import Multiselect from 'vue-multiselect';
  import _ from 'lodash';
  import {mapActions, mapGetters} from "vuex";
  import moment from "moment";
  import VueApexCharts from 'vue-apexcharts';
  import accounting from "accounting";

  export default {
    name: "averageValue",
    components: {
      "vue-apex-chart": VueApexCharts,
      Multiselect,
    },
    mounted() {
      NProgress.start();
      this.actionLoadReports()
      let self = this;
      this.intervalUpdateReports = setInterval(() => {
        if (!_.isEmpty(self.getterAEV) && !_.isEmpty(self.computedSeries) && !_.isEmpty(self.computedChartOptions) && !_.isEmpty(self.chartDataForTable)) {
          NProgress.done();
        }
      }, 1000);
    },
    beforeDestroy() {
      clearInterval(this.intervalUpdateReports);
    },
    data() {
      return {
        months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        adjustedBy: 'Month',
        showFilters: false,
        isDisplayByInsurer: true,
        isDisplayByNonInsurance: true,
        isDisplayDifference: true,
        intervalUpdateReports: null,
      }
    },
    methods: {
      ...mapActions({
        actionLoadReports: 'report/loadEPSAndAEVAndEVS',
      }),
      formatMoneyFunc(value) {
        return accounting.formatMoney(value)
      },
      exportReport(format) {
        let token = localStorage.getItem('token');
        let type = 'ps';
        let period = _.toLower(this.adjustedBy);
        let value = 'average';

        if (this.isDisplayByInsurer && !this.isDisplayByNonInsurance) {
          value = 'insuranceAverage';
        } else if (!this.isDisplayByInsurer && this.isDisplayByNonInsurance) {
          value = 'nonInsuranceAverage';
        }

        let url = appConfig.baseAPIURL + `/fe/reports/download/estimates/${type}/${period}/${value}/${format}?at=${token}`;
        if (value === 'average' && this.isDisplayDifference) {
          url += '&displayDifference=yes'
        }
        window.open(url);
        return;
      },
      getCurrentFilters() {
        let filters = '';
        if (this.isDisplayByNonInsurance === false || this.isDisplayByInsurer === false) {
          if (this.isDisplayByInsurer === true) {
            filters += 'isDisplayByInsurer;';
          }
          if (this.isDisplayByNonInsurance === true) {
            filters += 'isDisplayByNonInsurance;';
          }
        }
        if (this.isDisplayDifference === true) {
          filters += 'isDisplayDifference;';
        }
        if (filters == '') {
          return false;
        }
        return filters;
      },
    },
    computed: {
      ...mapGetters({
        getterAEV: 'report/getAEV',
      }),
      computedSeries() {
        let data = [{
          data: [],
        }];
        if (this.isDisplayDifference) {
          data = [
            {
              name: 'Insurer',
              data: []
            },
            {
              name: 'Non-Insurance',
              data: []
            }
          ]
        }
        if (!this.chartDataForTable) {
          return  data;
        }
        let reportData = this.adjustedBy === 'Month' || this.adjustedBy === 'Quarter' ? this.chartDataForTable.slice().reverse() : this.chartDataForTable.slice();
        _.forEach(reportData, (itm) => {
          if (this.isDisplayDifference) {
            data[0].data.push(itm.insuranceAverage)
            data[1].data.push(itm.nonInsuranceAverage)
          } else {
            data[0].data.push(itm.average)
          }
        })

        if (this.adjustedBy === 'Month') {
          if (this.isDisplayDifference) {
            data[0].data = data[0].data.slice(6, 12)
            data[1].data = data[1].data.slice(6, 12)
          } else {
            data[0].data = data[0].data.slice(6, 12)
          }
        }

        return data;
      },
      currentMonth() {
        return new Date().getMonth();
      },
      computedChartOptions() {
        let self = this
        let options = {
          chart: {
            id: 'vuechart-example',
            // stacked: true,
            toolbar: {
              show: false,
            },
          },
          colors: ['#3366cc', '#dc3912', '#ff9900', '#109618', '#990099', '#0099c6', '#dd4477', '#66aa00', '#b82e2e', '#316395', '#994499', '#22aa99', '#aaaa11', '#6633cc', '#e67300', '#8b0707', '#651067', '#329262', '#5574a6', '#3b3eac', '#b77322', '#16d620', '#b91383', '#f4359e', '#9c5935', '#a9c413', '#2a778d', '#668d1c', '#bea413', '#0c5922', '#743411', '#f4359e', '#b91383', '#16d620', '#b77322', '#3b3eac', '#5574a6', '#329262', '#329262', '#651067', '#8b0707', '#e67300', '#6633cc', '#aaaa11', '#22aa99', '#994499', '#316395', '#b82e2e', '#66aa00', '#dd4477', '#0099c6', '#990099', '#109618', '#ff9900', '#dc3912', '#3366cc', '#ca24d6', '#378784', '#ab8965', '#141e7d', '#edc7c7', '#167d76', '#d5f74a', '#3022c9', '#705658'],
          xaxis: {
            categories: []
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return self.formatMoneyFunc(value)
              }
            },
          },
          legend: {
            showForNullSeries: false,
            position: 'top',
            height: 38,
          },
          tooltip: {
            marker: {show: true},
            enabled: true,
            y: {
              title: {
                formatter: () => {
                  return 'Average Estimate Value: '
                },
              },
            },
            style: {
              fontSize: '13px',
              fontFamily: 'Nunito Sans',
              fontWeight: 'bold',
            },
          },
          dataLabels: {
            enabled: false,
          },
        }
        let result = [];
        if (this.adjustedBy === 'Month') {
          let prevMonths = [];
          let self = this;
          _.forEach(this.months, function (item) {
            if (self.months.indexOf(item) > self.currentMonth) {
              prevMonths.push(item)
            }
          })

          let currentMonths = [];
          _.forEach(self.months, function (item) {
            let index = self.months.indexOf(item)
            if (index <= self.currentMonth) {
              currentMonths.push(item)
            }
          });

          _.forEach(prevMonths, function (item) {
            result.push(item)
          })
          _.forEach(currentMonths, function (item) {
            result.push(item)
          });
        } else if (this.adjustedBy === 'Week') {
          result = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
        } else if (this.adjustedBy === 'Quarter') {
          let q1 = 'January - March';
          let q2 = 'April - June';
          let q3 = 'July - September';
          let q4 = 'October - December';

          let i1 = [0, 1, 2];
          let i2 = [3, 4, 5];
          let i3 = [6, 7, 8];
          let i4 = [9, 10, 11];

          if (i1.includes(this.currentMonth)) {
            result = [
              q2,
              q3,
              q4,
              q1
            ]
          } else if (i2.includes(this.currentMonth)) {
            result = [
              q3,
              q4,
              q1,
              q2,
            ]
          } else if (i3.includes(this.currentMonth)) {
            result = [
              q4,
              q1,
              q2,
              q3,
            ]
          } else if (i4.includes(this.currentMonth)) {
            result = [
              q1,
              q2,
              q3,
              q4,
            ]
          }
        } else if (this.adjustedBy === 'Year') {
          for (let key in this.getterAEV.year) {
            result.push(key);
          }
        }

        options.xaxis.categories.splice(0, options.xaxis.categories.length);
        _.forEach(result, item => {
          options.xaxis.categories.push(item);
        })

        if (this.adjustedBy === 'Month') {
          options.xaxis.categories = options.xaxis.categories.slice(6, 12)
        }

        return options;
      },
      isHasData() {
        if (this.isEmptyReportsData) {
          return false;
        }
        if (this.adjustedBy === 'Week') {
          let isEmptyWeek = true;
          for (let day in this.getterAEV.week) {
            if (!_.isEmpty(this.getterAEV.week[day])) {
              isEmptyWeek = false;
              break
            }
          }
          return !this.isEmptyReportsData && !isEmptyWeek;
        } else if (this.adjustedBy === 'Year') {
          let isEmptyYear = true;
          for (let year in this.getterAEV.year) {
            if (!_.isEmpty(this.getterAEV.year[year])) {
              isEmptyYear = false;
              break
            }
          }
          return !this.isEmptyReportsData && !isEmptyYear;
        } else if (this.adjustedBy === 'Month') {
          let isEmptyMonth = true;
          for (let month in this.getterAEV.month) {
            if (!_.isEmpty(this.getterAEV.month[month])) {
              isEmptyMonth = false;
              break
            }
          }
          return !this.isEmptyReportsData && !isEmptyMonth;
        } else if (this.adjustedBy === 'Quarter') {
          let isEmptyQuarter = true;
          for (let quarter in this.getterAEV.quarter) {
            if (!_.isEmpty(this.getterAEV.quarter[quarter])) {
              isEmptyQuarter = false;
              break
            }
          }
          return !this.isEmptyReportsData && !isEmptyQuarter;
        }
      },
      getDate() {
        let lastIndex = this.computedChartOptions.xaxis.categories.length - 1;
        if (this.isEmptyReportsData) {
          return  '';
        }
        if (this.adjustedBy === "Week") {
          let monday = moment().startOf('isoweek').toDate();
          let sunday   = moment().endOf('isoweek').toDate();

          let mDay = String(monday.getDate()).padStart(2, '0');
          let mMonth = String(monday.getMonth() + 1).padStart(2, '0');
          let mYear = monday.getFullYear();

          let mResult = mDay + '/' + mMonth + '/' + mYear;

          let sDay = String(sunday.getDate()).padStart(2, '0');
          let sMonth = String(sunday.getMonth() + 1).padStart(2, '0');
          let sYear = sunday.getFullYear();

          let sResult = sDay + '/' + sMonth + '/' + sYear;

          return 'Mon' + ' ' + mResult + ' - ' + 'Sun ' + sResult;
        } else if (this.adjustedBy === 'Month') {
          let firstMonth = this.computedChartOptions.xaxis.categories[0];
          let lastMonth = this.computedChartOptions.xaxis.categories[lastIndex];
          let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

          if (lastMonth === 'December') {
            let y1 = new Date().getFullYear();

            return  firstMonth + ' ' + y1 + ' - ' + lastMonth + ' ' + y1;
          }

          if (months.indexOf(firstMonth) > months.indexOf(lastMonth)) {
            let y2 = new Date().getFullYear();
            let y1 = y2 - 1;

            return  firstMonth + ' ' + y1 + ' - ' + lastMonth + ' ' + y2;
          }
        } else if (this.adjustedBy === 'Quarter') {
          let firstQuarter = this.computedChartOptions.xaxis.categories[0];
          let lastQuarter = this.computedChartOptions.xaxis.categories[lastIndex];
          let quarters = ['January - March', 'April - June', 'July - September', 'October - December']

          if (lastQuarter === 'October - December') {
            let y1 = new Date().getFullYear();

            return  firstQuarter + ' ' + y1 + ' - ' + lastQuarter + ' ' + y1;
          }

          if (quarters.indexOf(firstQuarter) > quarters.indexOf(lastQuarter)) {
            let y2 = new Date().getFullYear();
            let y1 = y2 - 1;

            return  firstQuarter + ' ' + y1 + ' to ' + lastQuarter + ' ' + y2;
          }
        } else if (this.adjustedBy === 'Year') {
          let firstYear = this.computedChartOptions.xaxis.categories[0];
          let lastYear = this.computedChartOptions.xaxis.categories[lastIndex];

          return firstYear + ' - ' + lastYear;
        }
      },
      isEmptyReportsData() {
        return _.isEmpty(this.getterAEV);
      },
      fields() {
        let data = [];
        if (this.adjustedBy === 'Month') {
          data.push(
            {
              label: "Month",
              key: "month",
              sortable: true,
              tdClass: 'clickable',
            },
          )
        } else if (this.adjustedBy === 'Week') {
          data.push(
            {
              label: "Day",
              key: "day",
              sortable: true,
              tdClass: 'clickable',
            },
          )
        } else if (this.adjustedBy === 'Year') {
          data.push(
            {
              label: "Year",
              key: "year",
              sortable: true,
              tdClass: 'clickable',
            },
          )
        } else if (this.adjustedBy === 'Quarter') {
          data.push(
            {
              label: "Quarter",
              key: "quarter",
              sortable: true,
              tdClass: 'clickable',
            },
          )
        }
        if (this.isDisplayDifference && this.isDisplayByInsurer && this.isDisplayByNonInsurance) {
          data.push(
              {
                label: 'Average Estimate Value - Insurance',
                key: 'insuranceAverage',
                sortable: true,
                tdClass: 'clickable',
              }
          );
          data.push(
              {
                label: 'Average Estimate Value - Non-Insurance',
                key: 'nonInsuranceAverage',
                sortable: true,
                tdClass: 'clickable',
              }
          );
        } else if (this.isDisplayByNonInsurance && !this.isDisplayByInsurer) {
          data.push(
              {
                label: 'Average Estimate Value',
                key: 'nonInsuranceAverage',
                sortable: true,
                tdClass: 'clickable',
              }
          );
        } else if (!this.isDisplayByNonInsurance && this.isDisplayByInsurer) {
          data.push(
              {
                label: 'Average Estimate Value',
                key: 'insuranceAverage',
                sortable: true,
                tdClass: 'clickable',
              }
          );
        } else {
          data.push(
              {
                label: 'Average Estimate Value',
                key: 'average',
                sortable: true,
                tdClass: 'clickable',
              }
          );
        }
        return data
      },
      chartDataForTable () {
        if (this.isEmptyReportsData) {
          return  null;
        }
        if (this.adjustedBy === 'Month') {
          let d = new Date();
          let currentMonth = d.getMonth();
          let months = [
            {
              month: 'January',
              average: this.getterAEV.month.January.average,
              insuranceAverage: this.getterAEV.month.January.insuranceAverage,
              nonInsuranceAverage: this.getterAEV.month.January.nonInsuranceAverage,
            },
            {
              month: 'February',
              average: this.getterAEV.month.February.average,
              insuranceAverage: this.getterAEV.month.February.insuranceAverage,
              nonInsuranceAverage: this.getterAEV.month.February.nonInsuranceAverage,
            },
            {
              month: 'March',
              average: this.getterAEV.month.March.average,
              insuranceAverage: this.getterAEV.month.March.insuranceAverage,
              nonInsuranceAverage: this.getterAEV.month.March.nonInsuranceAverage,
            },
            {
              month: 'April',
              average: this.getterAEV.month.April.average,
              insuranceAverage: this.getterAEV.month.April.insuranceAverage,
              nonInsuranceAverage: this.getterAEV.month.April.nonInsuranceAverage,
            },
            {
              month: 'May',
              average: this.getterAEV.month.May.average,
              insuranceAverage: this.getterAEV.month.May.insuranceAverage,
              nonInsuranceAverage: this.getterAEV.month.May.nonInsuranceAverage,
            },
            {
              month: 'June',
              average: this.getterAEV.month.June.average,
              insuranceAverage: this.getterAEV.month.June.insuranceAverage,
              nonInsuranceAverage: this.getterAEV.month.June.nonInsuranceAverage,
            },
            {
              month: 'July',
              average: this.getterAEV.month.July.average,
              insuranceAverage: this.getterAEV.month.July.insuranceAverage,
              nonInsuranceAverage: this.getterAEV.month.July.nonInsuranceAverage,
            },
            {
              month: 'August',
              average: this.getterAEV.month.August.average,
              insuranceAverage: this.getterAEV.month.August.insuranceAverage,
              nonInsuranceAverage: this.getterAEV.month.August.nonInsuranceAverage,
            },
            {
              month: 'September',
              average: this.getterAEV.month.September.average,
              insuranceAverage: this.getterAEV.month.September.insuranceAverage,
              nonInsuranceAverage: this.getterAEV.month.September.nonInsuranceAverage,
            },
            {
              month: 'October',
              average: this.getterAEV.month.October.average,
              insuranceAverage: this.getterAEV.month.October.insuranceAverage,
              nonInsuranceAverage: this.getterAEV.month.October.nonInsuranceAverage,
            },
            {
              month: 'November',
              average: this.getterAEV.month.November.average,
              insuranceAverage: this.getterAEV.month.November.insuranceAverage,
              nonInsuranceAverage: this.getterAEV.month.November.nonInsuranceAverage,
            },
            {
              month: 'December',
              average: this.getterAEV.month.December.average,
              insuranceAverage: this.getterAEV.month.December.insuranceAverage,
              nonInsuranceAverage: this.getterAEV.month.December.nonInsuranceAverage,
            },
          ];
          let prevMonths = months.slice(currentMonth + 1);
          let currentMonths = months.slice(0, currentMonth + 1);
          return _.concat(currentMonths.reverse(), prevMonths.reverse())

        } else if (this.adjustedBy === 'Week') {
          return [
            {
              day: 'Monday',
              average: this.getterAEV.week.Monday.average,
              insuranceAverage: this.getterAEV.week.Monday.insuranceAverage,
              nonInsuranceAverage: this.getterAEV.week.Monday.nonInsuranceAverage,
            },
            {
              day: 'Tuesday',
              average: this.getterAEV.week.Tuesday.average,
              insuranceAverage: this.getterAEV.week.Tuesday.insuranceAverage,
              nonInsuranceAverage: this.getterAEV.week.Tuesday.nonInsuranceAverage,
            },
            {
              day: 'Wednesday',
              average: this.getterAEV.week.Wednesday.average,
              insuranceAverage: this.getterAEV.week.Wednesday.insuranceAverage,
              nonInsuranceAverage: this.getterAEV.week.Wednesday.nonInsuranceAverage,
            },
            {
              day: 'Thursday',
              average: this.getterAEV.week.Thursday.average,
              insuranceAverage: this.getterAEV.week.Thursday.insuranceAverage,
              nonInsuranceAverage: this.getterAEV.week.Thursday.nonInsuranceAverage,
            },
            {
              day: 'Friday',
              average: this.getterAEV.week.Friday.average,
              insuranceAverage: this.getterAEV.week.Friday.insuranceAverage,
              nonInsuranceAverage: this.getterAEV.week.Friday.nonInsuranceAverage,
            },
            {
              day: 'Saturday',
              average: this.getterAEV.week.Saturday.average,
              insuranceAverage: this.getterAEV.week.Saturday.insuranceAverage,
              nonInsuranceAverage: this.getterAEV.week.Saturday.nonInsuranceAverage,
            },
            {
              day: 'Sunday',
              average: this.getterAEV.week.Sunday.average,
              insuranceAverage: this.getterAEV.week.Sunday.insuranceAverage,
              nonInsuranceAverage: this.getterAEV.week.Sunday.nonInsuranceAverage,
            },
          ]
        } else if (this.adjustedBy === 'Quarter') {
          let q1 = [0, 1, 2];
          let q2 = [3, 4, 5];
          let q3 = [6, 7, 8];
          let q4 = [9, 10, 11];

          let d = new Date();
          let currentMonth = d.getMonth();

          let result = [];

          if (q1.includes(currentMonth)) {
            result = [
              {
                quarter: 'January - March',
                average: this.getterAEV.quarter['January - March'].average,
                insuranceAverage: this.getterAEV.quarter['January - March'].insuranceAverage,
                nonInsuranceAverage: this.getterAEV.quarter['January - March'].nonInsuranceAverage,
              },
              {
                quarter: 'October - December',
                average: this.getterAEV.quarter['October - December'].average,
                insuranceAverage: this.getterAEV.quarter['October - December'].insuranceAverage,
                nonInsuranceAverage: this.getterAEV.quarter['October - December'].nonInsuranceAverage,
              },
              {
                quarter: 'July - September',
                average: this.getterAEV.quarter['July - September'].average,
                insuranceAverage: this.getterAEV.quarter['July - September'].insuranceAverage,
                nonInsuranceAverage: this.getterAEV.quarter['July - September'].nonInsuranceAverage,
              },
              {
                quarter: 'April - June',
                average: this.getterAEV.quarter['April - June'].average,
                insuranceAverage: this.getterAEV.quarter['April - June'].insuranceAverage,
                nonInsuranceAverage: this.getterAEV.quarter['April - June'].nonInsuranceAverage,
              },
            ]
          } else if (q2.includes(currentMonth)) {
            result = [
              {
                quarter: 'April - June',
                average: this.getterAEV.quarter['April - June'].average,
                insuranceAverage: this.getterAEV.quarter['April - June'].insuranceAverage,
                nonInsuranceAverage: this.getterAEV.quarter['April - June'].nonInsuranceAverage,
              },
              {
                quarter: 'January - March',
                average: this.getterAEV.quarter['January - March'].average,
                insuranceAverage: this.getterAEV.quarter['January - March'].insuranceAverage,
                nonInsuranceAverage: this.getterAEV.quarter['January - March'].nonInsuranceAverage,
              },
              {
                quarter: 'October - December',
                average: this.getterAEV.quarter['October - December'].average,
                insuranceAverage: this.getterAEV.quarter['October - December'].insuranceAverage,
                nonInsuranceAverage: this.getterAEV.quarter['October - December'].nonInsuranceAverage,
              },
              {
                quarter: 'July - September',
                average: this.getterAEV.quarter['July - September'].average,
                insuranceAverage: this.getterAEV.quarter['July - September'].insuranceAverage,
                nonInsuranceAverage: this.getterAEV.quarter['July - September'].nonInsuranceAverage,
              },
            ]
          } else if (q3.includes(currentMonth)) {
            result = [
              {
                quarter: 'July - September',
                average: this.getterAEV.quarter['July - September'].average,
                insuranceAverage: this.getterAEV.quarter['July - September'].insuranceAverage,
                nonInsuranceAverage: this.getterAEV.quarter['July - September'].nonInsuranceAverage,
              },
              {
                quarter: 'April - June',
                average: this.getterAEV.quarter['April - June'].average,
                insuranceAverage: this.getterAEV.quarter['April - June'].insuranceAverage,
                nonInsuranceAverage: this.getterAEV.quarter['April - June'].nonInsuranceAverage,
              },
              {
                quarter: 'January - March',
                average: this.getterAEV.quarter['January - March'].average,
                insuranceAverage: this.getterAEV.quarter['January - March'].insuranceAverage,
                nonInsuranceAverage: this.getterAEV.quarter['January - March'].nonInsuranceAverage,
              },
              {
                quarter: 'October - December',
                average: this.getterAEV.quarter['October - December'].average,
                insuranceAverage: this.getterAEV.quarter['October - December'].insuranceAverage,
                nonInsuranceAverage: this.getterAEV.quarter['October - December'].nonInsuranceAverage,
              },
            ]
          } else if (q4.includes(currentMonth)) {
            result = [
              {
                quarter: 'October - December',
                average: this.getterAEV.quarter['October - December'].average,
                insuranceAverage: this.getterAEV.quarter['October - December'].insuranceAverage,
                nonInsuranceAverage: this.getterAEV.quarter['October - December'].nonInsuranceAverage,
              },
              {
                quarter: 'July - September',
                average: this.getterAEV.quarter['July - September'].average,
                insuranceAverage: this.getterAEV.quarter['July - September'].insuranceAverage,
                nonInsuranceAverage: this.getterAEV.quarter['July - September'].nonInsuranceAverage,
              },
              {
                quarter: 'April - June',
                average: this.getterAEV.quarter['April - June'].average,
                insuranceAverage: this.getterAEV.quarter['April - June'].insuranceAverage,
                nonInsuranceAverage: this.getterAEV.quarter['April - June'].nonInsuranceAverage,
              },
              {
                quarter: 'January - March',
                average: this.getterAEV.quarter['January - March'].average,
                insuranceAverage: this.getterAEV.quarter['January - March'].insuranceAverage,
                nonInsuranceAverage: this.getterAEV.quarter['January - March'].nonInsuranceAverage,
              },
            ]
          }

          return result

        } else if (this.adjustedBy === 'Year') {
          let data = []
          for (let year in this.getterAEV.year) {
            data.push(
              {
                year: year,
                average: this.getterAEV.year[year].average,
                insuranceAverage: this.getterAEV.year[year].insuranceAverage,
                nonInsuranceAverage: this.getterAEV.year[year].nonInsuranceAverage,
              })
          }
          return data
        }
      },
    },
  }
</script>

<style scoped>
.active-filters-control {
  display: flex;
  flex-direction: column;
  width: 880px;
  position: relative;
  left: 96px;
  border: 1px solid rgba(27, 30, 56, 0.25);
}

.filters-checkboxes {
  padding: 10px 0 15px 15px;
  display: flex;
}

.filters-checkboxes-title {
  display: inline-block !important;
  width: 200px;
}

.filters-checkbox {
  display: flex;
  margin-right: 30px;
  display: inline-block !important;
  width: 100px;
}
</style>

