import { render, staticRenderFns } from "./Estimage.vue?vue&type=template&id=ff62bcde&scoped=true"
import script from "./Estimage.vue?vue&type=script&lang=js"
export * from "./Estimage.vue?vue&type=script&lang=js"
import style0 from "./Estimage.vue?vue&type=style&index=0&id=ff62bcde&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff62bcde",
  null
  
)

export default component.exports