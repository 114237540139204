<script>
import Axios from 'axios';
// import DataTables from './utility/data-tables'
import GoogleLocation from './utility/google-location';
import {VueContext} from 'vue-context';
import NumberFormatter from './utility/number-formatter';
import BlockPagination from './utility/block-pagination';
import {mapGetters} from "vuex";
import _ from "lodash";

export default {
    name: 'insurers',
    data: function () {
        return {
            insurers: [],
            newInsurers: [],
            contextOpenEvent: null,
            filter: '',

            insurersPerPage: 50,
            insurersCurrentPage: 1,
            insurersFiltered: 0,

            new_insurer_name: '',
            new_insurer_location: '',
            new_insurer_email: '',

            sortBy: 'insurer_name',
            sortDesc: false,
            fields: [
                {
                    label: "Insurer Name",
                    key: "insurer_name",
                    tdClass: 'clickable text-left font-weight-bold td-text',
                    thClass: 'bg-navyblue text-left th-text',
                    sortable: true
                },
                {
                    label: "Insurer Location",
                    key: "insurer_location",
                    tdClass: 'text-left clickable font-weight-600 td-text',
                    thClass: 'text-left th-text bg-navyblue',
                    sortable: true
                },
                {
                    label: "Claims Email",
                    key: "insurer_email",
                    tdClass: 'text-left clickable font-weight-600 td-text',
                    thClass: 'text-left th-text bg-navyblue',
                    sortable: true
                },
                {
                    label: "Assessors",
                    key: "assessors",
                    tdClass: 'text-left clickable font-weight-600 td-text',
                    thClass: 'text-left th-text'
                },
                {
                    label: " ",
                    key: "insurer_id",
                    tdClass: 'text-left clickable font-weight-600 td-text',
                    thClass: 'text-left th-text bg-navyblue'
                }
            ],
        };
    },
    computed: {
      ...mapGetters({
          'isCustomerUser': 'isCustomerUser',
          isStatusActive: 'isStatusActive',
          getterBusinessName : 'getBusinessName',
        }
      ),
    },
    methods: {
      filterForTable(row, filter) {
        let formattedFilter = _.trim(_.toLower(filter))
        if (row.assessors && row.assessors.length > 0 && row.assessors.join(' ').toLowerCase().includes(formattedFilter)) {
          return true;
        } else if (row.estimate_methodology && row.estimate_methodology.toLowerCase().includes(formattedFilter)) {
          return true;
        } else if (row.insurer_abn && row.insurer_abn.includes(formattedFilter)) {
          return true;
        } else if (row.insurer_email && row.insurer_email.toLowerCase().includes(formattedFilter)) {
          return true;
        } else if (row.insurer_location && row.insurer_location.toLowerCase().includes(formattedFilter)) {
          return true;
        } else if (row.insurer_name && row.insurer_name.toLowerCase().includes(formattedFilter)) {
          return true;
        }
        return false;
      },
      openContext(event){
        this.contextOpenEvent = event;
      },
      closeContext(){
        let event = this.contextOpenEvent;
        if (event) {
          let row = event.target.parentElement;
          let rows = row.parentElement.rows;
          for (let i = 0; i < rows.length; i++) {
            let elem = rows[i];
            elem.style.backgroundColor = "";
          }
        }
      },
        deleteInsurer: function (item) {
          if (!this.isStatusActive) {
            return;
          }
          if (confirm('Are you sure?')) {
              let id = item.insurer_id;
              Axios({
                method: 'post',
                responseType: 'json',
                headers: {'Delete': true},
                url: `/fe/insurer/${id}`,
                data: {delete: id},
                validateStatus: function (status) {
                  return status < 500;
                },
              })
                .then(response => {
                  if (response.status === 200) {
                    toastr.error("Insurer deleted");
                    this.loadInsurers();
                  }
                });
          }
        },
        newInsurer: function () {
            var self = this;
            NProgress.start();
            Axios.post(`/fe/insurer/new`)
                .then(response => {
                    if (response.data.status && response.data.id > 0) {
                        this.$router.push('/insurers/view/' + response.data.id);
                    } else {
                        toastr.error("Insurer does not create");
                    }
                    NProgress.done();
                })
                .catch(error => {
                    toastr.error("Insurer does not create");
                    console.log(error);
                    NProgress.done();
                });
        },
        onRightClickByInsurersAll(type, data) {
            if (type && data && type == 2) {
                this.$router.push('/insurers/view/' + data.item.insurer_id);
            }
            if (type && data && type == 3) {
                this.deleteInsurer(data.item);
            }
        },
        openMenuForInsurersAll: function (item, index, event) {
          if (!this.isStatusActive) {
            return;
          }
            event.preventDefault();
            let row = event.target.parentElement;
            let rows = row.parentElement.rows;
            for (let i = 0; i < rows.length; i++) {
              let elem = rows[i];
              elem.style.backgroundColor = "";
            }
          row.style.backgroundColor = "#F3F3F3";

          this.$refs.rightClickMenuForInsurersAll.open(event, {item: item, index: index});
        },
        loadInsurers: function () {
            NProgress.start();
            Axios.get('/fe/insurer')
                .then(response => {
                    this.insurers = response.data.data.map( item => {
                        item.insurer_name = item.insurer_name.trim();
                        return item;
                    });
                    NProgress.done();
                })
                .catch(error => {
                    console.log(error);
                    NProgress.done();
                });
        },
        saveInsurers: function () {
            if (this.new_insurer_name != '' && this.new_insurer_location != '') {
                this.addNewInsurer();
            }

            if (this.newInsurers.length == 0) {
                return false;
            }

            NProgress.start();
            Axios.post(
                '/fe/insurer',
                JSON.stringify({insurers: this.newInsurers})
            )
                .then(response => {
                    this.loadInsurers();
                    toastr.success('Insurer/s created');
                    NProgress.done();
                })
                .catch(error => {
                    console.log(error);
                    NProgress.done();
                    return false;
                });

            return true;
        },
        insurerModalFormCompleted: function () {
            let saved = this.saveInsurers();

            if (saved) {
                //   this.loadInsurers()
                this.$refs.insurerModal.hide();
            }
        },
        editInsurer: function (insurer_id) {
            this.$router.push(`/insurers/view/${insurer_id}`);
        },
        addNewInsurer: function () {
            if (this.new_insurer_name == '' || this.new_insurer_location == '') {
                return;
            }
            this.newInsurers.push({
                insurer_name: this.new_insurer_name,
                insurer_location: this.new_insurer_location,
                insurer_email: this.new_insurer_email
            });

            this.new_insurer_name = '';
            this.new_insurer_location = '';
            this.new_insurer_email = '';
        },
        onInsurersFiltered: function (fi) {
            this.insurersFiltered = fi.length;
            this.insurersCurrentPage = 1;
        },
        onInsurersRowClick: function (item, index, event) {
            this.$router.push('/insurers/view/' + item.insurer_id);
        },
    },
    mounted: function () {
        this.loadInsurers();
    },
    components: {
        GoogleLocation,
        VueContext,
        NumberFormatter,
        BlockPagination,
    }
};
</script>

<template>
    <div class="insurers-page table-view">
        <div class="page-header">
            <h4>View Insurers</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item home">
                    <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item active">View Insurers</li>
            </ol>
            <h4 class="header-display-name">{{ getterBusinessName }}</h4>
            <div v-if="isStatusActive" class="page-header-nav-btn">
                <button @click="newInsurer" class="btn btn-primary"><i class="ti-plus"></i>New Insurer</button>
            </div>
        </div>
        <div class="box-block bg-white cards">
            <tabs :options="{useUrlFragment: false, defaultTabHash: 'insurers-all' }">
                <tab name="All" id="insurers-all" ref="activeTab" :class-name="'tab-content-table'">
                    <div class="row">
                        <div>
                            <div class="bg-white">
                                <div class="pagination-block-bottom">
                                    <b-table
                                        class="rs-scroll rs-scroll--y noselect"
                                        responsive
                                        hover
                                        :items="insurers"
                                        :sort-by.sync="sortBy"
                                        :sort-desc.sync="sortDesc"
                                        :fields="fields"
                                        :filter="filter"
                                        :filter-function="filterForTable"
                                        :current-page="insurersCurrentPage"
                                        :per-page="insurersPerPage"
                                        @row-contextmenu="openMenuForInsurersAll"
                                        @row-clicked="onInsurersRowClick"
                                        @filtered="onInsurersFiltered"
                                    >
                                        <template v-slot:cell(insurer_id)="data">
                                            <button v-if="!isCustomerUser" @click="deleteInsurer(data.item)" class="btn_trush clickable"></button>
                                        </template>
                                        <template v-slot:cell(assessors)="data">
                                        <div>
                                            <div v-for="(assessor,index) in data.value">{{(index+1)}}.{{assessor}}</div>
                                        </div>
                                        </template>
                                    </b-table>
                                </div>
                                <block-pagination
                                    :role="'insurers'"
                                    :count="insurers.length"
                                    :countFiltered="filter ? insurersFiltered : insurers.length"
                                    :currentPage="insurersCurrentPage"
                                    @changedCurrentPage="value => insurersCurrentPage = value"
                                    :perPage="insurersPerPage"
                                    @changedPerPage="value => insurersPerPage = value"
                                    >
                                </block-pagination>
                            </div>
                            <vue-context @open = "openContext" @close = "closeContext"  ref="rightClickMenuForInsurersAll">
                                <ul slot-scope="item">
                                    <li @click="onRightClickByInsurersAll(2, item.data)">Edit Details</li>
                                    <li v-if="!isCustomerUser" @click="onRightClickByInsurersAll(3, item.data)">Delete Insurer</li>
                                </ul>
                            </vue-context>
                        </div>
                    </div>
                </tab>
                <template slot="nav-item-right">
                    <div class="tabs-nav-right-search right">
                        <b-form-input v-model="filter" type="text" class="form-control search-input search-position"
                                    placeholder="Search or Filter results"
                                    debounce="100"></b-form-input>
                    </div>
                    <div v-if="isStatusActive" class="tabs-nav-right-btn">
                        <button @click="newInsurer" class="btn btn-primary w-min-sm waves-effect waves-light add-button-table"><i
                            class="ti-plus"></i>New Insurer
                        </button>
                    </div>
                </template>
            </tabs>
        </div>

        <modal-wizard ref="insurerModal" finishButtonText="Save" @on-complete="insurerModalFormCompleted">
            <modal-wizard-tab title="New Insurers" description="Add new insurer/s details" title-icon-class="ti-user">
                <template slot-scope="insurers">
                    <table class="table b-table table-hover">
                        <thead>
                        <tr>
                            <th>Insurer Name</th>
                            <th>Insurer Location</th>
                            <th>Claims Email</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(insurer, index) in newInsurers">
                            <td><input v-model="insurer.insurer_name" type="text" class="form-control"/></td>
                            <td>
                                <!--<input v-model="insurer.insurer_location" type="text" name="" class="form-control" />-->
                                <google-location v-model="insurer.insurer_location" class="form-control"
                                                 @onPlaceChange="addressChanged" placeholder=""></google-location>
                            </td>
                            <td><input v-model="insurer.insurer_email" type="text" class="form-control"/></td>
                            <td class="text-center">
                                <button @click="newInsurers.splice(index, 1)" class="btn btn-danger btn-sm">Remove
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td><input v-model="new_insurer_name" type="text" name="" class="form-control"/></td>
                            <td>
                                <!--<input v-model="new_insurer_location" type="text" name="" class="form-control" />-->
                                <google-location v-model="new_insurer_location" class="form-control"></google-location>
                            </td>
                            <td><input v-model="new_insurer_email" type="text" name="" class="form-control"/></td>
                            <td class="text-center">
                                <button @click="addNewInsurer" class="btn btn-success btn-sm">Add New</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </template>
            </modal-wizard-tab>
        </modal-wizard>

    </div>
</template>

<style>
    .insurers-page .ti-plus {
        margin-right: 10px;
    }

    .table-view .search-input {
        box-sizing: border-box;
        height: 38px;
        width: 100%;
        border: 1px solid #1C1F39;
        border-radius: 3px;
    }

.insurers-page .btn-primary.ml-1.pull-right {
  height: 37px;
  display: flex;
  align-items: center;
  padding: auto 10px;
  border-radius: 3px;
  border: none;
}
.insurers-page .tabs-nav-right-btn {
    margin-left: 15px !important;
}

@media (min-width: 626px) {
  .tabs-nav-right-btn {
    margin-left: 15px !important;
  }
}
    @media (max-width: 576px) {
        .search-position {
            margin: 10px 0 !important;
        }
    }
</style>

<style scoped>
    @media screen and (max-width: 768px) {
        .header-display-name {
            display: none;
        }
    }
</style>
