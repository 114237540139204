<script>
    import {mapGetters} from "vuex";
    import SearchFilter2 from '../../components/search-filter/search-filter-2'
    import _ from "lodash";
    import InvoiceTable from './invoice-table'

    export default {
        name: 'invoices',
        data: function () {
            return {
              searchFilter: '',
            };
        },
        computed: {
            ...mapGetters({
              invoicesAll: 'invoice/getAllInvoices',
              getterToken: 'invoice/getToken',
              getterTokens: 'invoice/getTokens',
              filter: 'invoiceFilter',
              isCustomerUser: 'isCustomerUser',
              isStatusActive: 'isStatusActive',
              getterBusinessName : 'getBusinessName',
              isPDRTechnicianUser: 'isPDRTechnicianUser',
              isTechnicalUser: 'isTechnicalUser',
              isPanelTechnicianUser: 'isPanelTechnicianUser',
              isPaintTechnicianUser: 'isPaintTechnicianUser',
              isStripperFitterUser: 'isStripperFitterUser',
              isDisplayBoardUser: 'isDisplayBoardUser',
            }),
          isCanSearchBySomeFields() {
            return !this.isPDRTechnicianUser && !this.isPanelTechnicianUser && !this.isPaintTechnicianUser && !this.isStripperFitterUser && !this.isDisplayBoardUser
          },
          computedSearchFilters() {
            let invoiceNumbers = {};
            let estimateNumbers = {};
            let insurers = {};
            let regos = {};
            let makes = {};
            let models = {};
            let vehicleOwner = {};


            this.invoicesAll.forEach(invoice => {
              if (invoice.number) {
                invoiceNumbers[invoice.number.trim().toLowerCase()] = {label: invoice.number.trim()}
              }
            })

            this.invoicesAll.forEach(invoice => {
              if (invoice.estimateNumber) {
                estimateNumbers[invoice.estimateNumber.trim().toLowerCase()] = {label: invoice.estimateNumber.trim()}
              }
            })

            this.invoicesAll.forEach(invoice => {
              if (invoice.vehicle_owner) {
                vehicleOwner[invoice.vehicle_owner.trim().toLowerCase()] = {label: invoice.vehicle_owner.trim()}
              }
            })

            this.invoicesAll.forEach(invoice => {
              if (invoice.rego_number) {
                regos[invoice.rego_number.trim().toLowerCase()] = {label: invoice.rego_number.trim()}
              }
            })

            this.invoicesAll.forEach(invoice => {
              if (invoice.make) {
                makes[invoice.make.trim().toLowerCase()] = {label: invoice.make.trim()}
              }
            })

            this.invoicesAll.forEach(invoice => {
              if (invoice.model) {
                models[invoice.model.trim().toLowerCase()] = {label: invoice.model.trim()}
              }
            })

            this.invoicesAll.forEach(invoice => {
              if (invoice.insurer) {
                insurers[invoice.insurer.trim().toLowerCase()] = {label: invoice.insurer.trim()}
              }
            })

            invoiceNumbers = _.orderBy(Object.values(invoiceNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
            estimateNumbers = _.orderBy(Object.values(estimateNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
            vehicleOwner = _.orderBy(Object.values(vehicleOwner), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
            regos = _.orderBy(Object.values(regos), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
            makes = _.orderBy(Object.values(makes), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
            models = _.orderBy(Object.values(models), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
            insurers = _.orderBy(Object.values(insurers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])

            let result = [
              {
                id: 'invoiceNumber',
                label: 'Invoice Number',
                iconClass: 'bx-user',
                options: [
                  invoiceNumbers,
                ]
              },
              {
                id: 'estimateNumber',
                label: 'Estimate Number',
                iconClass: 'bx-user',
                options: [
                  estimateNumbers,
                ]
              },
              {
                id: 'insurer',
                label: 'Insurer',
                iconClass: 'bx-user',
                options: [
                  insurers,
                ]
              },
              {
                id: 'rego',
                label: 'Rego',
                iconClass: 'bx-user',
                options: [
                  regos,
                ]
              },
              {
                id: 'make',
                label: 'Make',
                iconClass: 'bx-user',
                options: [
                  makes,
                ]
              },
              {
                id: 'model',
                label: 'Model',
                iconClass: 'bx-store',
                options: [
                  models
                ]
              },
              {
                id: 'vehicleOwner',
                label: 'Vehicle Owner',
                iconClass: 'bx-store',
                options: [
                  vehicleOwner
                ]
              },
            ]

            if (!this.isCanSearchBySomeFields) {
              result = _.filter(result, (r) => {
                return r.id !== 'estimateNumber' && r.id !== 'insurer'
              })
            }
            return result
          },
        },
        methods: {
          onSearchFilterChange(filters) {
            this.$store.commit('invoice/setSearchFilterObj', filters)
            if (filters && filters[0] && filters[0].search) {
              this.$store.commit('setInvoiceFilter', filters[0].search);
            } else {
              this.$store.commit('setInvoiceFilter', '');
            }
          },
        },
        mounted: function () {
          if (!this.isCanSearchBySomeFields && this.getterTokens.length == 7) {
            this.$store.commit('invoice/setTokensNew',
                {
                  0: [],
                  1: [],
                  2: [],
                  3: [],
                  4: [],
                }
            )
          }
        },
        components: {
          SearchFilter2,
          InvoiceTable
        }
    };
</script>

<template>
    <div class="invoices-page">
        <div class="page-header">
            <h4>View Invoices</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item home">
                    <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item active">View Invoices</li>
            </ol>
            <h4 class="header-display-name">{{ getterBusinessName }}</h4>
            <div class="page-header-nav-btn float-right" v-if="isStatusActive">
                <router-link to="/invoice/create" class="btn btn-primary">
                    <i class="ti-plus"></i> New Invoice
                </router-link>
            </div>
        </div>
        <div class="box-block bg-white invoices">
            <tabs :options="{useUrlFragment: false, defaultTabHash: 'invoices-all' }">
                <tab name="All" id="invoices-all" :class-name="'tab-content-table'">
                  <invoice-table
                      :type="'All'"
                  ></invoice-table>
                </tab>
                <tab name="Awaiting Payment" id="invoices-awaiting-payment" :class-name="'tab-content-table'">
                  <invoice-table
                      :type="'Awaiting'"
                  ></invoice-table>
                </tab>
                <tab name="Paid" id="invoices-paid" :class-name="'tab-content-table'">
                  <invoice-table
                      :type="'Paid'"
                  ></invoice-table>
                </tab>
                <template slot="nav-item-right">
                    <div class="tabs-nav-right-search tabs-nav-right-search-font-weight">
                      <search-filter2
                          type="estimates"
                          v-model="searchFilter"
                          class="search-filter"
                          :filters="computedSearchFilters"
                          :filterValue="filter"
                          :limit-length="50"
                          :isShowSearIcon="true"
                          @change="onSearchFilterChange"
                          @updateToken="value => $store.commit('invoice/setToken', value)"
                          @updateTokens="value => $store.commit('invoice/setTokens', value)"
                          :tokenCopy="getterToken"
                          :tokensCopy="getterTokens"
                          height="41px"
                          aria-expanded="false"
                          data-toggle="dropdown"
                          :isMyltipleOptions="true"
                          :isMyltiple="true"
                          :optionsLength="isCanSearchBySomeFields ? 7 : 5"
                      ></search-filter2>
                    </div>
                    <div class="tabs-nav-right-btn" v-if="isStatusActive" style="position: relative; bottom: 8px">
                        <router-link to="/invoice/create" class="btn btn-primary">
                            <i class="ti-plus"></i> New Invoice
                        </router-link>
                    </div>
                </template>
            </tabs>
        </div>


    </div>
</template>

<style>
    @media (min-width: 626px) {
        .V3 .invoices-page .tabs-component .tab-header.float-sm-right .tabs-nav-right-btn {
            margin-left: 15px;
        }
    }

    @media (max-width: 625px) {
        .V3 .invoices-page .tabs-component .tab-header.float-sm-left {
            float: none !important;
        }

        .V3 .invoices-page .tabs-component .tab-header.float-sm-left,
        .V3 .invoices-page .tabs-component .tab-header.float-sm-right > div {
            margin-bottom: 10px !important;
            display: block;
            text-align: left;
            margin-right: 30px;
        }

        .V3 .invoices-page .tabs-component .tab-header.float-sm-right {
            margin-top: 10px;
            float: none !important;
        }

        .V3 .invoices-page .tabs-component .tab-header.view-header-buttons {
            padding-left: 15px;
        }
    }
</style>

<style scoped>
    .tabs-nav-right-search .search-filter >>> .form-control:focus{
      outline: 0 !important;
    }
    .tabs-nav-right-search .search-filter >>> .search-filter__input{
      font-size: 13px !important;
      font-weight: 800;
      color: black;
    }
    .tabs-nav-right-search .search-filter >>> .search-filter__box{
      border: 3px solid #5e77fd;
      border-radius: 7px;
    }
  .search-filter >>> input::placeholder{
    color: #a6a6a6;
    font-weight: 700;
  }
  .tabs-nav-right-search .search-filter >>> .form-control:focus{
    outline: 0 !important;
  }
  .tabs-nav-right-search .search-filter >>> .search-filter__input{
    font-size: 13px !important;
    font-weight: 800;
    color: black;
  }
  .tabs-nav-right-search .search-filter >>> .search-filter__box{
    border: 3px solid #5e77fd;
    border-radius: 7px;
  }
    .search-filter >>> input::placeholder{
      color: #bbbbc2;
    }
    .search-filter >>> input:focus{
      background-color: #ebf0fc;
    }
    .tabs-nav-right-search .search-filter >>> .form-control:focus{
      outline: 0 !important;
    }
    .search-filter >>> .search-filter__box__focused{
      background-color: #ebf0fc;
      border: 3px solid rgba(94, 121, 255, 0.5) !important;
      box-shadow: 0 0 0 0.2rem rgb(94 121 255 / 25%) !important;
    }
    .search-filter >>> .search-filter__li--input{
      border-bottom: 0;
    }
    .search-filter >>> .search-filter__li{
      border-bottom: 0 !important;
    }
    .search-filter >>> .search-filter__box{
      border: 2px solid rgba(28, 31, 57, 0.25);
    }

    .tabs-nav-right-search-font-weight >>> input{
      font-weight: 700;
    }
    .tabs-nav-right-search-font-weight >>> input::placeholder{
      font-weight: 600;
    }
    .page-header{
      touch-action: none;
    }
    .invoices >>> .tabs-component{
      touch-action: none;
    }
    .tabs-nav-right-search {
        display: block;
        margin: 10px 0 0 0;
    }

    .row.no-gutters {
        margin-right: 0;
        margin-left: 0;
    }

    .row.no-gutters > [class^="col-"],
    .row.no-gutters > [class*=" col-"] {
        padding-right: 0;
        padding-left: 0;
    }

    tbody tr {
        cursor: pointer;
    }

    .action-icon i {
        font-size: 14px;
        color: #BFBBBA;
    }

    .action-icon.active i {
        color: #142742
    }

    .action-icon.active[tooltip] {
        position: relative;
        display: inline-block;
        transition: .3s;
    }

    .action-icon.active[tooltip]::before {
        content: "";
        position: absolute;
        bottom: 25px;
        left: 50%;
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-top-color: #333333;
        border-bottom: 0;
        margin-left: -5px;
        margin-bottom: -5px;
        opacity: 0;
        transition: .3s;
    }

    .action-icon.active[tooltip]:hover::before {
        opacity: 1;
    }

    .action-icon.active[tooltip-position='left']::before {
        left: 0%;
        top: 50%;
        margin-left: -12px;
        transform: translatey(-50%) rotate(-90deg)
    }

    .action-icon.active[tooltip-position='top']::before {
        left: 50%;
    }

    .action-icon.active[tooltip-position='buttom']::before {
        top: 100%;
        margin-top: 8px;
        transform: translateX(-50%) translatey(-100%) rotate(-180deg)
    }

    .action-icon.active[tooltip-position='right']::before {
        left: 100%;
        top: 50%;
        margin-left: 1px;
        transform: translatey(-50%) rotate(90deg)
    }

    .action-icon.active[tooltip]::after {
        content: attr(tooltip);
        position: absolute;
        left: 50%;
        top: -6px;
        transform: translateX(-50%) translateY(-100%);
        background: #333333;
        text-align: center;
        color: #FFFFFF;
        padding: 4px 2px;
        font-size: 10px;
        font-weight: 600;
        min-width: 155px;
        border-radius: 0px;
        pointer-events: none;
        z-index: 99;
        opacity: 0;
        transition: .3s;
    }

    .action-icon.active[tooltip-position='left']::after {
        left: 0%;
        top: 50%;
        margin-left: -8px;
        transform: translateX(-100%) translateY(-50%);
    }

    .action-icon.active[tooltip-position='top']::after {
        left: 50%;
    }

    .action-icon.active[tooltip-position='buttom']::after {
        top: 100%;
        margin-top: 8px;
        transform: translateX(-50%) translateY(0%);
    }

    .action-icon.active[tooltip-position='right']::after {
        left: 100%;
        top: 50%;
        margin-left: 8px;
        transform: translateX(0%) translateY(-50%);
    }

    .action-icon.active[tooltip]:hover::after, [tooltip]:hover::before {
        opacity: 1
    }

    @media screen and (max-width: 768px) {
      .header-display-name {
          display: none;
      }
      .tabs-nav-right-search{
        margin: 10px 0 !important;
      }
      .estimates >>>.tabs-component .tab-header.nav-tabs.nav-tabs-2{
        border-bottom: 5px solid #e3e4e7 !important;
        padding: 0;
        margin: 0 15px;
      }
      .search-filter{
        padding-left: 15px !important;
        padding-right: 15px;
      }
      .box-block >>> .tab-header.nav.nav-tabs {
        padding: 0 !important;
        margin-left: 15px;
        margin-right: 15px;
        border-bottom: 5px solid #e1e2e5 !important;
      }
    }
    @media screen and (max-width: 460px) {
      .invoices >>> .tabs-component {
        top: 116px;
      }
    }
</style>

<style>
    /* 90 */
    th.number {
        width: 5%;
        min-width: 90px;
    }

    /* 130 */
    th.customer {
        width: 10.5%;
        min-width: 130px;
    }

    /* 120 */
    th.insurer,
    th.rego,
    th.make,
    th.model,
    th.currency,
    th.status,
    th.date,
    th.booking-date {
        width: 10%;
        min-width: 120px;
    }

    .b-table td {
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        letter-spacing: normal;
    }
</style>
