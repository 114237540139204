<template>
  <div id="snapshot-view" class="booking-snapshot">
        <div class="cards-all-snapshot-body">
          <div class="cards-all-snapshot-header d-flex">
            <div class="cards-all-snapshot-header-title">
              <h1>Bookings Snapshot</h1>
            </div>
            <div class="cards-all-snapshot-header-period d-flex">
              <div class="cards-all-snapshot-header-period-num">
                Period of {{ currentFilterDates }}
              </div>
              <div class="cards-all-snapshot-header-period-selection">
                <div class="planer-period-menu">
                  <div class="dropdown dropdown-options show inline">
                    <button type="button"
                            class="btn waves-effect waves-light ex-options-button"
                            id="dropdownMenuLink1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true">
                      {{filter}}
                      <span class="dropdown-arrow pull-right" style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                    <i class="fa fa-chevron-down pull-right"></i>
                    </span>
                    </button>
                    <div style="z-index: 9999" class="dropdown-menu dropdown-menu-right animated fadeInUp" aria-labelledby="dropdownMenuLink">
                      <a v-if="filter != 'Month'" class="dropdown-item text-primary p-1 clickable" @click="$store.commit('board/setFilter', 'Month')">Month (Current)</a>
                      <a v-if="filter != 'Week'" class="dropdown-item text-primary p-1 clickable" @click="$store.commit('board/setFilter', 'Week')">Week</a>
                      <a v-if="filter != 'Day'" class="dropdown-item text-primary p-1 clickable" @click="$store.commit('board/setFilter', 'Day')">Day</a>
                      <a v-if="filter != 'Last Month'" class="dropdown-item text-primary p-1 clickable" @click="$store.commit('board/setFilter', 'Last Week')">Last Week</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cards-all-snapshot-main">
            <booking-counts></booking-counts>

            <div class="dividing_line"></div>

            <the-reasons></the-reasons>

            <div class="dividing_line"></div>

            <topInsurers></topInsurers>

            <div class="dividing_line"></div>

            <bookingAttempts></bookingAttempts>

          </div>
        </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import dayjs from "dayjs";
import bookingCounts from './booking-counts'
import bookingAttempts from './call-back-attempts'
import topInsurers from './top-insurers'
import theReasons from './the-reasons'
import _ from "lodash";

export default {
  name: "booking-snapshot",
  components: {
    bookingCounts,
    bookingAttempts,
    topInsurers,
    theReasons
  },
  data: function () {
    return {
      currentDateFilter: 'Month',
    }
  },
  computed: {
    ...mapGetters({
      filter: 'board/getBookingFilter',
      getBookingItems: 'board/getBookingItems',
    }),
    currentFilterDates() {
      if (this.filter == 'Month') {
        return dayjs().startOf('month').format('DD/MM/YYYY') + ' - ' + dayjs().endOf('month').format('DD/MM/YYYY')
      } else if (this.filter == 'Last Week') {
        let now = new Date();
        let sunday = dayjs(new Date(now.setDate(now.getDate() - now.getDay()))).format('DD/MM/YYYY');
        let monday = dayjs(new Date(now.setDate(now.getDate() - 6))).format('DD/MM/YYYY');
        return monday + ' - ' + sunday
      } else if (this.filter == 'Day') {
        return dayjs().format('DD/MM/YYYY')
      } else if (this.filter == 'Week') {
        let monday = dayjs().isoWeekday(1).format('DD/MM/YYYY')
        let sunday = dayjs().isoWeekday(7).format('DD/MM/YYYY')
        return monday + ' - ' + sunday
      }
    },
    currentMonth() {
      return new Date().getMonth();
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>


<style scoped>
.dividing_line{
  width: 100%;
  border: 2px solid #dcdcdc;
  border-radius: 3px;
  margin: 15px 0;
}
.booking-snapshot{
  padding-left: 15px;
}

.cards-all-snapshot-body{
  padding-top: 20px;
}
.cards-all-snapshot-header{
  justify-content: space-between;
}
.cards-all-snapshot-header-title h1{
  font-size: 25px;
  font-weight: bold;
}
.cards-all-snapshot-header-period-num{
  font-weight: 600;
  margin-top: 10px;
  margin-right: 30px;
}



@media screen and (max-width: 460px) {
  .booking-snapshot >>> .tabs-component {
    top: 116px;
    padding-top: 10px;
  }
}
</style>
