<template>
  <div class="">
    <div style="overflow-x: auto;" class="pagination-block-bottom">
      <b-table
          class="rs-scroll rs-scroll--y noselect"
          responsive
          hover
          :items="type == 'All' ? computedInvoicesAll : (type == 'Awaiting' ? computedInvoicesAwaitingPayment : computedInvoicesPaid)"
          :fields="type == 'All' ? fieldsAll : (type == 'Awaiting' ? fieldsAwaitingPayment : fieldsPaid)"
          :filter="filter"
          :filter-function="filterForTable"
          :sort-by="type == 'All' ? getterSortByForInvoicesAll : (type == 'Awaiting' ? getterSortByForInvoicesAwaitingPayment : getterSortByForInvoicesPaid)"
          :sort-desc="type == 'All' ? getterSortDescForInvoicesAll : (type == 'Awaiting' ? getterSortDescForInvoicesAwaitingPayment : getterSortDescForInvoicesAwaitingPayment)"
          @sort-changed="setSort"
          :current-page="type == 'All' ? getterInvoicesAllCurrentPage : (type == 'Awaiting' ? getterInvoicesAwaitingPaymentCurrentPage : getterInvoicesPaidCurrentPage)"
          :per-page="getterPerPage"
          @filtered="onTableFiltered"
          @row-clicked="onInvoicesRowClick"
          @row-contextmenu="onRowContextMenu"
      >
        <template v-slot:cell(insurer)="data">
          <div v-if="data.item.insurer" :style="getInsurerNameStyle(data.item.insurerCardColorId)">
            {{ data.item.insurer }}
          </div>
        </template>
      </b-table>
    </div>
    <block-pagination
        :role="'invoices'"
        :count="type == 'All' ? invoicesAll.length : (type == 'Awaiting' ? invoicesAwaitingPayment.length : invoicesPaid.length)"
        :countFiltered="type == 'All' ? (filter ? invoicesAllFiltered : computedInvoicesAll.length) : (type == 'Awaiting' ? (filter ? invoicesAwaitingPaymentFiltered : computedInvoicesAwaitingPayment.length) : (filter ? invoicesPaidFiltered : computedInvoicesPaid.length))"
        :currentPage="type == 'All' ? getterInvoicesAllCurrentPage : (type == 'Awaiting' ? getterInvoicesAwaitingPaymentCurrentPage : getterInvoicesPaidCurrentPage)"
        @changedCurrentPage="changedCurrentPage"
        :perPage="getterPerPage"
        :perPageForMultipleTables="getterPerPage"
        @changedPerPage="value => $store.commit('invoice/setInvoicesPerPage', value)"
    >
    </block-pagination>

    <vue-context ref="contextMenuForInvoices" @open="contextOpen" @close="contextClose">
      <ul slot-scope="scope">
        <li v-if="vendorInfo.activeXeroAuth && scope && scope.data && !scope.data.item.xeroInvoiceId && !isCustomerUser && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.xero" @click="sendToXero(scope.data.item.id)">Send to XERO</li>
        <li v-if="vendorInfo.activeXeroAuth && scope && scope.data &&  scope.data.item.xeroInvoiceId && !isCustomerUser && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.xero" @click="sendToXero(scope.data.item.id)">Update on XERO</li>
        <li v-if="vendorInfo.activeXeroAuth && scope && scope.data &&  scope.data.item.xeroInvoiceId && !isCustomerUser && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.xero" @click="viewOnXero(scope.data.item.id)">View on XERO</li>
        <li @click="markAsUnpaid(scope.data.item.id)">Mark As Unpaid</li>
      </ul>
    </vue-context>
  </div>
</template>

<script>
import BlockPagination from '../utility/block-pagination';
import _ from "lodash";
import {mapGetters} from "vuex";
import accounting from "accounting";
import {VueContext} from 'vue-context';
import Axios from "axios";

export default {
  name: "invoice-table",
  components: {
    BlockPagination,
    VueContext,
  },
  data() {
    return {
      fieldsAll: [
        {
          label: "Number",
          key: "number",
          sortable: true,
          tdClass: 'text-center clickable number text-align-left font-bold',
          thClass: 'text-center bg-navyblue number'
        },
        {
          label: "Vehicle Owner",
          key: "vehicle_owner",
          sortable: true,
          tdClass: 'clickable customer font-bold',
          thClass: 'bg-navyblue customer'
        },
        {
          label: "Insurer",
          key: "insurer",
          sortable: true,
          tdClass: 'clickable insurer',
          thClass: 'bg-navyblue insurer'
        },
        {
          label: "Rego",
          key: "rego_number",
          sortable: true,
          tdClass: 'text-center clickable rego',
          thClass: 'text-center bg-navyblue rego'
        },
        {
          label: "Created Date",
          key: "created_date",
          sortable: true,
          sortByFormatted: (value, key, item) => {
            return item.sortCreatedDate;
          },
          tdClass: 'text-center clickable make',
          thClass: 'text-center bg-navyblue make'
        },
        {
          label: "Due Date",
          key: "due_date",
          sortable: true,
          tdClass: 'text-center clickable model',
          thClass: 'text-center bg-navyblue model',
          sortByFormatted: (value, key, item) => {
            return item.sortDueDate;
          },
        },
        {
          label: "Paid Date",
          key: "paidDate",
          sortable: true,
          sortByFormatted: (value, key, item) => {
            return item.sortPaidDate;
          },
          tdClass: 'clickable colour',
          thClass: 'text-center bg-navyblue colour'
        },
        {
          label: "Paid",
          key: "paid",
          sortable: true,
          sortByFormatted: (value, key, item) => {
            return accounting.unformat(item.paid);
          },
          tdClass: 'text-align-right clickable currency',
          thClass: 'text-center bg-navyblue currency'
        },
        {
          label: "Due",
          key: "due",
          sortable: true,
          sortByFormatted: (value, key, item) => {
            return accounting.unformat(item.due);
          },
          tdClass: 'text-align-right clickable currency',
          thClass: 'text-center bg-navyblue currency'
        },
        {
          label: "Payment Type",
          key: "paymentType",
          sortable: true,
          tdClass: 'text-align-right clickable currency',
          thClass: 'text-center bg-navyblue currency'
        },
        {
          label: "Status",
          key: "status_name",
          sortable: true,
          tdClass: 'text-center clickable status font-bold',
          thClass: 'text-center bg-navyblue status'
        }
      ],
      fieldsAwaitingPayment: [
        {
          label: "Number",
          key: "number",
          sortable: true,
          tdClass: 'text-center clickable number text-align-left font-bold',
          thClass: 'text-center bg-navyblue number'
        },
        {
          label: "Vehicle Owner",
          key: "vehicle_owner",
          sortable: true,
          tdClass: 'clickable customer font-bold',
          thClass: 'bg-navyblue customer'
        },
        {
          label: "Insurer",
          key: "insurer",
          sortable: true,
          tdClass: 'clickable insurer',
          thClass: 'bg-navyblue insurer'
        },
        {
          label: "Rego",
          key: "rego_number",
          sortable: true,
          tdClass: 'text-center clickable rego',
          thClass: 'text-center bg-navyblue rego'
        },
        {
          label: "Created Date",
          key: "created_date",
          sortable: true,
          sortByFormatted: (value, key, item) => {
            return item.sortCreatedDate;
          },
          tdClass: 'text-center clickable colour',
          thClass: 'text-center bg-navyblue colour'
        },
        {
          label: "Due Date",
          key: "due_date",
          sortable: true,
          sortByFormatted: (value, key, item) => {
            return item.sortDueDate;
          },
          tdClass: 'clickable insurance',
          thClass: 'text-center bg-navyblue insurance'
        },
        {
          label: "Overdue By",
          key: "overdue",
          sortable: true,
          tdClass: 'clickable insurance',
          thClass: 'text-center bg-navyblue insurance'
        },
        {
          label: "Paid",
          sortable: true,
          sortByFormatted: (value, key, item) => {
            return accounting.unformat(item.paid);
          },
          key: "paid",
          tdClass: 'text-align-right clickable booking-date',
          thClass: 'text-center bg-navyblue booking-date'
        },
        {
          label: "Due",
          sortable: true,
          key: "due",
          sortByFormatted: (value, key, item) => {
            return accounting.unformat(item.due);
          },
          tdClass: 'text-align-right clickable booking-date',
          thClass: 'text-center bg-navyblue booking-date'
        },
        {
          label: "Sent Status",
          sortable: true,
          key: "sent_status",
          tdClass: 'text-center clickable booking-date',
          thClass: 'text-center bg-navyblue booking-date'
        }
      ],
      fieldsPaid: [
        {
          label: "Number",
          key: "number",
          sortable: true,
          tdClass: 'text-center clickable number text-align-left font-bold',
          thClass: 'text-center bg-navyblue number'
        },
        {
          label: "Vehicle Owner",
          key: "vehicle_owner",
          sortable: true,
          tdClass: 'clickable customer font-bold',
          thClass: 'bg-navyblue customer'
        },
        {
          label: "Insurer",
          key: "insurer",
          sortable: true,
          tdClass: 'clickable insurer',
          thClass: 'bg-navyblue insurer'
        },
        {
          label: "Rego",
          key: "rego_number",
          sortable: true,
          tdClass: 'text-center clickable rego',
          thClass: 'text-center bg-navyblue rego'
        },
        {
          label: "Created Date",
          key: "created_date",
          sortByFormatted: (value, key, item) => {
            return item.sortCreatedDate;
          },
          sortable: true,
          tdClass: 'text-center clickable colour',
          thClass: 'text-center bg-navyblue colour'
        },
        {
          label: "Paid Date",
          key: "paid_date",
          sortByFormatted: (value, key, item) => {
            return item.sortPaidDate;
          },
          sortable: true,
          tdClass: 'text-center clickable colour',
          thClass: 'text-center bg-navyblue colour'
        },
        {
          label: "Paid",
          sortable: true,
          key: "paid",
          sortByFormatted: (value, key, item) => {
            return accounting.unformat(item.paid);
          },
          tdClass: 'text-align-right clickable booking-date',
          thClass: 'text-center bg-navyblue booking-date'
        },
        {
          label: "Payment Type",
          key: "paymentType",
          sortable: true,
          tdClass: 'text-align-right clickable currency',
          thClass: 'text-center bg-navyblue currency'
        },
        {
          label: "Sent Status",
          sortable: true,
          key: "sent_status",
          tdClass: 'text-center clickable booking-date font-bold',
          thClass: 'text-center bg-navyblue booking-date'
        }
      ],
      invoicesAllFiltered: 0,
      invoicesAwaitingPaymentFiltered: 0,
      invoicesPaidFiltered: 0,
      contextOpenEvent: null,
    }
  },
  props: {
    type: {
      type: String,
      default: 'All'
    },
  },
  methods: {
    sendToXero(id) {
      NProgress.start();
      Axios.post(`/fe/invoice/${id}/send-to-xero`)
          .then(response => {
            if (response.data && response.data._status) {
              toastr.success('Successfully sent to Xero');
            } else {
              toastr.error(response.data._error);
            }
          })
          .catch(e => console.error(e))
          .finally(() => NProgress.done());
    },
    viewOnXero(id) {
      NProgress.start();
      Axios.post(`/fe/invoice/${id}/view-on-xero`)
          .then(response => {
            if (response.data && response.data._status) {
              window.open(response.data.url, '__blank');
            }
          })
          .catch(e => console.error(e))
          .finally(() => NProgress.done());
      this.$refs.contextMenuForInvoices.close();
    },
    markAsUnpaid(id){
      this.saveInvoice({'unpaid': true}, id)
    },
    saveInvoice: function (data, id) {
      let invoice_id = id
      NProgress.start();
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/invoice/${invoice_id}`,
        validateStatus: function (status) {
          return status < 500;
        },
        data: JSON.stringify({data})
      })
          .then(response => {
            toastr.success('Invoice updated');
            NProgress.done();
          })
          .catch(error => {
            toastr.error('error')
            NProgress.done();
            console.log(error);
          });

    },
    getIsActiveFilter(field) {
      for (let key in this.searchFilterObj) {
        if (this.searchFilterObj[key][field]) {
          return true
        }
      }
      return false
    },
    getActiveFilter(field) {
      let filter = {            }
      filter[field] = ''
      let obj = Object.assign({}, this.searchFilterObj)
      for (let key in obj) {
        if (obj[key][field]) {
          filter = obj[key]
          break
        }
      }
      filter = _.split(filter[field], ';')
      return _.filter(filter, (f) => {return _.trim(f)})
    },
    getInsurerNameStyle(id) {
      if (!id) {
        return null
      }
      let data = this.getterInsurerCardColor?.[id];
      if (data) {
        return {
          color: data.color,
          backgroundColor: data.background,
          fontWeight: 800,
          padding: '3px 7px',
          borderRadius: '5px',
          textAlign: 'center',
          display: 'inline-block'
        }
      }
      return null
    },
    changedCurrentPage(v) {
      if (this.type === 'All') {
        this.$store.commit('invoice/setInvoicesAllCurrentPage', v)
      } else  if (this.type === 'Awaiting') {
        this.$store.commit('invoice/setInvoicesAwaitingPaymentCurrentPage', v)
      } else  if (this.type === 'Paid') {
        this.$store.commit('invoice/setInvoicesPaidCurrentPage', v)
      }
    },
    onRowContextMenu(item, index, event) {
      if (!this.isStatusActive) {
        return;
      }
      event.preventDefault();
      let row = event.target.parentElement;
      let rows = row.parentElement.rows;
      if (!rows) {
        row = event.target.parentElement.parentElement;
        rows = row.parentElement.rows;
      }
      for (let i = 0; i < rows.length; i++) {
        let elem = rows[i];
        elem.style.backgroundColor = "";
      }
      row.style.backgroundColor = "#F3F3F3";
      if (this.vendorInfo.activeXeroAuth && !this.isCustomerUser) {
        this.$refs.contextMenuForInvoices.open(event, {item: item, index: index});
      } else {
        this.$refs.contextMenuForInvoices.close();
      }

    },
    contextOpen: function (event, w, e) {
      this.contextOpenEvent = event;
    },
    contextClose: function () {
      let event = this.contextOpenEvent;
      if (event) {
        let row = event.target.parentElement;
        let rows = row.parentElement.rows;
        for (let i = 0; i < rows.length; i++) {
          let elem = rows[i];
          elem.style.backgroundColor = "";
        }
      }
    },
    onInvoicesRowClick: function (item, index, event) {
      if (item.is_excess_invoice) {
        this.$router.push('/invoice/view/' + item.id + '/excess');
      } else {
        this.$router.push('/invoice/view/' + item.id);
      }
    },
    onTableFiltered(fi) {
      if (this.type === 'All') {
        this.onAllInvoicesFiltered(fi)
      } else  if (this.type === 'Awaiting') {
        this.onAwaitingPaymentInvoicesFiltered(fi)
      } else  if (this.type === 'Paid') {
        this.onPaidInvoicesFiltered(fi)
      }
    },
    onAllInvoicesFiltered: function (fi) {
      this.invoicesAllFiltered = fi.length;
      this.$store.commit('invoice/setInvoicesAllCurrentPage', 1)
    },
    onAwaitingPaymentInvoicesFiltered: function (fi) {
      this.invoicesAwaitingPaymentFiltered = fi.length;
      this.$store.commit('invoice/setInvoicesAwaitingPaymentCurrentPage', 1)
    },
    onPaidInvoicesFiltered: function (fi) {
      this.invoicesPaidFiltered = fi.length;
      this.$store.commit('invoice/setInvoicesPaidCurrentPage', 1)
    },
    setSort(e) {
      if (this.type === 'All') {
        this.setSortByAndSortDescForInvoicesAll(e)
      } else  if (this.type === 'Awaiting') {
        this.setSortByAndSortDescForInvoicesAwaitingPayment(e)
      } else  if (this.type === 'Paid') {
        this.setSortByAndSortDescForInvoicesPaid(e)
      }
    },
    setSortByAndSortDescForInvoicesAll(e) {
      this.$store.commit('invoice/setSortByForInvoicesAll', e.sortBy);
      this.$store.commit('invoice/setSortDescForInvoicesAll', e.sortDesc);
    },
    setSortByAndSortDescForInvoicesAwaitingPayment(e) {
      this.$store.commit('invoice/setSortByForInvoicesAwaitingPayment', e.sortBy);
      this.$store.commit('invoice/setSortDescForInvoicesAwaitingPayment', e.sortDesc);
    },
    setSortByAndSortDescForInvoicesPaid(e) {
      this.$store.commit('invoice/setSortByForInvoicesPaid', e.sortBy);
      this.$store.commit('invoice/setSortDescForInvoicesPaid', e.sortDesc);
    },
    filterForTable(row, filter) {
      let formattedFilter = _.trim(_.toLower(filter))
      if (row.number && row.number.toLowerCase().includes(formattedFilter)) {
        return true;
      }
          // else if (row.vehicle_owner && row.vehicle_owner.toLowerCase().includes(formattedFilter)) {
          //   return true;
      // }
      else if (this.isCanSearchBySomeFields && row.insurer && row.insurer.toLowerCase().includes(formattedFilter)) {
        return true;
      }
      else if (row.rego_number && row.rego_number.toLowerCase().includes(formattedFilter)) {
        return true;
      } else if (row.model && row.model.toLowerCase().includes(formattedFilter)) {
        return true;
      } else if (this.isCanSearchBySomeFields && row.estimateNumber && row.estimateNumber.toLowerCase().includes(formattedFilter)) {
        return true;
      }
      // else if (row.created_date && row.created_date.includes(formattedFilter)) {
      //   return true;
      // } else if (row.due_date && row.due_date.includes(formattedFilter)) {
      //   return true;
      // } else if (row.paid && row.paid.includes(formattedFilter)) {
      //   return true;
      // } else if (row.due && row.due.includes(formattedFilter)) {
      //   return true;
      // } else if (row.status_name && row.status_name.toLowerCase().includes(formattedFilter)) {
      //   return true;
      // } else if (row.claimNumber && row.claimNumber.toLowerCase().includes(formattedFilter)) {
      //   return true;
      // } else if (row.sent_status && row.sent_status.toLowerCase().includes(formattedFilter)) {
      //   return true;
      // } else if (row.overdue && row.overdue.toString().includes(formattedFilter)) {
      //   return true;
      // } else if (row.paidDate && row.paidDate.includes(formattedFilter)) {
      //   return true;
      // } else if (row.paymentType && row.paymentType.toLowerCase().includes(formattedFilter)) {
      //   return true;
      // }
      return false;
    },
    filterInvoices(value) {
      return _.filter(value, (itm) => {
        if (!this.isInvoiceNumberFilterActive && !this.isInsurerNameFilterActive
            && !this.isRegoFilterActive && !this.isMakeFilterActive && !this.isModelFilterActive && !this.isVehicleOwnerFilterActive && !this.isEstimateNumberFilterActive) {
          return true
        }
        let b = false;
        if (this.isInvoiceNumberFilterActive) {
          if (_.includes((this.computedInvoiceNumberFilter), _.trim(itm.number))) {
            b = true
          }
        }

        if (this.isCanSearchBySomeFields && this.isInsurerNameFilterActive && _.trim(itm.insurer)) {
          if (_.includes((this.computedInsurerNameFilter), _.trim(itm.insurer))) {
            b = true
          }
        }

        if (this.isCanSearchBySomeFields &&this.isEstimateNumberFilterActive && _.trim(itm.estimateNumber)) {
          if (_.includes((this.computedEstimateNumberFilter), _.trim(itm.estimateNumber))) {
            b = true
          }
        }

        if (this.isVehicleOwnerFilterActive && _.trim(itm.vehicle_owner)) {
          if (_.includes((this.computedVehicleOwnerFilter), _.trim(itm.vehicle_owner))) {
            b = true
          }
        }

        if (this.isRegoFilterActive && _.trim(itm.rego_number)) {
          if (_.includes((this.computedRegoFilter), _.trim(itm.rego_number))) {
            b = true
          }
        }

        if (this.isMakeFilterActive && _.trim(itm.make)) {
          if (_.includes((this.computedMakeFilter), _.trim(itm.make))) {
            b = true
          }
        }
        if (this.isModelFilterActive && _.trim(itm.model)) {
          if (_.includes((this.computedModelFilter), _.trim(itm.model))) {
            b = true
          }
        }
        return b
      })
    },
  },
  computed: {
    ...mapGetters({
      'vendorInfo': 'vendorInfo',
      invoicesAll: 'invoice/getAllInvoices',
      invoicesAwaitingPayment: 'invoice/getAwaitingPaymentInvoices',
      invoicesPaid: 'invoice/getPaidInvoices',
      getterPerPage: 'invoice/getInvoicesPerPage',
      getterInvoicesAllCurrentPage: 'invoice/getInvoicesAllCurrentPage',
      getterInvoicesAwaitingPaymentCurrentPage: 'invoice/getInvoicesAwaitingPaymentCurrentPage',
      getterInvoicesPaidCurrentPage: 'invoice/getInvoicesPaidCurrentPage',
      getterSortByForInvoicesAll: 'invoice/getSortByForInvoicesAll',
      getterSortDescForInvoicesAll: 'invoice/getSortDescForInvoicesAll',
      getterSortByForInvoicesAwaitingPayment: 'invoice/getSortByForInvoicesAwaitingPayment',
      getterSortDescForInvoicesAwaitingPayment: 'invoice/getSortDescForInvoicesAwaitingPayment',
      getterSortByForInvoicesPaid: 'invoice/getSortByForInvoicesPaid',
      getterSortDescForInvoicesPaid: 'invoice/getSortDescForInvoicesPaid',
      getterInsurerCardColor: 'getInsurerCardColor',
      isStatusActive: 'isStatusActive',
      searchFilterObj: 'invoice/getSearchFilterObj',
      filter: 'invoiceFilter',

      isPDRTechnicianUser: 'isPDRTechnicianUser',
      isTechnicalUser: 'isTechnicalUser',
      isPanelTechnicianUser: 'isPanelTechnicianUser',
      isPaintTechnicianUser: 'isPaintTechnicianUser',
      isStripperFitterUser: 'isStripperFitterUser',
      isDisplayBoardUser: 'isDisplayBoardUser',
      isCustomerUser: 'isCustomerUser',
    }),
    isCanSearchBySomeFields() {
      return !this.isPDRTechnicianUser && !this.isPanelTechnicianUser && !this.isPaintTechnicianUser && !this.isStripperFitterUser && !this.isDisplayBoardUser
    },
    computedInvoicesAll() {
      return this.filterInvoices(this.invoicesAll)
    },
    computedInvoicesAwaitingPayment() {
      return this.filterInvoices(this.invoicesAwaitingPayment)
    },
    computedInvoicesPaid() {
      return this.filterInvoices(this.invoicesPaid)
    },
    isInvoiceNumberFilterActive() {
      return this.getIsActiveFilter('invoiceNumber')
    },
    computedInvoiceNumberFilter() {
      return this.getActiveFilter('invoiceNumber')
    },

    isEstimateNumberFilterActive() {
      return this.getIsActiveFilter('estimateNumber')
    },
    computedEstimateNumberFilter() {
      return this.getActiveFilter('estimateNumber')
    },

    isVehicleOwnerFilterActive() {
      return this.getIsActiveFilter('vehicleOwner')
    },
    computedVehicleOwnerFilter() {
      return this.getActiveFilter('vehicleOwner')
    },

    isInsurerNameFilterActive() {
      return this.getIsActiveFilter('insurer')
    },
    computedInsurerNameFilter() {
      return this.getActiveFilter('insurer')
    },

    isRegoFilterActive() {
      return this.getIsActiveFilter('rego')
    },
    computedRegoFilter() {
      return this.getActiveFilter('rego')
    },

    isMakeFilterActive() {
      return this.getIsActiveFilter('make')
    },
    computedMakeFilter() {
      return this.getActiveFilter('make')
    },

    isModelFilterActive() {
      return this.getIsActiveFilter('model')
    },
    computedModelFilter() {
      return this.getActiveFilter('model')
    },
  }
}
</script>

<style scoped>

</style>
