<template>
  <div class="box-block bg-white cards" id="table-view">
    <tabs
        ref="cards-table"
        cache-lifetime="0"
        @changed="tableTabChanged"
        :options="{useUrlFragment: false, defaultTabHash: computedDefaultCardTableTabHash }"
    >
      <tab name="All" id="cards-all" :class-name="'tab-content-table'">
        <card-table
            :cards="computedCardsAllForTable"
            :cardsGetter="cardsAll"
            :sortBy="getterSortByForCardsAll"
            :sortDesc="getterSortDescForCardsAll"
            :currentPage="getterCardsAllCurrentPage"
            :type="'All'"
            @setSortByAndSortDesc="v => setSortByAndSortDescForCards(v, 'All')"
            @setCurrentPage="v => $store.commit('card/setCardsAllCurrentPage', v)"
        ></card-table>
      </tab>
      <tab name="Open" id="cards-open" :class-name="'tab-content-table'">
          <card-table
            :cards="computedCardsOpenForTable"
            :cardsGetter="cardsOpen"
            :sortBy="getterSortByForCardsOpen"
            :sortDesc="getterSortDescForCardsOpen"
            :currentPage="getterCardsOpenCurrentPage"
            :type="'Open'"
            @setSortByAndSortDesc="v => setSortByAndSortDescForCards(v, 'Open')"
            @setCurrentPage="v => $store.commit('card/setCardsOpenCurrentPage', v)"
        ></card-table>
      </tab>
      <tab name="Active" id="cards-active" :class-name="'tab-content-table'">
        <card-table
            :cards="computedCardsActiveForTable"
            :cardsGetter="cardsActive"
            :sortBy="getterSortByForCardsActive"
            :sortDesc="getterSortDescForCardsActive"
            :currentPage="getterCardsActiveCurrentPage"
            :type="'Active'"
            @setSortByAndSortDesc="v => setSortByAndSortDescForCards(v, 'Active')"
            @setCurrentPage="v => $store.commit('card/setCardsActiveCurrentPage', v)"
        ></card-table>
      </tab>
      <tab name="Closed" id="cards-closed" :class-name="'tab-content-table'">
          <card-table
            :cards="computedCardsClosedForTable"
            :cardsGetter="cardsClosed"
            :sortBy="getterSortByForCardsClosed"
            :sortDesc="getterSortDescForCardsClosed"
            :currentPage="getterCardsClosedCurrentPage"
            :type="'Closed'"
            @setSortByAndSortDesc="v => setSortByAndSortDescForCards(v, 'Closed')"
            @setCurrentPage="v => $store.commit('card/setCardsClosedCurrentPage', v)"
        ></card-table>
      </tab>

      <template slot="nav-item-center"
                v-if="!isDisplayBoardUser && !isMobileOnly && (isShopManagerUser || isProductionManagerUser || isEstimatorUser || isPDRTechnicianUser || isCustomerUser)">
        <div class="btn-group cards-view-button" role="group">
          <button v-if="!isDisplayBoardUser" type="button" class="btn btn-primary active">Table</button>
          <button v-if="!isDisplayBoardUser" @click="setViewBoard" type="button" class="btn">Board</button>
        </div>
      </template>
      <template slot="nav-item-right">
        <div
            v-if="isStripperFitterUser || isPanelTechnicianUser || isPaintTechnicianUser || isEstimatorUser"
            style="margin-right: 15px;"
            class="tabs-nav-right-btn">
          <button @click.prevent="clickFilterTableForMine" class="btn" :class="{'btn-primary': isJustSeeMine, 'btn-secondary':!isJustSeeMine}">
            Just See Mine
          </button>
        </div>
        <div class="tabs-nav-right-search tabs-nav-right-search-font-weight">
          <!--                        <b-form-input-->
          <!--                                v-model="filter"-->
          <!--                                type="text"-->
          <!--                                class="form-control search-input"-->
          <!--                                placeholder="Search or Filter results"-->
          <!--                                debounce="300"-->
          <!--                        ></b-form-input>-->

          <search-filter2
              type="estimates"
              v-model="searchFilter"
              class="search-filter"
              :filters="computedSearchFilters"
              :filterValue="filter"
              :limit-length="50"
              :isShowSearIcon="true"
              @change="onSearchFilterChange"
              @updateToken="value => $store.commit('card/setToken', value)"
              @updateTokens="value => $store.commit('card/setTokens', value)"
              :tokenCopy="getterToken"
              :tokensCopy="getterTokens"
              height="41px"
              aria-expanded="false"
              data-toggle="dropdown"
              :isMyltipleOptions="true"
              :isMyltiple="true"
              :optionsLength="isCanSearchBySomeFields ? 8 : 6"
          ></search-filter2>
        </div>
        <div
            v-if="(isShopManagerUser || isProductionManagerUser || isEstimatorUser || isPDRTechnicianUser || isCustomerUser) && isStatusActive"
            class="tabs-nav-right-btn"
            style="position: relative; bottom: 8px">
          <router-link to="/cards/new" class="btn btn-primary">
            <i class="ti-plus"></i> New Card
          </router-link>
        </div>
      </template>
    </tabs>
  </div>
</template>

<script>
import CardTable from './card-table'
import SearchFilter2 from '../../search-filter/search-filter-2'
import {mapGetters} from "vuex";
import {isMobileOnly} from "../../../deviceDetect/index";
import CardsMixins from '../cards-mixins'
export default {
  mixins: [CardsMixins],
  components: {
    CardTable,
    SearchFilter2
  },
  data() {
    return {
    }
  },
  name: "cards-table",
  methods: {
    setViewBoard: function () {
      this.$storage.set("views.cards.defaultView", "board-view");
      this.$emit('switch', 'board-view')
    },
    clickFilterTableForMine() {
      this.isJustSeeMine = !this.isJustSeeMine;
    },
    tableTabChanged(selectedTab) {
      this.$storage.set("cards.tabs.defaultCardsTable", selectedTab.tab.id);
    },

    setSortByAndSortDescForCards(e, type) {
      this.$store.commit('card/setSortByForCards' + type, e.sortBy);
      this.$store.commit('card/setSortDescForCards' + type, e.sortDesc);
    },
  },
  computed: {
    ...mapGetters({
      cardsOpen: 'card/getOpenCards',
      cardsClosed: 'card/getClosedCards',
      cardsActive: 'card/getActiveCards',
      getterSortByForCardsAll: 'card/getSortByForCardsAll',
      getterSortDescForCardsAll: 'card/getSortDescForCardsAll',
      getterSortByForCardsOpen: 'card/getSortByForCardsOpen',
      getterSortDescForCardsOpen: 'card/getSortDescForCardsOpen',
      getterSortByForCardsActive: 'card/getSortByForCardsActive',
      getterSortDescForCardsActive: 'card/getSortDescForCardsActive',
      getterSortByForCardsClosed: 'card/getSortByForCardsClosed',
      getterSortDescForCardsClosed: 'card/getSortDescForCardsClosed',
      getterCardsAllCurrentPage: 'card/getCardsAllCurrentPage',
      getterCardsOpenCurrentPage: 'card/getCardsOpenCurrentPage',
      getterCardsActiveCurrentPage: 'card/getCardsActiveCurrentPage',
      getterCardsClosedCurrentPage: 'card/getCardsClosedCurrentPage',
    }),
    isMobileOnly: function () {
      return isMobileOnly;
    },
    isCanSearchBySomeFields() {
      return !this.isPDRTechnicianUser && !this.isPanelTechnicianUser && !this.isPaintTechnicianUser && !this.isStripperFitterUser && !this.isDisplayBoardUser
    },
    isJustSeeMine: {
      get() {
        return this.$store.getters.isJustSeeMine;
      },
      set(val) {
        this.$store.dispatch('updateJustSeeMine', val);
      }
    },
    computedDefaultCardTableTabHash: function () {
      if (this.$storage.get("cards.tabs.defaultCardsTable")) {
        //  console.log('ret', this.$storage.get('cards.tabs.defaultCardsTable'));
        return this.$storage.get("cards.tabs.defaultCardsTable");
      }
      return "cards-all";
    },

    computedCardsAllForTable() {
      if (this.isHasNotFilters) {
        return this.cardsAll
      }
      return this.filterCards(this.cardsAll)
    },
    computedCardsOpenForTable() {
      if (this.isHasNotFilters) {
        return this.cardsOpen
      }
      return this.filterCards(this.cardsOpen)
    },
    computedCardsActiveForTable() {
      if (this.isHasNotFilters) {
        return this.cardsActive
      }
      return this.filterCards(this.cardsActive)
    },
    computedCardsClosedForTable() {
      if (this.isHasNotFilters) {
        return this.cardsClosed
      }
      return this.filterCards(this.cardsClosed)
    },
  }
}
</script>

<style>
.cards .tabs-nav-right-search .search-filter .custom-dropdown {
  width: auto !important;
  min-width: 128px !important;
}
</style>

<style scoped>
.site-content-mobile .search-filter.pl-3{
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.tabs-nav-right-search .search-filter >>> .form-control:focus{
  outline: 0 !important;
}
.tabs-nav-right-search .search-filter >>> .search-filter__input{
  font-size: 13px !important;
  font-weight: 800;
  color: black;
}
.tabs-nav-right-search .search-filter >>> .search-filter__box{
  border: 3px solid #5e77fd;
  border-radius: 7px;
}
.search-filter >>> input::placeholder{
  color: #a6a6a6;
  font-weight: 700;
}
.tabs-nav-right-search .search-filter >>> .form-control:focus{
  outline: 0 !important;
}
.tabs-nav-right-search .search-filter >>> .search-filter__input{
  font-size: 13px !important;
  font-weight: 800;
  color: black;
}
.tabs-nav-right-search .search-filter >>> .search-filter__box{
  border: 3px solid #5e77fd;
  border-radius: 7px;
}
.search-filter >>> input::placeholder{
  color: #bbbbc2;
}
.search-filter >>> input:focus{
  background-color: #ebf0fc;
}
.tabs-nav-right-search .search-filter >>> .form-control:focus{
  outline: 0 !important;
}
.search-filter >>> .search-filter__box__focused{
  background-color: #ebf0fc;
  border: 3px solid rgba(94, 121, 255, 0.5) !important;
  box-shadow: 0 0 0 0.2rem rgb(94 121 255 / 25%) !important;
}
.search-filter >>> .search-filter__li--input{
  border-bottom: 0;
}
.search-filter >>> .search-filter__li{
  border-bottom: 0 !important;
}
.search-filter >>> .search-filter__box{
  border: 2px solid rgba(28, 31, 57, 0.25);
}

.tabs-nav-right-search-font-weight >>> input{
  font-weight: 700;
}
.tabs-nav-right-search-font-weight >>> input::placeholder{
  font-weight: 600;
}
.cards >>> .tabs-component{
  touch-action: none;
}
</style>
