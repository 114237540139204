<template>
    <div class="">
        <div class="row no-gutter header-content">
            <div class="col-12 col-md-4 chart-title">
                Invoice Processed Summary
            </div>
            <div class="col-12 col-md-6 header-right d-flex">
                <div class="navbar-nav nav dropdown dropdown-options  show inline" style="margin-left: 99px;">
                    <button type="button"
                            class="btn waves-effect waves-light ex-options-button"
                            id="dropdownMenuLink1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true">
                        Export
                        <span class="dropdown-arrow pull-right" style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                                    <i class="fa fa-chevron-down pull-right"></i>
                                </span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right animated fadeInUp"
                         aria-labelledby="dropdownMenuLink">
                        <a class="dropdown-item text-primary p-1 clickable" @click="exportReport('csv')">.CSV</a>
                        <a class="dropdown-item text-primary p-1 clickable" @click="exportReport('xlsx')">.XLS</a>
                    </div>
                </div>
                <div class="navbar-nav nav dropdown dropdown-options  show inline">
                    <button type="button"
                            class="btn waves-effect waves-light ex-options-button"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true">
                        {{adjustedBy}}
                        <span class="dropdown-arrow pull-right" style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                                    <i class="fa fa-chevron-down pull-right"></i>
                                </span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right animated fadeInUp"
                         aria-labelledby="dropdownMenuLink">
                        <a class="dropdown-item text-primary p-1 clickable" @click="adjustedBy = 'Week';    selectedColumn = '';">Week</a>
                        <a class="dropdown-item text-primary p-1 clickable" @click="adjustedBy = 'Month';   selectedColumn = '';">Month</a>
                        <a class="dropdown-item text-primary p-1 clickable" @click="adjustedBy = 'Quarter'; selectedColumn = '';">Quarter</a>
                        <a class="dropdown-item text-primary p-1 clickable" @click="adjustedBy = 'Year';    selectedColumn = '';">Year</a>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!isEmptyReportsData" class="mt-2 text-right chart-interval-block">
            <div>
                {{getDate}}
            </div>
        </div>
        <div class="chart-wrapper col-12 col-md-8">
            <vue-apex-chart
                v-if="isHasData"
                @click="onClick"
                width="1000"
                height="500"
                type="bar"
                :series="computedSeries"
                :options="computedChartOptions"
            ></vue-apex-chart>

            <div style="position: relative" v-if="isHasData">
              <div class="navbar-nav nav dropdown dropdown-options  show inline" style="right: -827px;">
                  <button type="button"
                        v-on:click="showFilters = !showFilters"
                            class="btn waves-effect waves-light ex-options-button"
                        >
                        Filters
                        <span class="dropdown-arrow pull-right" style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                                      <i v-if="!showFilters" class="fa fa-chevron-down pull-right"></i>
                                      <i v-if="showFilters" class="fa fa-chevron-up pull-right"></i>
                                  </span>
                </button>
              </div>
              <div v-if="showFilters" class="active-filters-control">
                <div class="filters-checkboxes">
                  <span class="filters-checkboxes-title">Display Invoice Types</span>
                  <label class="form-check-inline checkbox filters-checkbox">
                    <input  class="form-check-input" type="checkbox" name="excess" v-model="isTypeExcess">
                    <span class="icon"><i class='bx bx-check'></i></span><span class="text">Excess</span>
                  </label>
                  <label class="form-check-inline checkbox filters-checkbox">
                    <input v-model="isTypeComplete"  class="form-check-input" type="checkbox" name="complete">
                    <span class="icon"><i class='bx bx-check'></i></span><span class="text">Complete</span>
                  </label>
                </div>

                <div v-if="!isSelectedColumn">
                  <div class="filters-checkboxes">
                    <span class="filters-checkboxes-title">Display Breakdown</span>
                    <label class="form-check-inline checkbox filters-checkbox" @click="isDisplayBreakdownNo = false">
                                <input class="form-check-input" type="checkbox" v-model="isDisplayBreakdownYes">
                                <span class="icon"><i class='bx bx-check'></i></span><span class="text">Yes</span>
                            </label>
                    <label class="form-check-inline checkbox filters-checkbox" @click="isDisplayBreakdownYes = false">
                                <input class="form-check-input" type="checkbox" v-model="isDisplayBreakdownNo">
                                <span class="icon"><i class='bx bx-check'></i></span><span class="text">No</span>
                            </label>
                  </div>
                    </div>
                </div>
            </div>

            <b-table
                    v-if="isSelectedColumn"
                    responsive
                    hover
                    :items="computedInvoiceDetailData"
                    :fields="fieldsForInvoiceDetail"
                    class="report-table"
            >
                <template v-slot:cell(total)="data">
                    {{data.item.total|formatMoney}}
                </template>
                <template v-slot:cell(gst)="data">
                    {{data.item.gst|formatMoney}}
                </template>
                <template v-slot:cell(subtotal)="data">
                    {{data.item.subtotal|formatMoney}}
                </template>
                <template v-slot:cell(isExcess)="data">
                    <span v-if="data.item.isExcess">Excess</span>
                    <span v-else>Complete</span>
                </template>
            </b-table>
            <b-table
                    v-if="!isSelectedColumn && isHasData"
                    responsive
                    hover
                    :items="chartDataForTable"
                    :fields="fields"
                    class="report-table"
            >
                <template v-slot:cell(count)="data">
                    <div v-if="data.item.count === 0">
                        -
                    </div>
                    <div v-else>
                        {{data.item.count}}
                    </div>
                </template>

                <template v-slot:cell(countComplete)="data">
                    <div v-if="data.item.countComplete === 0">
                        -
                    </div>
                    <div v-else>
                        {{data.item.countComplete}}
                    </div>
                </template>

                <template v-slot:cell(countExcess)="data">
                    <div v-if="data.item.countExcess === 0">
                        -
                    </div>
                    <div v-else>
                        {{data.item.countExcess}}
                    </div>
                </template>
            </b-table>
<!--            <p class="no-data-text" v-if="!isHasData">No data for graph</p>-->
        </div>
    </div>
</template>

<script>
    import {appConfig} from '../../../../config';
    import Multiselect from 'vue-multiselect';
    import _ from 'lodash';
    import {mapActions, mapGetters} from "vuex";
    import moment from "moment";
    import Axios from "axios";
    import VueApexCharts from 'vue-apexcharts';

    export default {
        name: "ProcessedSummary",
        data() {
            return {
                months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                fieldsForInvoiceDetail: [
                    {label: "Invoice Number", key: "invoiceNumber", sortable: true, tdClass: '',},
                    {label: "Invoice Creation Date", key: "invoiceCreationDate", sortable: true, tdClass: '',},
                    {label: "Rego", key: "rego", sortable: true, tdClass: '',},
                    {label: "Vehicle Owner", key: "vehicleOwner", sortable: true, tdClass: '',},
                    {label: "Subtotal", key: "subtotal", sortable: true, tdClass: '',},
                    {label: "GST", key: "gst", sortable: true, tdClass: '',},
                    {label: "Grandtotal", key: "total", sortable: true, tdClass: '',},
                    {label: 'Type', key: 'isExcess', sortable: true, tdClass: ''},
                ],
                isTypeExcess: false,
                showFilters: false,
                isDisplayBreakdownYes: false,
                isDisplayBreakdownNo: true,
                isTypeComplete: true,
                invoiceDetailData: [],
                adjustedBy: 'Month',
                intervalUpdateReports: null,
                selectedColumn: '',
                chartEvents: {
                    'click': (event) => {
                        //  console.log('event', event);
                        if (!event.targetID.includes('bar#')) {
                            return;
                        }
                        let index = event.targetID.split('#');
                        index = index[index.length - 1];
                        if (this.selectedColumn != this.chartData[Number(index) + 1][0]) {
                            this.selectedColumn = this.chartData[Number(index) + 1][0];
                            this.clickOnChart();
                        } else {
                            this.selectedColumn = '';
                        }
                    }
                }
            };
        },
        computed: {
            ...mapGetters({
                getterIPS: 'report/getIPS',
            }),
            computedSeries() {
              let data = [{
                data: [],
              }];
              if (this.isAllInvoices) {
                data = [
                  // {
                  //   data: [],
                  //   name: 'Invoice Totals',
                  // },
                  {
                    data: [],
                    name: 'Complete',
                  },
                  {
                    data: [],
                    name: 'Excess',
                  },
                ]
              }

              if (!this.chartDataForTable) {
                return  data;
              }
              let reportData = this.adjustedBy === 'Month' || this.adjustedBy === 'Quarter' ? this.chartDataForTable.slice().reverse() : this.chartDataForTable.slice();
              _.forEach(reportData, (itm) => {
                if (this.isAllInvoices) {
                  // data[0].data.push(itm.count)
                  data[0].data.push(itm.countComplete)
                  data[1].data.push(itm.countExcess)
                } else {
                  data[0].data.push(itm.count)
                }
              })

              if (this.adjustedBy === 'Month') {
                if (this.isAllInvoices) {
                  data[0].data = data[0].data.slice(6, 12)
                  data[1].data = data[1].data.slice(6, 12)
                } else {
                  data[0].data = data[0].data.slice(6, 12)
                }
              }

              return data;
            },
            currentMonth() {
              return new Date().getMonth();
            },
            computedChartOptions() {
              let options = {
                chart: {
                  id: 'vuechart-example',
                  stacked: true,
                  toolbar: {
                    show: false,
                  },
                },
                colors: ['#3366cc', '#dc3912', '#ff9900', '#109618', '#990099', '#0099c6', '#dd4477', '#66aa00', '#b82e2e', '#316395', '#994499', '#22aa99', '#aaaa11', '#6633cc', '#e67300', '#8b0707', '#651067', '#329262', '#5574a6', '#3b3eac', '#b77322', '#16d620', '#b91383', '#f4359e', '#9c5935', '#a9c413', '#2a778d', '#668d1c', '#bea413', '#0c5922', '#743411', '#f4359e', '#b91383', '#16d620', '#b77322', '#3b3eac', '#5574a6', '#329262', '#329262', '#651067', '#8b0707', '#e67300', '#6633cc', '#aaaa11', '#22aa99', '#994499', '#316395', '#b82e2e', '#66aa00', '#dd4477', '#0099c6', '#990099', '#109618', '#ff9900', '#dc3912', '#3366cc', '#ca24d6', '#378784', '#ab8965', '#141e7d', '#edc7c7', '#167d76', '#d5f74a', '#3022c9', '#705658'],
                xaxis: {
                  categories: []
                },
                legend: {
                  showForNullSeries: false,
                  position: 'top',
                  height: 38,
                },
                tooltip: {
                  marker: {show: true},
                  enabled: true,
                  y: {
                    title: {
                      formatter: () => {
                        return 'Invoice Numbers: '
                      },
                    },
                  },
                  style: {
                    fontSize: '13px',
                    fontFamily: 'Nunito Sans',
                    fontWeight: 'bold',
                  },
                },
                dataLabels: {
                  enabled: false,
                },
              }
              let result = [];
              if (this.adjustedBy === 'Month') {
                let prevMonths = [];
                let self = this;
                _.forEach(this.months, function (item) {
                  if (self.months.indexOf(item) > self.currentMonth) {
                    prevMonths.push(item)
                  }
                })

                let currentMonths = [];
                _.forEach(self.months, function (item) {
                  let index = self.months.indexOf(item)
                  if (index <= self.currentMonth) {
                    currentMonths.push(item)
                  }
                });

                _.forEach(prevMonths, function (item) {
                  result.push(item)
                })
                _.forEach(currentMonths, function (item) {
                  result.push(item)
                });
              } else if (this.adjustedBy === 'Week') {
                result = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
              } else if (this.adjustedBy === 'Quarter') {
                let q1 = 'January - March';
                let q2 = 'April - June';
                let q3 = 'July - September';
                let q4 = 'October - December';

                let i1 = [0, 1, 2];
                let i2 = [3, 4, 5];
                let i3 = [6, 7, 8];
                let i4 = [9, 10, 11];

                if (i1.includes(this.currentMonth)) {
                  result = [
                    q2,
                    q3,
                    q4,
                    q1
                  ]
                } else if (i2.includes(this.currentMonth)) {
                  result = [
                    q3,
                    q4,
                    q1,
                    q2,
                  ]
                } else if (i3.includes(this.currentMonth)) {
                  result = [
                    q4,
                    q1,
                    q2,
                    q3,
                  ]
                } else if (i4.includes(this.currentMonth)) {
                  result = [
                    q1,
                    q2,
                    q3,
                    q4,
                  ]
                }
              } else if (this.adjustedBy === 'Year') {
                for (let key in this.getterIPS.year) {
                  result.push(key);
                }
              }

              options.xaxis.categories.splice(0, options.xaxis.categories.length);
              _.forEach(result, item => {
                options.xaxis.categories.push(item);
              })

              if (this.adjustedBy === 'Month') {
                options.xaxis.categories = options.xaxis.categories.slice(6, 12)
              }

              return options;
            },
            computedInvoiceDetailData() {
                let data = this.invoiceDetailData;
                if (this.isCompleteInvoiceOnly) {
                    return _.filter(data, (i) => {
                        return !i.isExcess;
                    });
                } else if (this.isExcessInvoiceOnly) {
                    return _.filter(data, (i) => {
                        return i.isExcess;
                    });
                } else {
                    return data;
                }
            },
            isExcessInvoiceOnly() {
                return this.isTypeExcess && !this.isTypeComplete;
            },
            isCompleteInvoiceOnly() {
                return !this.isTypeExcess && this.isTypeComplete;
            },
            isAllInvoices() {
                return this.isTypeExcess && this.isTypeComplete && !this.isDisplayBreakdownNo && this.isDisplayBreakdownYes;
            },
            isSelectedColumn() {
                return Boolean(this.selectedColumn != '');
            },
            isHasData() {
                if (this.isEmptyReportsData) {
                    return false;
                }
                if (this.adjustedBy === 'Week') {
                    let isEmptyWeek = true;
                    for (let day in this.getterIPS.week) {
                        if (!_.isEmpty(this.getterIPS.week[day])) {
                            isEmptyWeek = false;
                            break;
                        }
                    }
                    return !this.isEmptyReportsData && !isEmptyWeek;
                } else if (this.adjustedBy === 'Year') {
                    let isEmptyYear = true;
                    for (let year in this.getterIPS.year) {
                        if (!_.isEmpty(this.getterIPS.year[year])) {
                            isEmptyYear = false;
                            break;
                        }
                    }
                    return !this.isEmptyReportsData && !isEmptyYear;
                } else if (this.adjustedBy === 'Month') {
                    let isEmptyMonth = true;
                    for (let month in this.getterIPS.month) {
                        if (!_.isEmpty(this.getterIPS.month[month])) {
                            isEmptyMonth = false;
                            break;
                        }
                    }
                    return !this.isEmptyReportsData && !isEmptyMonth;
                } else if (this.adjustedBy === 'Quarter') {
                    let isEmptyQuarter = true;
                    for (let quarter in this.getterIPS.quarter) {
                        if (!_.isEmpty(this.getterIPS.quarter[quarter])) {
                            isEmptyQuarter = false;
                            break;
                        }
                    }
                    return !this.isEmptyReportsData && !isEmptyQuarter;
                }
            },
            getDate() {
                let lastIndex = this.computedChartOptions.xaxis.categories.length - 1;
                if (this.isEmptyReportsData) {
                    return '';
                }
                if (this.adjustedBy === "Week") {
                    let monday = moment().startOf('isoweek').toDate();
                    let sunday = moment().endOf('isoweek').toDate();

                    let mDay = String(monday.getDate()).padStart(2, '0');
                    let mMonth = String(monday.getMonth() + 1).padStart(2, '0');
                    let mYear = monday.getFullYear();

                    let mResult = mDay + '/' + mMonth + '/' + mYear;

                    let sDay = String(sunday.getDate()).padStart(2, '0');
                    let sMonth = String(sunday.getMonth() + 1).padStart(2, '0');
                    let sYear = sunday.getFullYear();

                    let sResult = sDay + '/' + sMonth + '/' + sYear;

                    return 'Mon' + ' ' + mResult + ' - ' + 'Sun ' + sResult;
                } else if (this.adjustedBy === 'Month') {
                    let firstMonth = this.computedChartOptions.xaxis.categories[0];
                    let lastMonth = this.computedChartOptions.xaxis.categories[lastIndex];
                    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

                    if (lastMonth === 'December') {
                        let y1 = new Date().getFullYear();

                        return firstMonth + ' ' + y1 + ' - ' + lastMonth + ' ' + y1;
                    }

                    if (months.indexOf(firstMonth) > months.indexOf(lastMonth)) {
                        let y2 = new Date().getFullYear();
                        let y1 = y2 - 1;

                        return firstMonth + ' ' + y1 + ' - ' + lastMonth + ' ' + y2;
                    }
                } else if (this.adjustedBy === 'Quarter') {
                    let firstQuarter = this.computedChartOptions.xaxis.categories[0];
                    let lastQuarter = this.computedChartOptions.xaxis.categories[lastIndex];
                    let quarters = ['January - March', 'April - June', 'July - September', 'October - December'];

                    if (lastQuarter === 'October - December') {
                        let y1 = new Date().getFullYear();

                        return firstQuarter + ' ' + y1 + ' - ' + lastQuarter + ' ' + y1;
                    }

                    if (quarters.indexOf(firstQuarter) > quarters.indexOf(lastQuarter)) {
                        let y2 = new Date().getFullYear();
                        let y1 = y2 - 1;

                        return firstQuarter + ' ' + y1 + ' to ' + lastQuarter + ' ' + y2;
                    }
                } else if (this.adjustedBy === 'Year') {
                    let firstYear = this.computedChartOptions.xaxis.categories[0];
                    let lastYear = this.computedChartOptions.xaxis.categories[lastIndex];

                    return firstYear + ' - ' + lastYear;
                }
            },
            isEmptyReportsData() {
                return _.isEmpty(this.getterIPS);
            },
            fields() {
                let data = [];
                if (this.adjustedBy === 'Month') {
                    data.push(
                        {
                            label: "Month",
                            key: "month",
                            sortable: true,
                            tdClass: 'clickable',
                        },
                    );
                } else if (this.adjustedBy === 'Week') {
                    data.push(
                        {
                            label: "Day",
                            key: "day",
                            sortable: true,
                            tdClass: 'clickable',
                        },
                    );
                } else if (this.adjustedBy === 'Year') {
                    data.push(
                        {
                            label: "Year",
                            key: "year",
                            sortable: true,
                            tdClass: 'clickable',
                        },
                    );
                } else if (this.adjustedBy === 'Quarter') {
                    data.push(
                        {
                            label: "Quarter",
                            key: "quarter",
                            sortable: true,
                            tdClass: 'clickable',
                        },
                    );
                }

                if (this.isAllInvoices) {
                    data.push(
                        {
                            label: 'Invoice Numbers',
                            key: 'count',
                            sortable: true,
                            tdClass: 'clickable',
                        }
                    );

                    data.push(
                        {
                            label: 'Excess Numbers',
                            key: 'countExcess',
                            sortable: true,
                            tdClass: 'clickable',
                        }
                    );

                    data.push(
                        {
                            label: 'Complete Numbers',
                            key: 'countComplete',
                            sortable: true,
                            tdClass: 'clickable',
                        }
                    );
                } else {
                    data.push(
                        {
                            label: 'Invoice Numbers',
                            key: 'count',
                            sortable: true,
                            tdClass: 'clickable',
                        }
                    );
                }
                return data;
            },
            chartDataForTable() {
                if (this.isEmptyReportsData) {
                    return null;
                }
                if (this.adjustedBy === 'Month') {
                    let d = new Date();
                    let currentMonth = d.getMonth();
                    let months = [];

                    if (this.isCompleteInvoiceOnly) {
                        months = [
                            {
                                month: 'January',
                                count: this.getterIPS.month.January.countComplete
                            },
                            {
                                month: 'February',
                                count: this.getterIPS.month.February.countComplete
                            },
                            {
                                month: 'March',
                                count: this.getterIPS.month.March.countComplete
                            },
                            {
                                month: 'April',
                                count: this.getterIPS.month.April.countComplete
                            },
                            {
                                month: 'May',
                                count: this.getterIPS.month.May.countComplete
                            },
                            {
                                month: 'June',
                                count: this.getterIPS.month.June.countComplete
                            },
                            {
                                month: 'July',
                                count: this.getterIPS.month.July.countComplete
                            },
                            {
                                month: 'August',
                                count: this.getterIPS.month.August.countComplete
                            },
                            {
                                month: 'September',
                                count: this.getterIPS.month.September.countComplete
                            },
                            {
                                month: 'October',
                                count: this.getterIPS.month.October.countComplete
                            },
                            {
                                month: 'November',
                                count: this.getterIPS.month.November.countComplete
                            },
                            {
                                month: 'December',
                                count: this.getterIPS.month.December.countComplete
                            },
                        ];
                    } else if (this.isExcessInvoiceOnly) {
                        months = [
                            {
                                month: 'January',
                                count: this.getterIPS.month.January.countExcess
                            },
                            {
                                month: 'February',
                                count: this.getterIPS.month.February.countExcess
                            },
                            {
                                month: 'March',
                                count: this.getterIPS.month.March.countExcess
                            },
                            {
                                month: 'April',
                                count: this.getterIPS.month.April.countExcess
                            },
                            {
                                month: 'May',
                                count: this.getterIPS.month.May.countExcess
                            },
                            {
                                month: 'June',
                                count: this.getterIPS.month.June.countExcess
                            },
                            {
                                month: 'July',
                                count: this.getterIPS.month.July.countExcess
                            },
                            {
                                month: 'August',
                                count: this.getterIPS.month.August.countExcess
                            },
                            {
                                month: 'September',
                                count: this.getterIPS.month.September.countExcess
                            },
                            {
                                month: 'October',
                                count: this.getterIPS.month.October.countExcess
                            },
                            {
                                month: 'November',
                                count: this.getterIPS.month.November.countExcess
                            },
                            {
                                month: 'December',
                                count: this.getterIPS.month.December.countExcess
                            },
                        ];
                    } else if (this.isAllInvoices) {
                        months = [
                            {
                                month: 'January',
                                count: this.getterIPS.month.January.count,
                                countExcess: this.getterIPS.month.January.countExcess,
                                countComplete: this.getterIPS.month.January.countComplete,
                            },
                            {
                                month: 'February',
                                count: this.getterIPS.month.February.count,
                                countExcess: this.getterIPS.month.February.countExcess,
                                countComplete: this.getterIPS.month.February.countComplete,
                            },
                            {
                                month: 'March',
                                count: this.getterIPS.month.March.count,
                                countExcess: this.getterIPS.month.March.countExcess,
                                countComplete: this.getterIPS.month.March.countComplete,
                            },
                            {
                                month: 'April',
                                count: this.getterIPS.month.April.count,
                                countExcess: this.getterIPS.month.April.countExcess,
                                countComplete: this.getterIPS.month.April.countComplete,
                            },
                            {
                                month: 'May',
                                count: this.getterIPS.month.May.count,
                                countExcess: this.getterIPS.month.May.countExcess,
                                countComplete: this.getterIPS.month.May.countComplete,
                            },
                            {
                                month: 'June',
                                count: this.getterIPS.month.June.count,
                                countExcess: this.getterIPS.month.June.countExcess,
                                countComplete: this.getterIPS.month.June.countComplete,
                            },
                            {
                                month: 'July',
                                count: this.getterIPS.month.July.count,
                                countExcess: this.getterIPS.month.July.countExcess,
                                countComplete: this.getterIPS.month.July.countComplete,
                            },
                            {
                                month: 'August',
                                count: this.getterIPS.month.August.count,
                                countExcess: this.getterIPS.month.August.countExcess,
                                countComplete: this.getterIPS.month.August.countComplete,
                            },
                            {
                                month: 'September',
                                count: this.getterIPS.month.September.count,
                                countExcess: this.getterIPS.month.September.countExcess,
                                countComplete: this.getterIPS.month.September.countComplete,
                            },
                            {
                                month: 'October',
                                count: this.getterIPS.month.October.count,
                                countExcess: this.getterIPS.month.October.countExcess,
                                countComplete: this.getterIPS.month.October.countComplete,
                            },
                            {
                                month: 'November',
                                count: this.getterIPS.month.November.count,
                                countExcess: this.getterIPS.month.November.countExcess,
                                countComplete: this.getterIPS.month.November.countComplete,
                            },
                            {
                                month: 'December',
                                count: this.getterIPS.month.December.count,
                                countExcess: this.getterIPS.month.December.countExcess,
                                countComplete: this.getterIPS.month.December.countComplete,
                            },
                        ];
                    } else {
                        months = [
                            {
                                month: 'January',
                                count: this.getterIPS.month.January.count
                            },
                            {
                                month: 'February',
                                count: this.getterIPS.month.February.count
                            },
                            {
                                month: 'March',
                                count: this.getterIPS.month.March.count
                            },
                            {
                                month: 'April',
                                count: this.getterIPS.month.April.count
                            },
                            {
                                month: 'May',
                                count: this.getterIPS.month.May.count
                            },
                            {
                                month: 'June',
                                count: this.getterIPS.month.June.count
                            },
                            {
                                month: 'July',
                                count: this.getterIPS.month.July.count
                            },
                            {
                                month: 'August',
                                count: this.getterIPS.month.August.count
                            },
                            {
                                month: 'September',
                                count: this.getterIPS.month.September.count
                            },
                            {
                                month: 'October',
                                count: this.getterIPS.month.October.count
                            },
                            {
                                month: 'November',
                                count: this.getterIPS.month.November.count
                            },
                            {
                                month: 'December',
                                count: this.getterIPS.month.December.count
                            },
                        ];
                    }

                    let prevMonths = months.slice(currentMonth + 1);
                    let currentMonths = months.slice(0, currentMonth + 1);
                    return _.concat(currentMonths.reverse(), prevMonths.reverse());
                } else if (this.adjustedBy === 'Week') {
                    if (this.isCompleteInvoiceOnly) {
                        return [
                            {
                                day: 'Monday',
                                count: this.getterIPS.week.Monday.countComplete,
                            },
                            {
                                day: 'Tuesday',
                                count: this.getterIPS.week.Tuesday.countComplete,
                            },
                            {
                                day: 'Wednesday',
                                count: this.getterIPS.week.Wednesday.countComplete,
                            },
                            {
                                day: 'Thursday',
                                count: this.getterIPS.week.Thursday.countComplete,
                            },
                            {
                                day: 'Friday',
                                count: this.getterIPS.week.Friday.countComplete,
                            },
                            {
                                day: 'Saturday',
                                count: this.getterIPS.week.Saturday.countComplete,
                            },
                            {
                                day: 'Sunday',
                                count: this.getterIPS.week.Sunday.countComplete,
                            },
                        ];
                    } else if (this.isExcessInvoiceOnly) {
                        return [
                            {
                                day: 'Monday',
                                count: this.getterIPS.week.Monday.countExcess,
                            },
                            {
                                day: 'Tuesday',
                                count: this.getterIPS.week.Tuesday.countExcess,
                            },
                            {
                                day: 'Wednesday',
                                count: this.getterIPS.week.Wednesday.countExcess,
                            },
                            {
                                day: 'Thursday',
                                count: this.getterIPS.week.Thursday.countExcess,
                            },
                            {
                                day: 'Friday',
                                count: this.getterIPS.week.Friday.countExcess,
                            },
                            {
                                day: 'Saturday',
                                count: this.getterIPS.week.Saturday.countExcess,
                            },
                            {
                                day: 'Sunday',
                                count: this.getterIPS.week.Sunday.countExcess,
                            },
                        ];
                    } else if (this.isAllInvoices) {
                        return [
                            {
                                day: 'Monday',
                                count: this.getterIPS.week.Monday.count,
                                countExcess: this.getterIPS.week.Monday.countExcess,
                                countComplete: this.getterIPS.week.Monday.countComplete,
                            },
                            {
                                day: 'Tuesday',
                                count: this.getterIPS.week.Tuesday.count,
                                countExcess: this.getterIPS.week.Tuesday.countExcess,
                                countComplete: this.getterIPS.week.Tuesday.countComplete,
                            },
                            {
                                day: 'Wednesday',
                                count: this.getterIPS.week.Wednesday.count,
                                countExcess: this.getterIPS.week.Wednesday.countExcess,
                                countComplete: this.getterIPS.week.Wednesday.countComplete,
                            },
                            {
                                day: 'Thursday',
                                count: this.getterIPS.week.Thursday.count,
                                countExcess: this.getterIPS.week.Thursday.countExcess,
                                countComplete: this.getterIPS.week.Thursday.countComplete,
                            },
                            {
                                day: 'Friday',
                                count: this.getterIPS.week.Friday.count,
                                countExcess: this.getterIPS.week.Friday.countExcess,
                                countComplete: this.getterIPS.week.Friday.countComplete,
                            },
                            {
                                day: 'Saturday',
                                count: this.getterIPS.week.Saturday.count,
                                countExcess: this.getterIPS.week.Saturday.countExcess,
                                countComplete: this.getterIPS.week.Saturday.countComplete,
                            },
                            {
                                day: 'Sunday',
                                count: this.getterIPS.week.Sunday.count,
                                countExcess: this.getterIPS.week.Sunday.countExcess,
                                countComplete: this.getterIPS.week.Sunday.countComplete,
                            },
                        ];
                    } else {
                        return [
                            {
                                day: 'Monday',
                                count: this.getterIPS.week.Monday.count,
                            },
                            {
                                day: 'Tuesday',
                                count: this.getterIPS.week.Tuesday.count,
                            },
                            {
                                day: 'Wednesday',
                                count: this.getterIPS.week.Wednesday.count,
                            },
                            {
                                day: 'Thursday',
                                count: this.getterIPS.week.Thursday.count,
                            },
                            {
                                day: 'Friday',
                                count: this.getterIPS.week.Friday.count,
                            },
                            {
                                day: 'Saturday',
                                count: this.getterIPS.week.Saturday.count,
                            },
                            {
                                day: 'Sunday',
                                count: this.getterIPS.week.Sunday.count,
                            },
                        ];
                    }

                } else if (this.adjustedBy === 'Quarter') {
                    let q1 = [0, 1, 2];
                    let q2 = [3, 4, 5];
                    let q3 = [6, 7, 8];
                    let q4 = [9, 10, 11];

                    let d = new Date();
                    let currentMonth = d.getMonth();

                    let result = [];

                    if (q1.includes(currentMonth)) {
                        if (this.isCompleteInvoiceOnly) {
                            result = [
                                {quarter: 'January - March', count: this.getterIPS.quarter['January - March'].countComplete},
                                {quarter: 'October - December', count: this.getterIPS.quarter['October - December'].countComplete},
                                {quarter: 'July - September', count: this.getterIPS.quarter['July - September'].countComplete},
                                {quarter: 'April - June', count: this.getterIPS.quarter['April - June'].countComplete},
                            ];
                        } else if (this.isExcessInvoiceOnly) {
                            result = [
                                {quarter: 'January - March', count: this.getterIPS.quarter['January - March'].countExcess},
                                {quarter: 'October - December', count: this.getterIPS.quarter['October - December'].countExcess},
                                {quarter: 'July - September', count: this.getterIPS.quarter['July - September'].countExcess},
                                {quarter: 'April - June', count: this.getterIPS.quarter['April - June'].countExcess},
                            ];
                        } else if (this.isAllInvoices) {
                            result = [
                                {
                                    quarter: 'January - March',
                                    count: this.getterIPS.quarter['January - March'].count,
                                    countExcess: this.getterIPS.quarter['January - March'].countExcess,
                                    countComplete: this.getterIPS.quarter['January - March'].countComplete
                                },
                                {
                                    quarter: 'October - December',
                                    count: this.getterIPS.quarter['October - December'].count,
                                    countExcess: this.getterIPS.quarter['October - December'].countExcess,
                                    countComplete: this.getterIPS.quarter['October - December'].countComplete
                                },
                                {
                                    quarter: 'July - September',
                                    count: this.getterIPS.quarter['July - September'].count,
                                    countExcess: this.getterIPS.quarter['July - September'].countExcess,
                                    countComplete: this.getterIPS.quarter['July - September'].countComplete
                                },
                                {
                                    quarter: 'April - June',
                                    count: this.getterIPS.quarter['April - June'].count,
                                    countExcess: this.getterIPS.quarter['April - June'].countExcess,
                                    countComplete: this.getterIPS.quarter['April - June'].countComplete
                                },
                            ];
                        } else {
                            result = [
                                {quarter: 'January - March', count: this.getterIPS.quarter['January - March'].count},
                                {quarter: 'October - December', count: this.getterIPS.quarter['October - December'].count},
                                {quarter: 'July - September', count: this.getterIPS.quarter['July - September'].count},
                                {quarter: 'April - June', count: this.getterIPS.quarter['April - June'].count},
                            ];
                        }

                    } else if (q2.includes(currentMonth)) {
                        if (this.isCompleteInvoiceOnly) {
                            result = [
                                {quarter: 'April - June', count: this.getterIPS.quarter['April - June'].countComplete},
                                {quarter: 'January - March', count: this.getterIPS.quarter['January - March'].countComplete},
                                {quarter: 'October - December', count: this.getterIPS.quarter['October - December'].countComplete},
                                {quarter: 'July - September', count: this.getterIPS.quarter['July - September'].countComplete},
                            ];
                        } else if (this.isExcessInvoiceOnly) {
                            result = [
                                {quarter: 'April - June', count: this.getterIPS.quarter['April - June'].countExcess},
                                {quarter: 'January - March', count: this.getterIPS.quarter['January - March'].countExcess},
                                {quarter: 'October - December', count: this.getterIPS.quarter['October - December'].countExcess},
                                {quarter: 'July - September', count: this.getterIPS.quarter['July - September'].countExcess},
                            ];
                        } else if (this.isAllInvoices) {
                            result = [
                                {
                                    quarter: 'April - June',
                                    count: this.getterIPS.quarter['April - June'].count,
                                    countExcess: this.getterIPS.quarter['April - June'].countExcess,
                                    countComplete: this.getterIPS.quarter['April - June'].countComplete
                                },
                                {
                                    quarter: 'January - March',
                                    count: this.getterIPS.quarter['January - March'].count,
                                    countExcess: this.getterIPS.quarter['January - March'].countExcess,
                                    countComplete: this.getterIPS.quarter['January - March'].countComplete
                                },
                                {
                                    quarter: 'October - December',
                                    count: this.getterIPS.quarter['October - December'].count,
                                    countExcess: this.getterIPS.quarter['October - December'].countExcess,
                                    countComplete: this.getterIPS.quarter['October - December'].countComplete
                                },
                                {
                                    quarter: 'July - September',
                                    count: this.getterIPS.quarter['July - September'].count,
                                    countExcess: this.getterIPS.quarter['July - September'].countExcess,
                                    countComplete: this.getterIPS.quarter['July - September'].countComplete
                                },
                            ];
                        } else {
                            result = [
                                {quarter: 'April - June', count: this.getterIPS.quarter['April - June'].count},
                                {quarter: 'January - March', count: this.getterIPS.quarter['January - March'].count},
                                {quarter: 'October - December', count: this.getterIPS.quarter['October - December'].count},
                                {quarter: 'July - September', count: this.getterIPS.quarter['July - September'].count},
                            ];
                        }
                    } else if (q3.includes(currentMonth)) {
                        if (this.isCompleteInvoiceOnly) {
                            result = [
                                {quarter: 'July - September', count: this.getterIPS.quarter['July - September'].countComplete},
                                {quarter: 'April - June', count: this.getterIPS.quarter['April - June'].countComplete},
                                {quarter: 'January - March', count: this.getterIPS.quarter['January - March'].countComplete},
                                {quarter: 'October - December', count: this.getterIPS.quarter['October - December'].countComplete},
                            ];
                        } else if (this.isExcessInvoiceOnly) {
                            result = [
                                {quarter: 'July - September', count: this.getterIPS.quarter['July - September'].countExcess},
                                {quarter: 'April - June', count: this.getterIPS.quarter['April - June'].countExcess},
                                {quarter: 'January - March', count: this.getterIPS.quarter['January - March'].countExcess},
                                {quarter: 'October - December', count: this.getterIPS.quarter['October - December'].countExcess},
                            ];
                        } else if (this.isAllInvoices) {
                            result = [
                                {
                                    quarter: 'July - September',
                                    count: this.getterIPS.quarter['July - September'].count,
                                    countExcess: this.getterIPS.quarter['July - September'].countExcess,
                                    countComplete: this.getterIPS.quarter['July - September'].countComplete
                                },
                                {
                                    quarter: 'April - June',
                                    count: this.getterIPS.quarter['April - June'].count,
                                    countExcess: this.getterIPS.quarter['April - June'].countExcess,
                                    countComplete: this.getterIPS.quarter['April - June'].countComplete
                                },
                                {
                                    quarter: 'January - March',
                                    count: this.getterIPS.quarter['January - March'].count,
                                    countExcess: this.getterIPS.quarter['January - March'].countExcess,
                                    countComplete: this.getterIPS.quarter['January - March'].countComplete
                                },
                                {
                                    quarter: 'October - December',
                                    count: this.getterIPS.quarter['October - December'].count,
                                    countExcess: this.getterIPS.quarter['October - December'].countExcess,
                                    countComplete: this.getterIPS.quarter['October - December'].countComplete
                                },
                            ];
                        } else {
                            result = [
                                {quarter: 'July - September', count: this.getterIPS.quarter['July - September'].count},
                                {quarter: 'April - June', count: this.getterIPS.quarter['April - June'].count},
                                {quarter: 'January - March', count: this.getterIPS.quarter['January - March'].count},
                                {quarter: 'October - December', count: this.getterIPS.quarter['October - December'].count},
                            ];
                        }
                    } else if (q4.includes(currentMonth)) {
                        if (this.isCompleteInvoiceOnly) {
                            result = [
                                {quarter: 'October - December', count: this.getterIPS.quarter['October - December'].countComplete},
                                {quarter: 'July - September', count: this.getterIPS.quarter['July - September'].countComplete},
                                {quarter: 'April - June', count: this.getterIPS.quarter['April - June'].countComplete},
                                {quarter: 'January - March', count: this.getterIPS.quarter['January - March'].countComplete},
                            ];
                        } else if (this.isExcessInvoiceOnly) {
                            result = [
                                {quarter: 'October - December', count: this.getterIPS.quarter['October - December'].countExcess},
                                {quarter: 'July - September', count: this.getterIPS.quarter['July - September'].countExcess},
                                {quarter: 'April - June', count: this.getterIPS.quarter['April - June'].countExcess},
                                {quarter: 'January - March', count: this.getterIPS.quarter['January - March'].countExcess},
                            ];
                        } else if (this.isAllInvoices) {
                            result = [
                                {
                                    quarter: 'October - December',
                                    count: this.getterIPS.quarter['October - December'].count,
                                    countExcess: this.getterIPS.quarter['October - December'].countExcess,
                                    countComplete: this.getterIPS.quarter['October - December'].countComplete
                                },
                                {
                                    quarter: 'July - September',
                                    count: this.getterIPS.quarter['July - September'].count,
                                    countExcess: this.getterIPS.quarter['July - September'].countExcess,
                                    countComplete: this.getterIPS.quarter['July - September'].countComplete
                                },
                                {
                                    quarter: 'April - June',
                                    count: this.getterIPS.quarter['April - June'].count,
                                    countExcess: this.getterIPS.quarter['April - June'].countExcess,
                                    countComplete: this.getterIPS.quarter['April - June'].countComplete
                                },
                                {
                                    quarter: 'January - March',
                                    count: this.getterIPS.quarter['January - March'].count,
                                    countExcess: this.getterIPS.quarter['January - March'].countExcess,
                                    countComplete: this.getterIPS.quarter['January - March'].countComplete
                                },
                            ];
                        } else {
                            result = [
                                {quarter: 'October - December', count: this.getterIPS.quarter['October - December'].count},
                                {quarter: 'July - September', count: this.getterIPS.quarter['July - September'].count},
                                {quarter: 'April - June', count: this.getterIPS.quarter['April - June'].count},
                                {quarter: 'January - March', count: this.getterIPS.quarter['January - March'].count},
                            ];
                        }
                    }

                    return result;
                } else if (this.adjustedBy === 'Year') {
                    let data = [];

                    if (this.isCompleteInvoiceOnly) {
                        for (let year in this.getterIPS.year) {
                            data.push(
                                {
                                    year: year,
                                    count: this.getterIPS.year[year].countComplete,
                                });
                        }
                    } else if (this.isExcessInvoiceOnly) {
                        for (let year in this.getterIPS.year) {
                            data.push(
                                {
                                    year: year,
                                    count: this.getterIPS.year[year].countExcess,
                                });
                        }
                    } else if (this.isAllInvoices) {
                        for (let year in this.getterIPS.year) {
                            data.push(
                                {
                                    year: year,
                                    count: this.getterIPS.year[year].count,
                                    countComplete: this.getterIPS.year[year].countComplete,
                                    countExcess: this.getterIPS.year[year].countExcess,
                                });
                        }
                    } else {
                        for (let year in this.getterIPS.year) {
                            data.push(
                                {
                                    year: year,
                                    count: this.getterIPS.year[year].count,
                                });
                        }
                    }

                    return data;
                }
            },
        },
        methods: {
            ...mapActions({
                actionLoadReports: 'report/loadIPSAndIVSAndAIV',
            }),
            onClick(event, context, config){
              if (this.selectedColumn === this.computedChartOptions.xaxis.categories[config.dataPointIndex] || config.dataPointIndex === -1) {
                this.selectedColumn = '';
                return;
              }
              this.selectedColumn = this.computedChartOptions.xaxis.categories[config.dataPointIndex];
              if (this.adjustedBy == 'week') {
                return;
              }
              NProgress.start();
              Axios.get('/fe/reports/invoice/details/' + _.toLower(this.adjustedBy) + '/' + _.toLower(this.selectedColumn.replace(/\s+/g, '')))
                  .then(response => {
                    this.invoiceDetailData = response.data.data;
                  })
                  .catch(error => {
                    console.log(error);

                  }).finally(() => {
                NProgress.done();
              });
            },
            getCurrentFilters() {
                let filters = '';
                if (this.isTypeExcess === false || this.isTypeComplete === false) {
                    if (this.isTypeExcess === true) {
                        filters += 'isTypeExcess;';
                    }
                    if (this.isTypeComplete === true) {
                        filters += 'isTypeComplete;';
                    }
                }
                if (this.isDisplayBreakdownYes === true) {
                    filters += 'isDisplayBreakdownYes;';
                }
                if (filters == '') {
                    return false;
                }
                return filters;
            },
            exportReport(format) {
                let token = localStorage.getItem('token');
                let filters = this.getCurrentFilters();
                if (this.isSelectedColumn) {
                    let url = appConfig.baseAPIURL;
                    url += '/fe/reports/download/invoices/details/';
                    url += _.toLower(this.adjustedBy);
                    url += '/';
                    url += _.toLower(this.selectedColumn.replace(/\\s+/g, ''));
                    url += '/';
                    url += format;
                    url += '?at=' + token;
                    if (filters) {
                        url += '&filters=' + filters;
                    }
                    console.log(url)
                    window.open(url);
                } else {
                    let type = 'ps';
                    let period = _.toLower(this.adjustedBy);
                    let value = 'count';
                    if (this.isTypeExcess && !this.isTypeComplete) {
                      value = 'countExcess'
                    } else if (!this.isTypeExcess && this.isTypeComplete) {
                      value = 'countComplete'
                    }
                    let url = appConfig.baseAPIURL + `/fe/reports/download/invoices/${type}/${period}/${value}/${format}?at=${token}`;

                    if (value === 'count' && this.isDisplayBreakdownYes === true) {
                     url+= '&displayBreakdown=yes'
                    }

                    window.open(url);
                }
                return;
            }
        },
        mounted() {
            NProgress.start();
            this.actionLoadReports();
            let self = this;
            this.intervalUpdateReports = setInterval(() => {
                if (!_.isEmpty(self.getterIPS) && !_.isEmpty(self.computedSeries) && !_.isEmpty(self.computedChartOptions) && !_.isEmpty(self.chartDataForTable)) {
                    NProgress.done();
                }
            }, 1000);
        },
        beforeDestroy() {
            clearInterval(this.intervalUpdateReports);
        },
        components: {
            "vue-apex-chart": VueApexCharts,
            Multiselect,
        },
    };
</script>
<style scoped>
  .active-filters-control {
    display: flex;
    flex-direction: column;
    width: 880px;
    position: relative;
    left: 96px;
    border: 1px solid rgba(27, 30, 56, 0.25);
  }

  .filters-checkboxes {
    padding: 10px 0 15px 15px;
    display: flex;
  }

  .filters-checkboxes-title {
    display: inline-block !important;
    width: 200px;
  }

  .filters-checkbox {
    display: flex;
    margin-right: 30px;
    display: inline-block !important;
    width: 100px;
  }
</style>
