<script>
import SearchFilter2 from "@/components/search-filter/search-filter-2.vue";
import _ from "lodash";
import {mapGetters} from "vuex";

export default {
  name: 'assign-to-modal',
  components: {
    SearchFilter2,
  },
  data: function (){
    return{
      searchAssignTo: '',
    }
  },
  methods: {
    selectedUser(v) {
      this.$emit('selected', v)
      this.$emit('close')
    },
    close() {
      this.$emit('close')
    },
  },
  computed: {
    ...mapGetters({
      getterVisibleUsers: 'visibleUsers',
    }),
    users () {
      if (!this.searchAssignTo || this.searchAssignTo == '') {
        let users = this.getterVisibleUsers.slice()
        users.unshift({
          id: null,
          name: 'None',
          groupId: null,
          group_id: null,
          isActive: true,
          isVisible: true,
          lastName: null,
          firstName: null,
          role: null,
        })
        return users
      }
      let self = this
      let filterUsers = _.filter(this.getterVisibleUsers, function (user) {
        let search = []
        search.push(_.toLower(user.name))
        return !!(search.join(',').indexOf(_.toLower(self.searchAssignTo)) > -1)
      })
      return filterUsers
    },
    computedUsers() {
      let data = _.filter(this.users, (r) => {
        return _.startsWith(_.trim(_.toLower(r.name)), _.trim(_.toLower(this.searchAssignTo)))
      })
      let result = [];
      _.forEach(data, (itm) => {
        let label = itm.name

        result.push({
          value: itm.id,
          label: label,
        })
      })
      result = _.orderBy(result, ['label'], ['asc'])
      let indexOfNone = _.findIndex(result, (v) => {return v.label == 'None'})
      if (indexOfNone !== -1) {
        let obj = _.cloneDeep(result[indexOfNone])
        result.splice(indexOfNone, 1)
        result.unshift(obj)
      }
      result = result.slice(0, 5)
      return result
    }
  }
}
</script>

<template>
  <div class="wrapper-assign-to">
    <div class="assing-to-close-btn" style="" @click="$emit('close')">Close</div>
    <search-filter2
        id="searchFilter"
        v-model="searchAssignTo"
        placeholder="Assign to"
        :limitLength="50"
        @selectAdditional="selectedUser"
        :placeholder="'Search'"
        :additionalMenu="computedUsers"
        :isIgnoreSiteAndPageHeader="true"
        :isAssignModal="true"
        :debug="false"
        height="41px"
        aria-expanded="false"
        data-toggle="dropdown"
    ></search-filter2>
  </div>
</template>

<style scoped>
.wrapper-assign-to{
  position: absolute;
  top: 0;
  z-index: 99999;
  background-color: rgba(51, 51, 51, 0.5);
  height: 100vh;
  width: 100vw;
  padding-top: 140px;
}
.wrapper-assign-to .search-filter >>> .form-control:focus{
  outline: 0 !important;
}
.wrapper-assign-to .search-filter >>> .search-filter__input{
  font-size: 13px !important;
  font-weight: 800;
  color: black;
}
.wrapper-assign-to .search-filter >>> .search-filter__box{
  border: 3px solid #5e77fd;
  border-radius: 7px;
  background-color: white;
}
.wrapper-assign-to >>> .board-item-holder .board-item{
  border: 2px solid #bebebe;
}
.wrapper-assign-to >>> .board-item-holder .board-item:hover{
  border-color: #5e77fc;
}
.wrapper-assign-to .search-filter >>> .form-control:focus{
  outline: 0 !important;
}
.wrapper-assign-to .search-filter >>> .search-filter__input{
  font-size: 13px !important;
  font-weight: 800;
  color: black;
}
.wrapper-assign-to .search-filter >>> .custom-dropdown{
  width: calc(100vw - 30px);
  top: 37px;
}
.wrapper-assign-to .search-filter >>> .custom-dropdown .tabs-nav-right-search-dropdown-item{
  border: 0;
}
.wrapper-assign-to .search-filter >>> .search-filter__box{
  border: 3px solid #5e77fd;
  border-radius: 7px;
}
.search-filter >>> input::placeholder{
  color: #bbbbc2;
}
.search-filter >>> .search-filter__box__focused{
  border: 3px solid rgba(94, 121, 255, 0.5) !important;
  box-shadow: 0 0 0 0.2rem rgb(94 121 255 / 25%) !important;
}
.search-filter >>> .search-filter__li--input{
  border-bottom: 0;
}
.search-filter >>> .search-filter__li{
  border-bottom: 0 !important;
}
.search-filter >>> .search-filter__box{
  border: 2px solid rgba(28, 31, 57, 0.25);
}
.wrapper-assign-to >>> input{
  font-weight: 700;
}
.wrapper-assign-to >>> input::placeholder{
  font-weight: 600;
}
.assing-to-close-btn{
  position: absolute;
  color: #1C1F39;
  font-weight: bold;
  line-height: 18px;
  border-radius: 3px;
  width: 60px;
  text-align: center;
  border: 1px solid #5E79FF;
  padding: 0.75rem 5px;
  font-size: 15px;
  right: 10px;
  top: 15px;
  cursor: pointer;
  background-color: #FFFFFF;
}

.site-content-mobile .wrapper-assign-to >>> .search-filter{
  padding-left: 15px !important;
  padding-right: 15px;
}

</style>
