<template>
  <div class="">
    <div class="row no-gutter header-content">
      <div class="col-12 col-md-4 chart-title">
        Estimates Values by Insurer and Non-Insurance Summary
      </div>
      <div class="col-12 col-md-6 header-right d-flex">
        <div class="navbar-nav nav dropdown dropdown-options  show inline" style="margin-left: 99px;">
          <button type="button"
                  class="btn waves-effect waves-light ex-options-button"
                  id="dropdownMenuLink1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true">
            Export
            <span class="dropdown-arrow pull-right" style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                                    <i class="fa fa-chevron-down pull-right"></i>
                                </span>
          </button>
          <div class="dropdown-menu dropdown-menu-right animated fadeInUp"
               aria-labelledby="dropdownMenuLink">
            <a class="dropdown-item text-primary p-1 clickable" @click="exportReport('csv')">.CSV</a>
            <a class="dropdown-item text-primary p-1 clickable" @click="exportReport('xlsx')">.XLS</a>
          </div>
        </div>
        <div class="navbar-nav nav dropdown dropdown-options  show inline">
          <button type="button"
                  class="btn waves-effect waves-light ex-options-button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true">
            {{adjustedBy}}
            <span class="dropdown-arrow pull-right" style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                                    <i class="fa fa-chevron-down pull-right"></i>
                                </span>
          </button>
          <div class="dropdown-menu dropdown-menu-right animated fadeInUp"
               aria-labelledby="dropdownMenuLink">
            <a class="dropdown-item text-primary p-1 clickable" @click="setAdjustedBy('Week')">Week</a>
            <a class="dropdown-item text-primary p-1 clickable" @click="setAdjustedBy('Month')">Month</a>
            <a class="dropdown-item text-primary p-1 clickable" @click="setAdjustedBy('Quarter')">Quarter</a>
            <a class="dropdown-item text-primary p-1 clickable" @click="setAdjustedBy('Year')">Year</a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isEmptyReportsData" class="mt-2 text-right chart-interval-block">
      <div>
        {{getDate}}
      </div>
    </div>
    <div class="chart-wrapper col-12 col-md-8">
<!--      <div class="chart-legend-block mt-2 report-table mb-0">-->
<!--        <div class="pr-1 chart-legend-item" v-for="(insurer, key, index) in chartDataForTableTitle" :key="key">-->
<!--          <div class='rectangle-legend' v-bind:style="{backgroundColor: colors[index]}"></div> {{key}}-->
<!--        </div>-->
<!--      </div>-->
      <vue-apex-chart
          v-if="isHasData"
          :width="widthApexchartsLegend + 800"
          height="500"
          type="bar"
          class="apexcharts-block"
          :series="computedSeries"
          :options="computedChartOptions"
      ></vue-apex-chart>
      <b-table-simple v-if="isHasData" hover responsive class="report-table rs-scroll rs-scroll--y" style="margin-left: 40px ">
        <b-thead>
          <b-tr>
            <b-th v-if="adjustedBy === 'Week'">Day</b-th>
            <b-th v-else>{{adjustedBy}}</b-th>
            <b-th v-for="(insurer, key) in chartDataForTableTitle" :key="key">{{key}}</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(month, key) in chartDataForTableBody">
            <b-td>{{key}}</b-td>
            <b-td v-for="(insurer, key) in month" :key="key">
              <div v-if="insurer.summary === 0">
                -
              </div>
              <div v-else>
                {{insurer.summary | formatMoney}}
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
<!--      <p class="no-data-text" v-if="!isHasData">No data for graph</p>-->
    </div>
  </div>
</template>

<script>
  import {appConfig} from '../../../../config'
  import Multiselect from 'vue-multiselect';
  import _ from 'lodash';
  import {mapActions, mapGetters} from "vuex";
  import moment from "moment";
  import VueApexCharts from 'vue-apexcharts'
  import accounting from "accounting";

  export default {
    name: "valuesByInsrerAndNonInsuranceSummary",
    components: {
      Multiselect,
      VueApexChart: VueApexCharts,
    },
    data() {
      return {
        widthApexchartsLegend: 500,
        adjustedBy: 'Month',
        intervalUpdateReports: null,
        months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        colors: ['#3366cc', '#dc3912', '#ff9900', '#109618', '#990099', '#0099c6', '#dd4477', '#66aa00', '#b82e2e', '#316395', '#994499', '#22aa99', '#aaaa11', '#6633cc', '#e67300', '#8b0707', '#651067', '#329262', '#5574a6', '#3b3eac', '#b77322', '#16d620', '#b91383', '#f4359e', '#9c5935', '#a9c413', '#2a778d', '#668d1c', '#bea413', '#0c5922', '#743411', '#f4359e', '#b91383', '#16d620', '#b77322', '#3b3eac', '#5574a6', '#329262', '#329262', '#651067', '#8b0707', '#e67300', '#6633cc', '#aaaa11', '#22aa99', '#994499', '#316395', '#b82e2e', '#66aa00', '#dd4477', '#0099c6', '#990099', '#109618', '#ff9900', '#dc3912', '#3366cc', '#ca24d6', '#378784', '#ab8965', '#141e7d', '#edc7c7', '#167d76', '#d5f74a', '#3022c9', '#705658'],
      }
    },
    mounted() {
      NProgress.start();
      this.actionLoadReports()
      let self = this;
      this.intervalUpdateReports = setInterval(() => {
        if (!_.isEmpty(self.getterEINS) && !_.isEmpty(self.computedReportsData) && !_.isEmpty(self.computedSeries) && !_.isEmpty(self.computedChartOptions) && !_.isEmpty(self.chartDataForTableBody)&& !_.isEmpty(self.chartDataForTableTitle)) {
          NProgress.done();
        }
      }, 1000);
    },
    beforeDestroy() {
      clearInterval(this.intervalUpdateReports);
    },
    methods: {
      ...mapActions({
        actionLoadReports: 'report/loadEINSAndEVI',
      }),
      setAdjustedBy(v) {
        NProgress.start()
        setTimeout(() => {
          this.adjustedBy = v;
          this.$nextTick(() => {
            NProgress.done()
          })
        }, 10)
      },
      formatMoneyFunc(value) {
        return accounting.formatMoney(value)
      },
      sortObject(obj) {
        let insurers = [];
        let filteredInsurers = {};
        for (let insurer in obj) {
          insurers.push({
            name: insurer,
            value: obj[insurer],
          })
        }
        insurers = _.sortBy(insurers, [function(o) {return o.name.trim().toLowerCase()}]);
        _.forEach(insurers, function (insurer) {
          filteredInsurers[insurer.name] = insurer.value
        })
        return filteredInsurers
      },
      sortData(type) {
        let dataWithoutNonInsurance = {};
        let dataWithoutNonInsuranceFiltered = {};
        let dataAllFiltered = {};

        for (let nameOfData in this.getterEINS[type]) {
          let filteredInsurers = _.pickBy(this.getterEINS[type][nameOfData], function (value, key) {
            return key !== 'Non-Insurance'
          })
          dataWithoutNonInsurance[nameOfData] = filteredInsurers;
        }

        for (let nameOfData in dataWithoutNonInsurance) {
          dataWithoutNonInsuranceFiltered[nameOfData] = this.sortObject(dataWithoutNonInsurance[nameOfData])
        }

        let isHasNonInsurance = true;

        for (let nameOfData in dataWithoutNonInsuranceFiltered) {
          if (!this.getterEINS[type][nameOfData]['Non-Insurance']) {
            isHasNonInsurance = false;
          }
          dataAllFiltered[nameOfData] = _.assign(
            {'Non-Insurance': this.getterEINS[type][nameOfData]['Non-Insurance']},
            dataWithoutNonInsuranceFiltered[nameOfData]
          )
        }
        if (isHasNonInsurance) {
          return dataAllFiltered
        }
        return dataWithoutNonInsuranceFiltered;
      },
      exportReport(format) {
        let token = localStorage.getItem('token');
        let type = 'insurers';
        let period = _.toLower(this.adjustedBy);
        let value = 'summary';
        window.open(appConfig.baseAPIURL + `/fe/reports/download/estimates/${type}/${period}/${value}/${format}?at=${token}`);
        return;
      }
    },
    computed: {
      ...mapGetters({
        getterEINS: 'report/getEINS',
      }),
      computedSeries() {
        let data = [];
        let self = this;
        _.forEach(this.chartDataForTableTitle, (insurer, key) => {
          data.push({
            name: key,
            data: []
          })
          let index = _.findIndex(data, item => {
            return item.name === key;
          });
          _.forEach(self.computedChartOptions.xaxis.categories, type => {
            if (self.chartDataForTableBody && self.chartDataForTableBody[type] && self.chartDataForTableBody[type][key] && self.chartDataForTableBody[type][key].summary) {
              data[index].data.push(self.chartDataForTableBody[type][key].summary)
            } else {
              data[index].data.push(0)
            }
          })
        })

        let dataCopy = _.cloneDeep(data)
        if (this.adjustedBy === 'Month') {
          _.forEach(dataCopy, (d) => {
            d.data.slice(6, 12)
          })
          return dataCopy
        }

        return data;
      },
      currentMonth() {
        return new Date().getMonth();
      },
      computedChartOptions() {
        let self = this
        let options = {
          chart: {
            id: 'vuechart-example',
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          colors: ['#3366cc', '#dc3912', '#ff9900', '#109618', '#990099', '#0099c6', '#dd4477', '#66aa00', '#b82e2e', '#316395', '#994499', '#22aa99', '#aaaa11', '#6633cc', '#e67300', '#8b0707', '#651067', '#329262', '#5574a6', '#3b3eac', '#b77322', '#16d620', '#b91383', '#f4359e', '#9c5935', '#a9c413', '#2a778d', '#668d1c', '#bea413', '#0c5922', '#743411', '#f4359e', '#b91383', '#16d620', '#b77322', '#3b3eac', '#5574a6', '#329262', '#329262', '#651067', '#8b0707', '#e67300', '#6633cc', '#aaaa11', '#22aa99', '#994499', '#316395', '#b82e2e', '#66aa00', '#dd4477', '#0099c6', '#990099', '#109618', '#ff9900', '#dc3912', '#3366cc', '#ca24d6', '#378784', '#ab8965', '#141e7d', '#edc7c7', '#167d76', '#d5f74a', '#3022c9', '#705658'],
          xaxis: {
            categories: []
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return self.formatMoneyFunc(value)
              }
            },
          },
          legend: {
            showForNullSeries: false,
            position: 'right',
            width: this.widthApexchartsLegend,
            height: 500,
          },

          tooltip: {
            marker: {show: true},
            enabled: true,
            y: {
              title: {
                formatter: (val) => {
                  return val + ': '
                },
              },
            },
            style: {
              fontSize: '13px',
              fontFamily: 'Nunito Sans',
              fontWeight: 'bold',
            },
          },
          dataLabels: {
            enabled: false,
          },
        }
        let result = [];
        if (this.adjustedBy === 'Month') {
          let prevMonths = [];
          let self = this;
          _.forEach(this.months, function (item) {
            if (self.months.indexOf(item) > self.currentMonth) {
              prevMonths.push(item)
            }
          })

          let currentMonths = [];
          _.forEach(self.months, function (item) {
            let index = self.months.indexOf(item)
            if (index <= self.currentMonth) {
              currentMonths.push(item)
            }
          });

          _.forEach(prevMonths, function (item) {
            result.push(item)
          })
          _.forEach(currentMonths, function (item) {
            result.push(item)
          });
        } else if (this.adjustedBy === 'Week') {
          result = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
        } else if (this.adjustedBy === 'Quarter') {
          let q1 = 'January - March';
          let q2 = 'April - June';
          let q3 = 'July - September';
          let q4 = 'October - December';

          let i1 = [0, 1, 2];
          let i2 = [3, 4, 5];
          let i3 = [6, 7, 8];
          let i4 = [9, 10, 11];

          if (i1.includes(this.currentMonth)) {
            result = [
              q2,
              q3,
              q4,
              q1
            ]
          } else if (i2.includes(this.currentMonth)) {
            result = [
              q3,
              q4,
              q1,
              q2,
            ]
          } else if (i3.includes(this.currentMonth)) {
            result = [
              q4,
              q1,
              q2,
              q3,
            ]
          } else if (i4.includes(this.currentMonth)) {
            result = [
              q1,
              q2,
              q3,
              q4,
            ]
          }
        } else if (this.adjustedBy === 'Year') {
          for (let key in this.computedReportsData.year) {
            result.push(key);
          }
        }
        options.legend.width = window.innerWidth - 1035
        this.widthApexchartsLegend = window.innerWidth - 1035
        options.xaxis.categories.splice(0, options.xaxis.categories.length);
        _.forEach(result, item => {
          options.xaxis.categories.push(item);
        })

        if (this.adjustedBy === 'Month') {
          options.xaxis.categories = options.xaxis.categories.slice(6, 12)
        }

        return options;
      },
      getDate() {
        let lastIndex = this.computedChartOptions.xaxis.categories.length - 1;
        if (this.isEmptyReportsData || _.isEmpty(this.computedReportsData)) {
          return  '';
        }
        if (this.adjustedBy === "Week") {
          let monday = moment().startOf('isoweek').toDate();
          let sunday   = moment().endOf('isoweek').toDate();

          let mDay = String(monday.getDate()).padStart(2, '0');
          let mMonth = String(monday.getMonth() + 1).padStart(2, '0');
          let mYear = monday.getFullYear();

          let mResult = mDay + '/' + mMonth + '/' + mYear;

          let sDay = String(sunday.getDate()).padStart(2, '0');
          let sMonth = String(sunday.getMonth() + 1).padStart(2, '0');
          let sYear = sunday.getFullYear();

          let sResult = sDay + '/' + sMonth + '/' + sYear;

          return 'Mon' + ' ' + mResult + ' - ' + 'Sun ' + sResult;
        } else if (this.adjustedBy === 'Month') {
          let firstMonth = this.computedChartOptions.xaxis.categories[0];
          let lastMonth = this.computedChartOptions.xaxis.categories[lastIndex];
          let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

          if (lastMonth === 'December') {
            let y1 = new Date().getFullYear();

            return  firstMonth + ' ' + y1 + ' - ' + lastMonth + ' ' + y1;
          }

          if (months.indexOf(firstMonth) > months.indexOf(lastMonth)) {
            let y2 = new Date().getFullYear();
            let y1 = y2 - 1;

            return  firstMonth + ' ' + y1 + ' - ' + lastMonth + ' ' + y2;
          }
        } else if (this.adjustedBy === 'Quarter') {
          let firstQuarter = this.computedChartOptions.xaxis.categories[0];
          let lastQuarter = this.computedChartOptions.xaxis.categories[lastIndex];
          let quarters = ['January - March', 'April - June', 'July - September', 'October - December']

          if (lastQuarter === 'October - December') {
            let y1 = new Date().getFullYear();

            return  firstQuarter + ' ' + y1 + ' - ' + lastQuarter + ' ' + y1;
          }

          if (quarters.indexOf(firstQuarter) > quarters.indexOf(lastQuarter)) {
            let y2 = new Date().getFullYear();
            let y1 = y2 - 1;

            return  firstQuarter + ' ' + y1 + ' to ' + lastQuarter + ' ' + y2;
          }
        } else if (this.adjustedBy === 'Year') {
          let firstYear = this.computedChartOptions.xaxis.categories[0];
          let lastYear = this.computedChartOptions.xaxis.categories[lastIndex];

          return firstYear + ' - ' + lastYear;
        }
      },
      isEmptyReportsData() {
        return _.isEmpty(this.getterEINS);
      },
      isHasData() {
        if (this.isEmptyReportsData) {
          return false;
        }
        if (this.adjustedBy === 'Week') {
          let isEmptyWeek = true;
          for (let day in this.getterEINS.week) {
            if (!_.isEmpty(this.getterEINS.week[day])) {
              isEmptyWeek = false;
              break
            }
          }
          return !this.isEmptyReportsData && !isEmptyWeek;
        } else if (this.adjustedBy === 'Year') {
          let isEmptyYear = true;
          for (let year in this.getterEINS.year) {
            if (!_.isEmpty(this.getterEINS.year[year])) {
              isEmptyYear = false;
              break
            }
          }
          return !this.isEmptyReportsData && !isEmptyYear;
        } else if (this.adjustedBy === 'Month') {
          let isEmptyMonth = true;
          for (let month in this.getterEINS.month) {
            if (!_.isEmpty(this.getterEINS.month[month])) {
              isEmptyMonth = false;
              break
            }
          }
          return !this.isEmptyReportsData && !isEmptyMonth;
        } else if (this.adjustedBy === 'Quarter') {
          let isEmptyQuarter = true;
          for (let quarter in this.getterEINS.quarter) {
            if (!_.isEmpty(this.getterEINS.quarter[quarter])) {
              isEmptyQuarter = false;
              break
            }
          }
          return !this.isEmptyReportsData && !isEmptyQuarter;
        }
      },
      computedReportsData() {
        if (this.isEmptyReportsData) {
          return null;
        }
        return {
          week: this.sortData('week'),
          month: this.sortData('month'),
          year: this.sortData('year'),
          quarter: this.sortData('quarter')
        }
      },
      chartDataForTableTitle() {
        if (this.isEmptyReportsData || _.isEmpty(this.computedReportsData)) {
          return  null;
        }
        if (this.adjustedBy === 'Month') {
          return this.computedReportsData.month.January
        } else if (this.adjustedBy === 'Week') {
          return this.computedReportsData.week.Monday
        } else if (this.adjustedBy === 'Year') {
          return this.computedReportsData.year['2021']
        } else if (this.adjustedBy === 'Quarter') {
          return this.computedReportsData.quarter['January - March']
        }
      },
      chartDataForTableBody() {
        if (this.isEmptyReportsData || _.isEmpty(this.computedReportsData)) {
          return  null;
        }
        let finalResult = null
        if (this.adjustedBy === 'Month') {
          let d = new Date();
          let currentMonth = d.getMonth();
          let months = ["January","February","March","April","May","June","July","August","September","October","November","December"];

          let prevMonths = months.slice(currentMonth + 1);
          let currentMonths = months.slice(0, currentMonth + 1);
          prevMonths = prevMonths.reverse();
          currentMonths = currentMonths.reverse();
          let filteredMonths = _.concat(currentMonths, prevMonths);

          let result = {};
          let self = this;

          _.forEach(filteredMonths, function (item) {
            result[item] = self.computedReportsData.month[item];
          })

          finalResult = result
        } else if (this.adjustedBy === 'Week') {
          finalResult = this.computedReportsData.week
        } else if (this.adjustedBy === 'Year') {
          finalResult = this.computedReportsData.year
        } else if (this.adjustedBy === 'Quarter') {
          let q1 = this.computedReportsData.quarter['January - March'];
          let q2 = this.computedReportsData.quarter['October - December'];
          let q3 = this.computedReportsData.quarter['July - September'];
          let q4 = this.computedReportsData.quarter['April - June'];

          let i1 = [0, 1, 2];
          let i2 = [3, 4, 5];
          let i3 = [6, 7, 8];
          let i4 = [9, 10, 11];

          let d = new Date();
          let currentMonth = d.getMonth();

          let result = [];

          if (i1.includes(currentMonth)) {
            result = {
              'January - March': q1,
              'October - December': q2,
              'July - September': q3,
              'April - June': q4,
            }
          } else if (i2.includes(currentMonth)) {
            result = {
              'April - June': q4,
              'January - March': q1,
              'October - December': q2,
              'July - September': q3,
            }
          } else if (i3.includes(currentMonth)) {
            result = {
              'July - September': q3,
              'April - June': q4,
              'January - March': q1,
              'October - December': q2,
            }
          } else if (i4.includes(currentMonth)) {
            result = {
              'October - December': q2,
              'July - September': q3,
              'April - June': q4,
              'January - March': q1
            }
          }
          finalResult = result
        }

        _.forEach(finalResult, (value, key) => {
          let r = 0
          _.forEach(value, (itm) => {
            r += Number(itm.summary)
          })
          finalResult[key].Total = {
            summary: r
          }
        })
        return finalResult
      }
    }
  }
</script>

<style scoped>
  .report-table {
    width: calc(100vw - 280px) !important;
  }

  @media screen and (max-width: 767px) {
    .report-table {
      width: calc(100vw - 30px) !important;
    }
  }

  .chart-legend-block {
    display: flex;
    flex-wrap: wrap;
    width: 850px;
    margin-left: 120px;
  }

  .chart-legend-item {
    display: flex;
    align-items: center;
    font-size: 10px;
  }

  .rectangle-legend {
    width: 20px !important;
    height: 10px !important;
    margin-right: 3px;
  }
  .apexcharts-block >>> .apexcharts-legend{
    flex-wrap: wrap;
    flex-direction: row;
    overflow: auto;
    align-content: flex-start;
    padding-bottom: 40px;
    scrollbar-width: thin;
  }
  .apexcharts-block >>> .apexcharts-legend .apexcharts-legend-series{
    min-height: 30px;
    max-height: 50px;
  }
</style>
