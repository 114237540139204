<template>
  <div style="margin-top: 15px; padding-left: 15px" class="card row no-gutter">
    <div class="col-lg-5 col-12 ">
      <div class="card-header bg-navyblue">
        <strong>Estimage</strong>
      </div>

      <div class="card-block bg-white settings-integrations-estimage">

        <div class="form-group row no-gutter">
          <p>Let’s you manage Repairshop integration with your business Estimage account.</p>

          <h5 style="margin: 30px 0 20px; font-weight: bold;">Estimage – Windows PC</h5>

          <div class="row no-gutter rs-toggle">
            <label class="col-sm-3 description col-form-label"
                   for="ps-enabled-input">
              Integration Status
              <p class="lable-text">Displays your Estimage Windows PC <br>integration activation status.<br>(Managed by Repair-shop Support)</p>
            </label>
            <div class="col-sm-9">
              <div class="d-flex">
                <toggle-button
                    class="mb-0 rs-toggle__button col-form-label"
                    v-model="value.estimage.integrationStatus"
                    color="#5E79FF"
                    :disabled="!value.estimage.integrationStatusBySupport"
                    :width="40"
                    :sync="true"
                    style="opacity: 1;"
                    :labels="false"/>
                <span class="form-text text-active d-inline-block col-form-label mt-0 ml-0-25" style="line-height: 22px;">
                  <template v-if="value.estimage.integrationStatus">Active</template>
                  <template v-else style="align-items: center;">Not Active</template>
                </span>
              </div>
            </div>
          </div>
          <div class="row no-gutter">
            <label for="partssearch-api-input"
                   tabindex="0"
                   class="col-sm-3 description col-form-label pr-2">Service Status
              <p class="lable-text" style="padding-right: 50px">Windows Estimage Configurator <br>Service heartbeat status.</p>
            </label>
            <div>
              <div class="mb-2 mt-1 d-flex col-sm-9 col-form-label pr-2 pl-0" style="align-items: center; flex-wrap: wrap;">

                <template v-if="programRunning">
                  <span class="circle green mr-1 mb-0"></span>
                  <p class="mb-0">Running on current PC</p>
                </template>
                <template v-else class="pb-1 mb-0">
                  <span class="circle red mr-1 mb-0"></span>
                  <p class="mb-0">Not Running</p>
                </template>
              </div>
            </div>
          </div>

          <div class="row no-gutter">
            <label for="partssearch-api-input"
                   tabindex="0"
                   class="col-sm-3 description col-form-label">Local API Key
              <p class="lable-text">Unique identifier for your local PC <br>and Estimage</p>
            </label>
            <div class="col-sm-9">
              <input id="partssearch-api-input"
                     class="form-control"
                     :readonly="true || !isStatusActive"
                     placeholder="API Key"
                     v-model="apiKey">
            </div>
          </div>

          <div class="row no-gutter">
            <label for="partssearch-api-input"
                   tabindex="0"
                   class="col-sm-3 description col-form-label">All Connected PCs
              <p class="lable-text">Lists the PC name that have <br>Estimage configurator installed linked <br>to your Repair-shop account.</p>
            </label>
            <div v-if="pcs.length" class="col-sm-9 estimage-connections">
              <span v-for="pc in pcs" style="height: auto; min-height: 32px; word-wrap: break-word;" class="form-control estimage-connection-pc">{{pc.pc_name}}
                <span v-if="currentPcName === pc.pc_name && pc.current">(Your Local PC)</span>
                <span title="Remove PC from the list" @click="removePc(pc.id)" class="cross-for-pc">×</span>
              </span>
            </div>
          </div>

          <div class="row no-gutter rs-toggle">
            <label class="col-sm-3 description col-form-label"
                   for="ps-enabled-input">
              Windows Program
              <p class="lable-text">Download the Estimate Configurator to <br>Connect your PC to your Repair-Shop <br>account.</p>
            </label>
            <div class="col-sm-9">
              <div class="mt-1" style="display: inline-table">
                <a :href="downloadUrl" class="btn btn-primary pull-right">Download</a>
                <p style="margin-top: 46px;">Version {{value.estimage.configuratorExeVersion}}</p>
              </div>
            </div>
          </div>

          <div class="estimage-line"></div>

          <h5 style="margin: 30px 0 20px; font-weight: bold;">Estimage – Cloud</h5>

          <div class="row no-gutter rs-toggle">
            <label class="col-sm-3 description col-form-label"
                   for="ps-enabled-input">
              Integration Status
              <p class="lable-text">Displays your Estimage Cloud <br>integration activation status.<br>(Managed by Repair-shop Support)</p>
            </label>
            <div class="col-sm-9">
              <div class="d-flex">
                <toggle-button
                    class="mb-0 rs-toggle__button col-form-label"
                    color="#5E79FF"
                    v-model="value.estimage.cloudIntegrationStatus"
                    :disabled="!value.estimage.cloudIntegrationStatusBySupport"
                    :sync="true"
                    :width="40"
                    :labels="false"/>
                <span class="form-text text-active d-inline-block col-form-label mt-0 ml-0-25" style="line-height: 22px;">
                  <template v-if="value.estimage.cloudIntegrationStatus">Active</template>
                  <template v-else style="align-items: center;">Not Active</template>
                </span>
              </div>
            </div>
          </div>


          <div class="col-sm-8 col-8 float-sm-right">
            <a v-if="true" href="#" class="btn btn-primary" @click.prevent="connectToEstimage">Connect to Estimage</a>
            <a v-else href="#" class="btn btn-danger btn-sm" @click.prevent="disconnectFromEstimage">Disconnect</a>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {appConfig} from "../../../config";
import Axios from "axios";
import {mapGetters} from "vuex";

export default {
    name: "Estimage",
    props: {
      value: {}
    },
    data() {
      return {
        programRunning: false,
        obtainingApiKey: false,
        estimageConfiguratorStatus: true,
        pcs: [],
        currentPcName: "",
        estimageApiKey: null,
        checkProgramInterval: 5000,
        checkConnectedPcsInterval: 10000,
        checkProgramIntervalId: null,
        checkConnectedPcsIntervalId: null,
      }
    },
    computed: {
      ...mapGetters({
        isStatusActive: 'isStatusActive',
      }),
      apiKey(){
        if(!this.programRunning){
          return 'Service not running'
        }
        if(this.obtainingApiKey){
          return 'Setting up new API-Key...'
        }
        if(this.estimageApiKey){
          return this.estimageApiKey;
        }
        return 'No API-Key!'
      },
      downloadUrl: function () {
        return appConfig.baseAPIURL + '/test/estimageConfiguratorDownload';
      }
    },
    methods: {
      connectToEstimage() {
        let wnd = window.open(`${appConfig.baseAPIURL}/settings/xero-oauth-start`, 'xero-auth-window', 'width=800,height=600,location=on');
        window.addEventListener('message', e => {
          console.log(e);
          let data = e.data;
          if(data && typeof data !== "string") return;
          if(data && data.source === 'vue-devtools-proxy') return;
          if(data && data.source === 'vue-devtools-backend') return;
          wnd.close();
          data = JSON.parse(data);
          console.log(data);

          Axios.post(`/fe/settings/set-xero-auth`, data)
              .then(response => {
                if (response.data && response.data._status) {
                  this.$store.dispatch('loadVendorInfo', {}, {root: true});
                  this.loadXeroAuthData();
                }
              })
              .catch(e => console.error(e));

        });
      },
      disconnectFromEstimage() {
        if (!this.isStatusActive) {
          return;
        }
        Axios.post(`/fe/settings/remove-xero-auth`)
            .then(response => {
              if (response.data && response.data._status) {
                this.$store.dispatch('loadVendorInfo', {}, {root: true});
              }
            })
            .catch(e => console.error(e));
      },
      removePc(pc_id) {
        if (!confirm("Are you sure?")) {
          return;
        }
        Axios.post('/test/removeConnectedPc', {
          pcId: pc_id
        })
            .then(res => {
              if (!res || !res.data || !res.data.status) {
                console.log(res.data);
              }
              this.getConnectedPcs();
            }).catch(er => {
          console.log(er);
        });
      },
      checkProgramStatus() {
        try {
          Axios.get('http://localhost:8899', {
            timeout: 3000,
            withCredentials: false,
          })
              .then(res => {
                if (res.data && res.data.Status) {
                  this.programRunning = (!!res.data.ApiKey) || res.data.NeedPinCode;
                  this.estimageApiKey = res.data.ApiKey;
                  this.currentPcName = res.data.PcName;
                  this.obtainingApiKey = res.data.NeedPinCode;
                  if (res.data.NeedPinCode) {
                    this.getNewPincode();
                  }
                }
              }).catch(er => {
            this.programRunning = false;
          });
        } catch (e) {
          console.warn(e);
        }
      },
      getConnectedPcs() {
        Axios.post('/test/getConnectedPcs', {
          currentApiKey: this.estimageApiKey
        })
            .then(res => {
              if (res.data && res.data.status) {
                this.pcs = res.data.pcs;
              }
            }).catch(er => {
          console.log(er);
        });
      },
      getNewPincode() {
        Axios.get('/test/getNewPin', {})
            .then(res => {
              if (res.data && res.data.status) {
                console.log("New PIN %o", res.data);

                Axios.post('http://localhost:8899/setPin', JSON.stringify({
                  pin: res.data.pin
                }), {
                    withCredentials: false,
              }).then(res => {
                      if (res.data && res.data.Status) {
                        console.log("PIN has been sent");
                      }
                    }).catch(er => {
                  console.log(er);
                });

              }
            }).catch(er => {
          console.log(er);
        });
      }
    },
    mounted () {
      this.$nextTick(function (){
        this.checkProgramIntervalId = window.setInterval(() => {
          this.checkProgramStatus();
        }, this.checkProgramInterval);
        this.checkProgramStatus();

        this.checkConnectedPcsIntervalId = window.setInterval(() => {
          this.getConnectedPcs();
        }, this.checkConnectedPcsInterval);
        this.getConnectedPcs();
      });
    },
    beforeDestroy() {
      console.log('Program interval #%s', this.checkProgramIntervalId);
      if (this.checkProgramIntervalId != null) {
        clearInterval(this.checkProgramIntervalId);
        this.checkProgramIntervalId = null;
      }
      console.log('PCs interval #%s', this.checkConnectedPcsIntervalId);
      if (this.checkConnectedPcsIntervalId != null) {
        clearInterval(this.checkConnectedPcsIntervalId);
        this.checkConnectedPcsIntervalId = null;
      }
    },
}
</script>

<style scoped>
.position-elements-estimage {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.cross-for-pc {
  color: #f18181;
  font-size: 22px;
  cursor: pointer;
  position: absolute;
  right: 0.75rem;
  top: 0.5rem;
  display: none;
  font-weight: bolder;
}
.estimage-connection-pc:hover .cross-for-pc {
  display: inline-block;
}
.estimage-connection-pc .cross-for-pc:hover {
  display: inline-block;
  color: #D43935;
}
.circle {
  display: inline-block;
  border-radius: 999px;
  width: 16px;
  height: 16px;
}

.circle.green {
  background: #02D43E;
}

.circle.red {
  background: #D43935;
}

.circle.blue {
  background: #3E70C9;
}

.pc-item-holder {
  position: relative;
}

.pc-item-holder .remove-pc-button {
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  height: 14px;
  width: 14px;
  color: #D43935;
  opacity: 0;
  transition: opacity 1s;
}

.pc-item-holder .remove-pc-button:hover {
  color: #D43935;
  cursor: pointer;
}

.pc-item-holder:hover .remove-pc-button {
  transition: opacity .3s;
  opacity: 1;
}

.lable-text {
  color: rgba(0, 0, 0, 0.5);
  padding-right: 10px;
  margin-top: 5px;
}

.estimage-line {
  width: 100%;
  height: 10px;
  right: 0;
  display: block;
  position: absolute;
  background: #e3e4e7;
}

.estimage-connections {
  border: 1px solid rgba(28, 31, 57, 0.25);
  border-radius: 3px;
  padding: 3px !important;
}

.estimage-connection-pc {
  cursor: default;
  opacity: 1 !important;
  margin-bottom: 2px;
  background: #f2f2f2 !important;
  color: #7f7f7f;
  position: relative;
}

@media (min-width: 576px) {
    .settings-integrations-estimage .col-sm-9 {
      width: 33% !important
    }
    .settings-integrations-estimage .col-with-active-btn {
      width: 33% !important
    }
    .settings-integrations-estimage .description {
      width: 39%;
    }
    .col-with-active-btn {
      padding-top: 16px;
    }
    .col-sm-9 {
      width: 50%;
    }

  }
  @media (min-width: 992px) {
    .settings-integrations-estimage .description {
      width: 67%;
    }
    .settings-integrations-estimage .left-col {
      width: 50%;
    }
    .col-with-active-btn {
      padding-top: 16px;
    }
  }
</style>
