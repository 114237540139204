import { render, staticRenderFns } from "./TheEstimates.vue?vue&type=template&id=0196708f&scoped=true"
import script from "./TheEstimates.vue?vue&type=script&lang=js"
export * from "./TheEstimates.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0196708f",
  null
  
)

export default component.exports