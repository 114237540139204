<template>
  <div class="box-block bg-white estimates">
    <tabs :options="{useUrlFragment: false, defaultTabHash: 'estimates-all' }">
      <tab name="All" id="estimates-all" :class-name="'tab-content-table'">
        <div class="pagination-block-bottom">
          <b-table
              class="rs-scroll rs-scroll--y noselect"
              ref="tableEstimates"
              responsive
              hover
              :items="computedEstimatesAll"
              :fields="fields"
              :filter="filter"
              :filter-function="filterForTable"
              :current-page="getterEstimatesAllCurrentPage"
              :per-page="getterEstimatesPerPage"
              :sort-by="getterSortBy"
              :sort-desc="getterSortDesc"
              @sort-changed="setSortByAndSortDesc"
              @filtered="onAllEstimatesFiltered"
              @row-clicked="onEstimatesRowClick"
              @row-contextmenu="openMenuEstimates2"
          >
            <template v-slot:cell(estimate_number)="data">
              <div class="estimate-number semi-bold">{{ data.item.estimate_number }}</div>
            </template>
            <template v-slot:cell(customer_name)="data">
              <div class="semi-bold">{{ data.item.customer_name }}</div>
            </template>
            <template v-slot:cell(rego_number)="data">
              <div class="semi">{{ data.item.rego_number }}</div>
            </template>
            <template v-slot:cell(make)="data">
              <div class="semi">{{ data.item.make }}</div>
            </template>
            <template v-slot:cell(model)="data">
              <div class="semi">{{ data.item.model }}</div>
            </template>
            <template v-slot:cell(insurer)="data">
              <div class="semi" :style="getInsurerNameStyle(data.item.insurerCardColorId)">{{ data.item.insurer_name }}</div>
            </template>
            <template v-slot:cell(created_on)="data">
              <div class="semi">{{ data.item.created_on }}</div>
            </template>
            <template v-slot:cell(priority)="data">
                  <span v-if="data.item.priority === 'High'" style="color: #FF5E5E">
                    High
                  </span>
              <span v-else-if="data.item.priority === 'Medium'" style="color: #FFDB00">
                    Medium
                  </span>
              <span v-else-if="data.item.priority === 'Low'" style="color: #18D06B">
                    Low
                  </span>
              <span v-else-if="data.item.priority === 'OnHold'" style="color: #419abe">
                    On Hold
                  </span>

            </template>
            <template v-slot:cell(amount)="data">
              <div class="semi">{{ data.item.amount | formatMoney }}</div>
            </template>
            <template v-slot:cell(assignedTo)="data">
              <div>{{
                  data.item.assignedTo && data.item.assignedTo.id ? data.item.assignedTo.name :
                      '&nbsp'
                }}
              </div>
            </template>
            <template v-slot:cell(insurance)="data">
              <notification-cell class="row no-gutter" :item="data.item"
                                 :name="`All-${data.item.card_id}`"/>
            </template>
            <template v-slot:cell(status)="data">
              <p class="status-header">{{ data.item.status }}</p>
              <p class="status-count">
                                      <span>
                                          {{ data.item.ceStatus }} to {{ ceStatuses.length }}
                                      </span>
              </p>
              <div class="progress">
                <div class="bar" :style="{'width':`${data.item.ceStatusPercentage}%`}">
                </div>
              </div>
            </template>

          </b-table>
        </div>
        <block-pagination
            :role="'estimates'"
            :count="getterAllEstimates.length"
            :countFiltered="filter ? estimatesAllFiltered : computedEstimatesAll.length"
            :currentPage="getterEstimatesAllCurrentPage"
            @changedCurrentPage="value => $store.commit('estimate/setEstimatesAllCurrentPage', value)"
            :perPage="getterEstimatesPerPage"
            @changedPerPage="value => $store.commit('estimate/setEstimatesPerPage', value)"
        >
        </block-pagination>
      </tab>
      <template slot="nav-item-center">
        <div v-if="!isMobile" class="btn-group cards-view-button cards-view-button-center" role="group">
          <button type="button" class="btn btn-primary active">Table</button>
          <button @click="$emit('setViewSnapshot')" type="button" class="btn">Performance Snapshot</button>
        </div>
      </template>
      <template slot="nav-item-right">
        <div class="tabs-nav-right-search tabs-nav-right-search-font-weight" style="min-width: 290px !important">
          <search-filter
              type="estimates"
              v-model="searchFilter"
              class="search-filter"
              :filters="computedSearchFilters"
              :filterValue="filter"
              :tokenCopy="getterToken"
              @change="onSearchFilterChange"
              @updateToken="value => this.$store.commit('estimate/setToken', value)"
              :debug="false"
              height="41px"
              aria-expanded="false"
              data-toggle="dropdown"
              :optionsMultiplyLimit="searchFilterObj && searchFilterObj.hasOwnProperty('status') ? 4: 2"
              :isMyltipleOptions="true"
          />
        </div>
      </template>
    </tabs>
    <vue-context ref="rightClickMenu" @open="contextOpen" @close="contextClose">
      <ul v-if="scope && scope.data && scope.data.item"
          slot-scope="scope" ref="contextMenuForEstimates">
        <li v-if="scope && scope.data && scope.data.item && scope.data.item.activity && (
                          (scope.data.item.activity.Assignment && scope.data.item.activity.Assignment.from)
                          || (scope.data.item.activity.External && scope.data.item.activity.External.date)
                          || (scope.data.item.activity.Email && scope.data.item.activity.Email.date)
                          || (scope.data.item.activity.Message && scope.data.item.activity.Message.from)
                      )"
            @click="markAsRead(scope.data.item)"
        >
          Mark As Read
        </li>
        <li v-if="false" @click="acceptORMJob(scope.data.item.card_id)">
          Accept ORM JOB
        </li>

        <li v-if="false" @click="openDeclineModal(scope.data.item.card_id)">
          Decline ORM Job
        </li>
        <li>
          Open
          <i class="icon-right bx bx-chevron-right"></i>
          <ul type="none" class="subContext set-estimate-priority-list">
            <li @click="openEstimateInNewTab(scope.data.item.estimate_id)">
              in New Tab
            </li>
            <li @click="openEstimateInNewWindow(scope.data.item.estimate_id)">
              in New Window
            </li>
          </ul>

        </li>
        <li>
          Set Priority
          <i class="icon-right bx bx-chevron-right"></i>
          <ul type="none" class="subContext set-estimate-priority-list">
            <li class="d-flex" @click="setEstimatePriority(scope.data.item, 'High')"><span
                style="margin-right: 10px; margin-top: 1px;" class="status-color high-color"></span>High Priority
            </li>
            <li class="d-flex" @click="setEstimatePriority(scope.data.item, 'Medium')"><span
                style="margin-right: 10px; margin-top: 1px;" class="status-color medium-color"></span>Medium
              Priority
            </li>
            <li class="d-flex" @click="setEstimatePriority(scope.data.item, 'Low')"><span
                style="margin-right: 10px; margin-top: 1px;" class="status-color low-color"></span>Low Priority
            </li>
            <li class="d-flex" @click="setEstimatePriority(scope.data.item, 'OnHold')"><span
                style="margin-right: 10px; margin-top: 1px;" class="status-color on-hold-color"></span>On Hold
            </li>
            <li v-if="scope.data && scope.data.item && isHasPriority(scope.data.item.priority)" class="d-flex" @click="setEstimatePriority(scope.data.item, 'Undo')"><span
                style="margin-right: 10px; margin-top: 1px;" class="status-color undo-priority-color"></span>Undo Priority
            </li>
          </ul>

        </li>
        <li>
          Assign to
          <i class="icon-right bx bx-chevron-right"></i>
          <ul type="none" class="subContext assign-to-context">
            <input class="input-assign-to" type="text" placeholder="Search" v-model="filterAssignTo">
            <li @click="assignedToPerson(scope.data.item, user.id)" v-for="user in computedActiveUsers">{{
                user.name
              }}
            </li>
          </ul>

        </li>
        <li v-if="isCanAddToVehicle || isCanAddToDeparture">
          Add to
          <i class="icon-right bx bx-chevron-right"></i>
          <ul type="none" class="subContext assign-to-context">
            <li v-if="isCanAddToVehicle" @click="addToVehicleInBoard(scope.data.item.card_id, scope.data.item.card_number)">
              Vehicle In Board
            </li>
            <li v-if="isCanAddToDeparture" @click="addToDepartureBoard(scope.data.item.card_id, scope.data.item.card_number)">
              Departure Board
            </li>
          </ul>

        </li>

      </ul>
    </vue-context>

    <modal-wizard
        id="declineORMJob"
        ref="declineORMJob"
        cancelButtonText="Cancel"
        finishButtonText="Ok"
        :finishButtonTooltip="isDisabledModalFinishButton ? 'Please fill in all fields' : ''"
        :isDisabledFinishButton="isDisabledModalFinishButton"
        @on-close="locationForDecline = ''; reasonForDeclineORM = '';"
        @on-complete="declineORMJob">
      <modal-wizard-tab title="Decline ORM Job" description="" title-icon-class="ti-align-right" class="pr-2">
        <template slot-scope="props">
          <div class="pt-2">Are you sure you want Decline this Job?</div>
          <div class="form-group row">
            <label class="col-form-label"></label>
            <div class="col-12">
              <textarea v-model="reasonForDeclineORM" style="height: 67px; resize: none" class="form-control" placeholder="Please add a reason for declining the repair"/>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label"></label>
            <div class="col-12">
              <multiselect
                  :options="['With me the Repairer', 'Returned to the Customer', 'RMC']"
                  :showLabels="false"
                  v-model="locationForDecline"
                  :option-height="29"
                  :max-height="203"
                  :close-on-select="true"
                  placeholder="Please confirm the current location of the vehicle">
              </multiselect>
            </div>
          </div>
        </template>
      </modal-wizard-tab>
    </modal-wizard>
  </div>
</template>

<script>
import { VueContext } from 'vue-context'
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect';
import NotificationCell from '../notification-cell.vue'
import NumberFormatter from '../utility/number-formatter'
import BlockPagination from '../utility/block-pagination'
import accounting from 'accounting'
import _ from 'lodash'
import Axios from 'axios'
import SearchFilter from '../search-filter/search-filter-2';
import { isMobile} from "@/deviceDetect/index";

export default {
  name: "estimate-table",
  components: {
    VueContext,
    NotificationCell,
    Multiselect,
    NumberFormatter,
    BlockPagination,
    SearchFilter,
  },
  data() {
    return {
      isMobile,
      filterAssignTo: '',
      isFilteredTable: false,
      contextOpenEvent: null,
      estimates: [],
      searchFilter: '',
      estimatesAllFiltered: 0,
      ormDeclineCardId: null,
      reasonForDeclineORM: '',
      locationForDecline: '',

      fields: [
        {
          label: 'Number',
          key: 'estimate_number',
          sortable: true,
          tdClass: 'clickable estimates-table-number',
          thClass: 'bg-navyblue estimates-table-number'
        },
        {
          label: 'Vehicle Owner',
          key: 'customer_name',
          sortable: true,
          tdClass: 'clickable estimates-table-vehicle-owner',
          thClass: 'bg-navyblue estimates-table-vehicle-owner'
        },
        {
          label: 'Rego',
          key: 'rego_number',
          sortable: true,
          tdClass: 'clickable estimates-table-rego',
          thClass: 'bg-navyblue estimates-table-rego'
        },
        {
          label: 'Make',
          key: 'make',
          sortable: true,
          tdClass: 'clickable make',
          thClass: 'bg-navyblue make'
        },
        {
          label: 'Model',
          key: 'model',
          sortable: true,
          tdClass: 'clickable model',
          thClass: 'bg-navyblue model'
        },
        {
          label: 'Insurer',
          key: 'insurer',
          sortByFormatted: (value, key, item) => {
            return item.insurer_name
          },
          sortable: true,
          tdClass: 'clickable insurer',
          thClass: 'bg-navyblue insurer'
        },
        {
          label: 'Date Created',
          key: 'created_on',
          sortable: true,
          sortByFormatted: (value, key, item) => {
            return item.sortCreatedDate
          },
          tdClass: 'clickable created-on',
          thClass: 'bg-navyblue created-on'
        },
        {
          label: 'Amount',
          key: 'amount',
          sortable: true,
          tdClass: 'clickable amount',
          thClass: 'bg-navyblue amount'
        },
        {
          label: 'Assigned',
          key: 'assignedTo',
          sortByFormatted: (value, key, item) => {
            if (item.assignedTo && item.assignedTo.name && item.assignedTo.id) {
              return item.assignedTo.name
            }
          },
          sortable: true,
          tdClass: 'clickable colour',
          thClass: 'bg-navyblue assigned'
        },
        {
          label: 'Priority',
          key: 'priority',
          sortable: true,
          tdClass: 'text-center clickable notification ',
          thClass: 'text-center bg-navyblue notification',
        },
        {
          label: 'Notifications',
          key: 'insurance',
          sortable: true,
          tdClass: 'clickable notification',
          thClass: 'bg-navyblue notification'
        },
        {
          label: 'Status',
          key: 'status',
          sortable: true,
          tdClass: 'clickable status',
          thClass: 'bg-navyblue status'
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getterAllEstimates: 'estimate/getAllEstimates',
      getterEstimatesPerPage: 'estimate/getEstimatesPerPage',
      getterEstimatesAllCurrentPage: 'estimate/getEstimatesAllCurrentPage',
      getterSortBy: 'estimate/getSortBy',
      getterSortDesc: 'estimate/getSortDesc',
      isStatusActive: 'isStatusActive',
      getterVisibleUsers: 'visibleUsers',
      searchFilterObj: 'estimate/getSearchFilterObj',
      getterToken: 'estimate/getToken',
      getterInsurerCardColor: 'getInsurerCardColor',
      ceStatuses: 'ceStatuses',
      userInfo: 'userInfo',
      vendorInfo: 'vendorInfo',
      isShopManagerUser: 'isShopManagerUser',
      isProductionManagerUser: 'isProductionManagerUser',
      isDisplayBoardUser: 'isDisplayBoardUser',
    }),
    isDisabledModalFinishButton () {
      return !!(_.isEmpty(this.reasonForDeclineORM) || _.isEmpty(this.locationForDecline));
    },
    isCanAddToVehicle() {
      return !this.isMobile && (this.isShopManagerUser || this.isProductionManagerUser || this.isDisplayBoardUser) && this.vendorInfo?.features?.features?.vehicleInBoard
    },
    isCanAddToDeparture() {
      return !this.isMobile && (this.isShopManagerUser || this.isProductionManagerUser || this.isDisplayBoardUser) && this.vendorInfo?.features?.features?.departureBoard
    },
    isAssignedToFilterActive() {
      return !!(this.searchFilterObj && this.searchFilterObj.hasOwnProperty('assignedTo') && this.searchFilterObj.assignedTo !== '');
    },
    isRegoFilterActive() {
      return !!(this.searchFilterObj && this.searchFilterObj.hasOwnProperty('rego'));
    },
    isVINFilterActive() {
      return !!(this.searchFilterObj && this.searchFilterObj.hasOwnProperty('vin'));
    },
    isEstimateFilterActive() {
      return !!(this.searchFilterObj && this.searchFilterObj.hasOwnProperty('estimate'));
    },
    isPartNumberFilterActive() {
      return !!(this.searchFilterObj && this.searchFilterObj.hasOwnProperty('partNumber'));
    },
    isClaimNumberFilterActive() {
      return !!(this.searchFilterObj && this.searchFilterObj.hasOwnProperty('claimNumber'));
    },
    isInsurerFilterActive() {
      return !!(this.searchFilterObj && this.searchFilterObj.hasOwnProperty('insurer') && this.searchFilterObj.insurer !== '');
    },
    isDateFilterActive() {
      return !!(this.searchFilterObj && this.searchFilterObj.dateRange && (this.searchFilterObj.dateRange === 'This Month' || this.searchFilterObj.dateRange === 'Last Month' || (this.searchFilterObj.dateRange.label && this.searchFilterObj.dateRange.type === 'Date')));
    },
    isStatusesFilterActive() {
      return !!(this.searchFilterObj && this.searchFilterObj.hasOwnProperty('status') && this.searchFilterObj.status !== '');
    },

    computedEstimateDateFilter() {
      if (this.isDateFilterActive) {
        let filter = {
          start: '',
          end: '',
        }

        let date = new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        let firstDayPrevMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        let lastDayPrevMonth = new Date(date.getFullYear(), date.getMonth(), 0);

        if (this.searchFilterObj.dateRange === 'This Month') {
          filter.start = new Date(firstDay).getTime();
          filter.end = new Date(lastDay).getTime();
        } else if (this.searchFilterObj.dateRange === 'Last Month') {
          filter.start = new Date(firstDayPrevMonth).getTime();
          filter.end = new Date(lastDayPrevMonth).getTime();
        } else if (this.searchFilterObj.dateRange.label && this.searchFilterObj.dateRange.type === 'Date') {
          filter.start = new Date(this.searchFilterObj.dateRange.startDate).getTime();
          filter.end = new Date(this.searchFilterObj.dateRange.endDate).getTime();
        }

        return filter;
      }
    },
    isHasFilters() {
      return this.isRegoFilterActive || this.isEstimateFilterActive || this.isPartNumberFilterActive || this.isClaimNumberFilterActive || this.isVINFilterActive;
    },
    computedEstimatesAll() {
      let estimates = _.map(this.getterAllEstimates ? this.getterAllEstimates.slice() : [], (e) => {
        e.ceStatus = this.ceStatuses.findIndex(value => value.name === e.status) + 1
        e.ceStatusPercentage = (this.ceStatuses.findIndex(value => value.name === e.status) + 1) / this.ceStatuses.length * 100
        return e
      })

      if (this.isInsurerFilterActive) {
        return _.filter(estimates, (estimate) => {
          return _.trim(estimate.insurerName) == _.trim(this.searchFilterObj.insurer);
        })
      }
      if (this.isAssignedToFilterActive) {
        return _.filter(this.getterAllEstimates, (estimate) => {
          if (this.searchFilterObj.assignedTo == 'To Me') {
            return  Number(estimate?.assignedTo?.id) && Number(estimate?.assignedTo?.id) == Number(this.userInfo.user_id)
          }
          return _.trim(estimate?.assignedTo?.name) == _.trim(this.searchFilterObj.assignedTo);
        })
      }
      if (this.isStatusesFilterActive) {
        return _.filter(this.getterAllEstimates, (estimate) => {
          return estimate.card_status && _.includes(this.searchFilter, this.getStatusName(estimate.card_status))
        })
      }

      if (this.isDateFilterActive) {

        return _.filter(estimates, (estimate) => {
          if (!estimate.created_on) {
            return false;
          }

          let dateParts = estimate.created_on.split('/');

          let createdDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]).getTime();
          return createdDate >= this.computedEstimateDateFilter.start && createdDate <= this.computedEstimateDateFilter.end;
        })
      }

      return estimates;
    },
    computedActiveUsers () {
      if (!this.filterAssignTo || this.filterAssignTo == '') {
        let users = this.getterVisibleUsers.slice();
        users.unshift({
          id: null,
          name: 'None',
          groupId: null,
          group_id: null,
          isActive: true,
          isVisible: true,
          lastName: null,
          firstName: null,
          role: null,
        })
        return users;
      }
      let self = this
      let filterUsers = _.filter(this.getterVisibleUsers, function (user) {
        let search = []
        search.push(_.toLower(user.name))
        return !!(search.join(',').indexOf(_.toLower(self.filterAssignTo)) > -1)
      })
      return filterUsers
    },
    filter: {
      get () {
        return this.$store.getters.estimateFilter
      },
      set (value) {
        console.log('commit.filter',value)
        this.$store.commit('setEstimateFilter', value);
      }
    },
    computedSearchFilters() {
      if (this.getterAllEstimates.length > 0) {
        let statuses = [];
        _.forEach(this.ceStatuses, (s) => {
          statuses.push({
            label: s.name
          })
        })
        let assignedUsers = []
        _.forEach(this.getterAllEstimates, (e) => {
          if (e?.assignedTo?.id && Number(e.assignedTo.id) !== Number(this.userInfo.user_id) && !_.find(assignedUsers, (u) => {return Number(u.id) == Number(e?.assignedTo?.id)})) {
            assignedUsers.push({
              id: e.assignedTo.id,
              name: e.assignedTo.name,
            })
          }
        })
        assignedUsers = _.map(assignedUsers, (u) => {
          return u.name
        })
        assignedUsers = _.orderBy(assignedUsers, [(itm) => _.trim(_.toLower(itm))], 'asc')
        if (!_.includes(assignedUsers, 'To Me')) {
          assignedUsers.unshift('To Me')
        }
        assignedUsers = _.map(assignedUsers, (u) => {
          return {label: u.trim()}
        })

        let insurers = {};

        this.getterAllEstimates.forEach(estimate => {
          if (estimate.insurerName && estimate.insurerName.trim()) {
            insurers[estimate.insurerName.trim().toLowerCase()] = {label: estimate.insurerName.trim()};
          }
        });
        insurers = _.orderBy(Object.values(insurers),['label'],['asc']);

        // Create date template
        const date = [
          {
            id: 'date',
            label: "Date",
            resultObjectOnly: true,
            type: "Date",
            iconClass: "bx-calendar"
          }
        ];

        // Create searchFilter filters
        return [
          {
            id: 'estimate',
            label: 'Estimate',
            iconClass: 'bx-car',
            options: []
          },
          {
            id: 'rego',
            label: 'Rego',
            iconClass: 'bx-user',
            options: []
          },
          {
            id: 'vin',
            label: 'VIN',
            iconClass: 'bx-user',
            options: []
          },
          {
            id: 'partNumber',
            label: 'Part Number',
            iconClass: 'bx-user',
            options: []
          },
          {
            id: 'claimNumber',
            label: 'Claim Number',
            iconClass: 'bxs-user-badge',
            options: []
          },
          {
            id: 'insurer',
            label: 'Insurer',
            iconClass: 'bx-box',
            options: [
              insurers
            ]
          },
          {
            id: 'dateRange',
            label: 'Date Range',
            iconClass: 'bx-store',
            options: [
              [ {
                label: 'This Month',
              },
                {
                  label: 'Last Month',
                },
                {
                  label: 'Custom Date Range',
                }
              ]
            ]
          },
          {
            id: 'status',
            label: 'By Status',
            iconClass: 'bx-box',
            options: [
              statuses
            ]
          },
          {
            id: 'assignedTo',
            label: 'Assigned To',
            iconClass: 'bx-box',
            options: [
              assignedUsers
            ]
          },

        ];
      } else {
        return [];
      }
    },
  },
  methods: {
    openDeclineModal(v) {
      this.$refs.rightClickMenu.close()
      this.ormDeclineCardId = v
      this.$refs.declineORMJob.show()
    },
    declineORMJob () {
      this.$refs.declineORMJob.hide();
      NProgress.start();
      Axios.post(`/fe/card/${this.ormDeclineCardId}/orm/job-decline`, {})
          .then(response => {
            if (response?.data?._status) {
              toastr.success('ORM job [TestOrmNumber] has been successfully declined.')
            }
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            NProgress.done();
          })
    },
    acceptORMJob (id) {
      this.$refs.rightClickMenu.close()
      NProgress.start();
      Axios.post(`/fe/card/${id}/orm/job-accept`, {})
          .then(response => {
            if (response?.data?._status) {
              toastr.success('ORM job [ORMnumber] has been successfully Accepted.');
            }
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            NProgress.done();
          })
    },
    addToVehicleInBoard(id, number) {
      this.$refs.rightClickMenu.close()
      NProgress.start();
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/board/in/add-card`,
        validateStatus: function (status) {
          return status < 500;
        },
        data: {
          cardId: id,
        },
      }).then(response => {
        if (response.status === 200 && response.data._status) {
          toastr.success('Card ' + number + ' added successfully to the Vehicle In Board');
        } else {
          toastr.error(response.data.msg);
        }
      })
          .catch(error => {
            toastr.error(error);
          })
          .finally(() => {
            NProgress.done();
          });
    },
    addToDepartureBoard(id, number) {
      this.$refs.rightClickMenu.close()
      NProgress.start();
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/board/departure/add-card`,
        validateStatus: function (status) {
          return status < 500;
        },
        data: {
          cardId: id,
        },
      }).then(response => {
        if (response.status === 200 && response.data._status) {
          toastr.success('Card ' + number + ' added successfully to the Departure Board');
        } else {
          toastr.error(response.data.msg);
        }
      })
          .catch(error => {
            toastr.error(error);
          })
          .finally(() => {
            NProgress.done();
          });
    },
    getStatusName(id) {
      return _.find(this.ceStatuses, (c) => {
        return Number(c.ce_status_id) == Number(id)
      })?.name
    },
    openEstimateInNewTab(estimateId) {
      window.open(
          window.location + "/view/" + estimateId,
          "_blank"
      );
      this.$refs.rightClickMenu.close()
    },
    openEstimateInNewWindow(estimateId) {
      window.open(
          window.location + "/view/" + estimateId,
          "_blank",
          "toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=" +
          screen.width +
          ",height=" +
          screen.height
      );
      this.$refs.rightClickMenu.close()
    },

    getInsurerNameStyle(id) {
      if (!id) {
        return null
      }
      let data = this.getterInsurerCardColor?.[id];
      if (data) {
        return {
          color: data.color,
          backgroundColor: data.background,
          fontWeight: 800,
          padding: '3px 7px',
          borderRadius: '5px',
          textAlign: 'center',
          display: 'inline-block'
        }
      }
      return null
    },
    isHasPriority(priority) {
      if (priority === 'Low' || priority === 'Medium' || priority === 'High' || priority === 'OnHold') {
        return true
      }
      return  false;
    },
    setEstimatePriority (item, priority) {
      this.$refs.rightClickMenu.close()
      Axios({
        method: 'post',
        responseType: 'json',
        headers: { Autosave: true },
        url: `/fe/card/${item.cardId}`,
        validateStatus: function (status) {
          return status < 500
        },
        data: {
          uid: {
            priority: priority,
          }
        },
      })
          .then((response) => {
            if (response.status === 200) {
              item.priority = priority
            }
          })
          .catch((error) => {
          })
    },
    assignedToPerson (item, userId) {
      this.$refs.rightClickMenu.close()
      if (item && item.cardId > 0) {
        let uid = Math.random().toString(36).substr(2, 9) + Date.now()
        let update = {}
        update[uid] = {}
        update[uid].assignedTo = {}
        update[uid].assignedTo.id = userId
        Axios({
          method: 'post',
          responseType: 'json',
          headers: { Autosave: true },
          url: `/fe/card/${item.cardId}`,
          validateStatus: function (status) {
            return status < 500
          },
          data: update,
        })
            .then((response) => {
              if (response.status === 200) {
              }
            })
            .catch((error) => {
            })
      }
    },

    setSortByAndSortDesc (e) {
      this.$store.commit('estimate/setSortBy', e.sortBy)
      this.$store.commit('estimate/setSortDesc', e.sortDesc)
    },
    filterForTable (row, filter) {
      let formattedFilter = _.trim(_.toLower(filter))

      if (this.isHasFilters) {

        if (this.isRegoFilterActive && row.rego_number && row.rego_number.toLowerCase().includes(formattedFilter)) {
          return true;
        } else if (this.isEstimateFilterActive && row.estimate_number && row.estimate_number.toLowerCase().includes(formattedFilter)) {
          return true;
        } else if (this.isPartNumberFilterActive && row.partNumbers && row.partNumbers.toLowerCase().includes(formattedFilter)) {
          return true;
        } else if (this.isClaimNumberFilterActive && row.claim_number && row.claim_number.toLowerCase().includes(formattedFilter)) {
          return true;
        } else if (this.isVINFilterActive && row.vin && row.vin.toLowerCase().includes(formattedFilter)) {
          return true;
        }

      } else {

        if (row.estimate_number && row.estimate_number.toLowerCase().includes(formattedFilter)) {
          return true
        } else if (row.customer_name && row.customer_name.toLowerCase().includes(formattedFilter)) {
          return true
        } else if (row.rego_number && row.rego_number.toLowerCase().includes(formattedFilter)) {
          return true
        } else if (row.make && row.make.toLowerCase().includes(formattedFilter)) {
          return true
        } else if (row.model && row.model.toLowerCase().includes(formattedFilter)) {
          return true
        } else if (row.insurer_name && row.insurer_name.toLowerCase().includes(formattedFilter)) {
          return true
        } else if (row.created_on && row.created_on.includes(formattedFilter)) {
          return true
        } else if (row.assignedTo && row.assignedTo.name && row.assignedTo.name.toLowerCase().includes(formattedFilter)) {
          return true
        } else if (row.amount && accounting.formatMoney(row.amount) && accounting.formatMoney(row.amount).includes(formattedFilter)) {
          return true
        } else if (row.status && row.status.toLowerCase().includes(formattedFilter)) {
          return true
        } else if (row.claim_number && row.claim_number.toLowerCase().includes(formattedFilter)) {
          return true
        } else if (row.partNumbers && row.partNumbers.toLowerCase().includes(formattedFilter)) {
          return true
        } else if (row.driverName && row.driverName.toLowerCase().includes(formattedFilter)) {
          return true
        } else if (row.vin && row.vin.toLowerCase().includes(formattedFilter)) {
          return true
        }

      }


    },
    markAsRead: function (item) {
      this.$store.dispatch('markAsReadEstimateNotification', item.estimateId)

      item.activity.Assignment = {}
      item.activity.Email = {}

    },
    openContextMenu: function (item, index, event) {
      if (!this.isStatusActive) {
        return
      }
      event.preventDefault()
      if (item.activity && ((item.activity.Assignment && item.activity.Assignment.from) || (item.activity.Email && item.activity.Email.date))) {
        let vm = this
        this.$refs.rightClickMenu.open(event, { item })
      }
    },
    contextOpen: function (event, w, e) {
      this.contextOpenEvent = event
    },
    setColorForRow (event, color) {
      let tag = event.target.parentElement.localName
      let row = event.target.parentElement
      let rows = row.parentElement.rows
      if (tag === 'tr') {
        row = event.target.parentElement
        rows = event.target.parentElement.parentElement.rows
      } else if (tag === 'td') {
        row = event.target.parentElement.parentElement
        rows = event.target.parentElement.parentElement.parentElement.rows
      }

      for (let i = 0; i < rows.length; i++) {
        let elem = rows[i]
        elem.style.backgroundColor = ''
      }
      if (color) {
        row.style.backgroundColor = color
      }
    },
    contextClose: function () {
      if (this.contextOpenEvent) {
        this.setColorForRow(this.contextOpenEvent, '')
      }
    },
    openMenuEstimates2: function (item, index, event) {
      event.preventDefault()
      this.setColorForRow(event, '#F3F3F3')
      this.openMenuEstimates(event, index, item)
    },
    openMenuEstimates: function (event, index, item) {
      if (!this.isStatusActive) {
        return
      }
      this.$refs.rightClickMenu.open(event, { item: item, index: index })
      setTimeout(() => {
        [].slice
            .call(this.$refs.contextMenuForEstimates.children)
            .forEach((elem) => {
              if (elem.children.length && elem.querySelector('.subContext')) {
                elem.classList.add('hasSubContext')
                elem.addEventListener('click', (e) => {
                  e.stopPropagation()
                })
                elem.addEventListener('mouseenter', () => {
                  if (
                      window.innerWidth <
                      elem.getBoundingClientRect().right +
                      elem.querySelector('.subContext').getBoundingClientRect()
                          .width
                  ) {
                    elem
                        .querySelector('.subContext')
                        .classList.add('position_sub_context_left')
                  } else
                    elem
                        .querySelector('.subContext')
                        .classList.remove('position_sub_context_left')
                })
              } else elem.classList.remove('hasSubContext')
            })
      }, 0)
    },
    onRightClickByMenu (type, data) {
      var estimate_id = data.item.estimate_id
      Axios({
        method: 'post',
        responseType: 'json',
        headers: { 'Autosave': true },
        url: `/fe/estimate/${estimate_id}`,
        validateStatus: function (status) {
          return status < 500
        },
        data: { state: type }
      })
          .then(response => {
            if (response.status === 200) {
            }
          })

    },
    onAllEstimatesFiltered: function (fi) {
      this.estimatesAllFiltered = fi.length
      this.isFilteredTable = true
      this.$store.commit('estimate/setEstimatesAllCurrentPage', 1)
    },
    onEstimatesRowClick: function (item, index, event) {
      this.$router.push('/estimates/view/' + item.estimate_id)
    },
    onSearchFilterChange(filters) {
      this.$store.commit('estimate/setSearchFilterObj', filters);
      if (filters && filters.search) {
        this.filter = filters.search;
      } else {
        this.filter = '';
      }
      console.log('onSearchFilterChange', filters);
    },
  },
}
</script>

<style>
.estimates .tabs-nav-right-search .search-filter .custom-dropdown {
  width: auto !important;
  min-width: 128px !important;
}
.estimates #declineORMJob .modal-content {
  margin-left: 20%;
  width: 60%;
}

.estimates #declineORMJob .tab-content {
  background: none !important;
  overflow-x: inherit !important;
}
</style>
<style scoped>

.tabs-nav-right-search .search-filter >>> .form-control:focus{
  outline: 0 !important;
}
.tabs-nav-right-search .search-filter >>> .search-filter__input{
  font-size: 13px !important;
  font-weight: 800;
  color: black;
}
.tabs-nav-right-search .search-filter >>> .search-filter__box{
  border: 3px solid #5e77fd;
  border-radius: 7px;
}
.search-filter >>> input::placeholder{
  color: #a6a6a6;
  font-weight: 700;
}
.tabs-nav-right-search .search-filter >>> .form-control:focus{
  outline: 0 !important;
}
.tabs-nav-right-search .search-filter >>> .search-filter__input{
  font-size: 13px !important;
  font-weight: 800;
  color: black;
}
.tabs-nav-right-search .search-filter >>> .search-filter__box{
  border: 3px solid #5e77fd;
  border-radius: 7px;
}
.search-filter >>> input::placeholder{
  color: #bbbbc2;
}
.search-filter >>> input:focus{
  background-color: #ebf0fc;
}
.tabs-nav-right-search .search-filter >>> .form-control:focus{
  outline: 0 !important;
}
.search-filter >>> .search-filter__box__focused{
  background-color: #ebf0fc;
  border: 3px solid rgba(94, 121, 255, 0.5) !important;
  box-shadow: 0 0 0 0.2rem rgb(94 121 255 / 25%) !important;
}
.search-filter >>> .search-filter__li--input{
  border-bottom: 0;
}
.search-filter >>> .search-filter__li{
  border-bottom: 0 !important;
}
.search-filter >>> .search-filter__box{
  border: 2px solid rgba(28, 31, 57, 0.25);
}

.tabs-nav-right-search-font-weight >>> input{
  font-weight: 700;
}
.tabs-nav-right-search-font-weight >>> input::placeholder{
  font-weight: 600;
}
.page-header{
  touch-action: none;
}
.estimates >>> .tabs-component{
  touch-action: none;
}
.assign-to-context {
  height: auto;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}
.input-assign-to {
  border: 1px solid buttonface;
  padding: 2px 7px;
  margin: 5px 7px;
  width: 90%;
  border-radius: 5px;
}

.assign-to-context {
  height: auto;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}

.hasSubContext:hover .subContext {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}

.position_sub_context_left {
  right: calc(100% + 1px);
  left: auto;
}

.hasSubContext {
  position: relative;
  padding-right: 5px !important;
}

.subContext {
  position: absolute;
  left: calc(100% + 1px);
  background: #FFFFFF;
  border: 1px solid #BDBDBD;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
  0 1px 5px 0 rgba(0, 0, 0, 0.12);
  min-width: 100%;
  width: auto;

  top: 0;
  transition: 0.4s;
  visibility: hidden;
  opacity: 0;
  right: auto;
  transform: translateY(-10px);
}

.hasSubContext ul > li {
  height: auto;
}
.cards-view-button-center{
  margin-left: 20vw;
}
.site-content-mobile .tabs-nav-right-search{
  margin: 10px 0 !important;
}
.site-content-mobile .estimates >>>.tabs-component .tab-header.nav-tabs.nav-tabs-2{
  border-bottom: 5px solid #e3e4e7 !important;
  padding: 0;
  margin: 0 15px;
}
.site-content-mobile .search-filter{
  padding-left: 15px !important;
  padding-right: 15px;
}
@media screen and (min-width: 1900px) {
  .cards-view-button-center{
    margin-left: 35vw;
  }
}
@media screen and (max-width: 1200px) {
  .cards-view-button-center{
    margin-left: 7vw;
  }
}
@media screen and (max-width: 880px) {
  .cards-view-button-center{
    margin-left: 20px;
  }
}
@media screen and (max-width: 767px) {
  .hasSubContext:hover:after {
    transform: rotate(90deg);
  }

  .subContext {
    left: 0 !important;
    right: auto !important;
    top: 100% !important;
    width: 100% !important;
  }
}
@media screen and (max-width: 460px) {
  .estimates >>> .tabs-component {
    top: 135px;
  }
}
</style>
